/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, Grid} from '@mui/material';
import {BottomBar} from '../../../components/common/BottomBar/BottomBar';
import {ScrollLoader} from '../../../components/common/Loader/ScrollLoader';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {InfoCard} from './InfoCard';
import {jobFeedEmptyBox, scrollLimit, skelContainer} from './JobViewStyles';
import {useEffect, useRef, useState} from 'react';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';
import {CustomTextLoad} from '../../../components/common/CustomTextLoad/CustomTextLoad';
import {fetchMyShiftPostings} from '../../../providers/api/shiftPosting';
import {fetchFilterPreference} from '../../../providers/api/jobFilter';
import {TrainingChecker} from '../../../components/common/TrainingChecker/TrainingChecker';
import {passedAllMandatoryTrainings, removeOldJobPosts} from './JobHelper';

import {fetchMandatoryTrainings} from '../../../providers/api/training';
import {CustomCircularProgress} from '../../../components/common/CustomCircularProgress/CustomCircularProgress';
import {ContractChecker} from '../../../components/common/ContractChecker/ContractTracker';
import {TimezoneChecker} from '../../../components/common/TimezoneChecker/TimezoneTracker';
import {MandatoryDocumentChecker} from '../../../components/common/MandatoryDocumentChecker/MandatoryDocumentTracker';
import {loadContractChecker} from './ContractCheckerHelper';
import {useNavigate} from 'react-router-dom';

export const JobView = ({tenantProfile}: any) => {
  const [jobList, setJobList] = useState<any>([]);
  const [offset, setOffset] = useState(0);
  const [offsetTrigger, setOffsetTrigger] = useState(false);
  const limit = 10;
  const [loading, setLoading] = useState(false);
  const [initLoad, setInitLoad] = useState(true);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const staffId = localStorage.getItem('email');
  const [checker, setChecker] = useState(false);
  const [requiredTrainings, setRequiredTrainings] = useState<any>([]);
  const [loadContent, setLoadContent] = useState(true);
  const [loadedAllMsg, setLoadedAllMsg] = useState(false);
  const trainingCompleted = localStorage.getItem('completedMandatory');
  const currentFilterJSON = localStorage.getItem('filter');
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [refreshing, setRefreshing] = useState(false);
  const scrollPos = useRef(0);
  const [dateFilterExpired, setDateFilterExpired] = useState(false);
  const [contractChecker, setContractChecker] = useState(false);
  const [requiredContracts, setRequiredContracts] = useState<any>([]);
  const [isDocsExpired, setIsDocsExpired] = useState(false);
  const navigate = useNavigate();

  // Store scroll position when leaving page
  useEffect(() => {
    return () => {
      localStorage.setItem('JLScroll', JSON.stringify(scrollPos.current));
    };
  }, []);

  // Check Mandatory training completion and load job feed
  // If completed all mandatory trainings, load job feed. Will load stored job feed if any
  // If no stored job feed, fetch job list from api
  useEffect(() => {
    const tenantId = localStorage.getItem('tenantId');
    if (!tenantId) {
      // Prevent user close app before selecting any agency. Redirect user to agency view to set tenantId for the whole flow
      handlePopUp('error', 'Please select agency');
      setTimeout(() => {
        navigate('/agency/select');
      }, 2000);
      return;
    }

    const jobsLoadedFromStorage = loadJobsFromStorage();

    if (trainingCompleted === 'true') {
      if (jobsLoadedFromStorage) return;
      loadJobList(offset).catch(err => {
        console.log(err);
      });
    } else {
      loadMandatoryTrainings().catch(err => {
        console.log(err);
      });
    }
  }, []);

  // Load remaining job posts after scrolled to bottom
  useEffect(() => {
    if (!initLoad && !loadedAllMsg && offsetTrigger) {
      setLoading(true);
      const newOffset = offset + limit;
      handlePopUp('info', 'Loading more jobs for you');
      loadJobList(newOffset)
        .then(() => {
          setLoading(false);
          setOffset(newOffset);
        })
        .catch(err => {
          console.log(err);
        });
      setOffsetTrigger(false);
    }
  }, [offsetTrigger, limit]);

  // Restore last scroll position
  useEffect(() => {
    if (jobList.length > 0 && scrollContainerRef.current) {
      const storedScrollTop = localStorage.getItem('JLScroll');
      if (storedScrollTop) {
        const parsedScrollTop = JSON.parse(storedScrollTop);

        if (scrollContainerRef.current.scrollHeight > parsedScrollTop) {
          scrollContainerRef.current.scrollTop = parsedScrollTop;
        }
      }
    }
  }, [jobList]);

  // Shared function with Schedule View to load contract pop up on every scroll / visit to this page
  useEffect(() => {
    if (jobList.length > 0) {
      loadContractChecker(
        setContractChecker,
        setRequiredContracts,
        tenantProfile
      ).catch(err => {
        console.log(err);
      });
    }
  }, [jobList]);

  const loadJobsFromStorage = () => {
    const storedJobList = localStorage.getItem('jobList');
    const storedOffset = localStorage.getItem('JLOffset');
    const timestamp = new Date(localStorage.getItem('JLTime') || '');
    const currentTime = new Date();

    loadFilterPreference().catch(err => {
      console.log(err);
    });

    if (currentTime > timestamp) {
      removeOldJobPosts();
      return false;
    }

    if (storedJobList && storedOffset) {
      setJobList(JSON.parse(storedJobList));
      setOffset(JSON.parse(storedOffset));
      setInitLoad(false);

      return true;
    }

    return false;
  };

  const loadMandatoryTrainings = async () => {
    try {
      const res = await fetchMandatoryTrainings();
      await checkMandatoryTrainings(res);
    } catch (error) {
      console.log(error);
    }
  };

  const loadFilterPreference = async () => {
    try {
      let filterPreference;

      if (!currentFilterJSON) {
        filterPreference = await fetchFilterPreference(staffId!);
      } else {
        filterPreference = JSON.parse(currentFilterJSON);
      }

      if (filterPreference && filterPreference.date) {
        const filterDateFrom = new Date(filterPreference.date.value.from);
        const currentDate = new Date();
        const isFilterDateToday =
          currentDate.toDateString() === filterDateFrom.toDateString();
        const isFilterExpired =
          currentDate > filterDateFrom && !isFilterDateToday;

        setDateFilterExpired(isFilterExpired);
      }

      return filterPreference;
    } catch (error) {
      console.log(error);
    }
  };

  const loadJobList = async (newOffset: number, isRefresh = false) => {
    try {
      const filterPreference = await loadFilterPreference();

      const {shiftPostingResults, totalCounts} = await fetchMyShiftPostings(
        filterPreference,
        limit,
        newOffset
      );

      setJobList((prevJobList: any) => {
        let updatedJobList;
        if (isRefresh) {
          updatedJobList = [...shiftPostingResults];
        } else {
          updatedJobList = [...prevJobList, ...shiftPostingResults];
        }

        const timestamp = new Date();
        timestamp.setMinutes(timestamp.getMinutes() + 60);

        localStorage.setItem('JLTime', timestamp.toString());
        localStorage.setItem('jobList', JSON.stringify(updatedJobList));
        localStorage.setItem('JLOffset', JSON.stringify(newOffset));

        return updatedJobList;
      });

      localStorage.setItem('totalJobs', JSON.stringify(totalCounts));
      setInitLoad(false);
    } catch (err) {
      handlePopUp('error', 'Something wrong when trying to load more jobs');
      console.log(err);
    }
  };

  const checkMandatoryTrainings = async (mandatoryList: any) => {
    try {
      const {isAllPass, allTrainings}: any = await passedAllMandatoryTrainings(
        mandatoryList
      );

      if (isAllPass) {
        setLoadContent(true);
        localStorage.setItem('completedMandatory', 'true');
        await loadJobList(offset);
      } else {
        setLoadContent(false);
        setRequiredTrainings(allTrainings);
        setChecker(true);
        setInitLoad(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChecker = () => setChecker(!checker);
  const handleContractChecker = () => setContractChecker(!contractChecker);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (initLoad || loading) return;

    const element = e.target as HTMLDivElement;

    scrollPos.current = element.scrollTop;

    const scrollRatio =
      element.scrollTop / (element.scrollHeight - element.clientHeight);

    if (scrollRatio < -0.03 && !refreshing) {
      setRefreshing(true);
    }

    if (scrollRatio === 0 && refreshing) {
      handleRefresh()
        .then(() => setRefreshing(false))
        .catch(err => {
          console.log(err);
        });
    }

    if (scrollRatio > 0.93) {
      const totalJobsFromStorage = JSON.parse(
        localStorage.getItem('totalJobs') || '0'
      );
      if (jobList.length === totalJobsFromStorage && !loadedAllMsg) {
        handlePopUp(
          'info',
          'You have checked all the available job listings. To discover more opportunities, you may consider modifying your filter preferences.'
        );
        setLoadedAllMsg(true);
      }
      setOffsetTrigger(true);
    }
  };

  const handleRefresh = (): Promise<void> => {
    const newOffset = 0;
    setOffset(newOffset);
    setInitLoad(true);

    removeOldJobPosts();

    return new Promise<void>(resolve => {
      loadJobList(newOffset, true)
        .then(() => {
          resolve();
        })
        .catch(err => {
          console.log(err);
        });
    });
  };

  return (
    <Box sx={{width: '100%'}}>
      <Navbar leftTop rightTop />
      <Box sx={scrollLimit} onScroll={handleScroll} ref={scrollContainerRef}>
        <Grid container rowSpacing={2} sx={{px: 2}}>
          {initLoad ? (
            <>
              {/* Loader */}
              {Array.from(new Array(3)).map((_, index) => (
                <Grid key={index} item xs={12} sm={12} md={6}>
                  <Box sx={skelContainer}>
                    <CustomTextLoad height={50} mb={10} />
                    <CustomTextLoad height={30} mb={20} />
                    <CustomTextLoad height={30} mb={50} />
                    <CustomTextLoad height={40} mb={0} />
                  </Box>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {loadContent ? (
                <>
                  {jobList && jobList.length === 0 ? (
                    <Box sx={jobFeedEmptyBox}>
                      {dateFilterExpired
                        ? 'Your date filter has expired. Change it to check out new jobs!'
                        : 'The Job Feed is currently empty, or no jobs match your preference. Please check back later or change your filter preferences for more job options!'}
                    </Box>
                  ) : (
                    <>
                      {refreshing && <CustomCircularProgress />}
                      {jobList.map((job: any) => (
                        <Grid key={job.id} item xs={12} sm={12} md={6}>
                          <InfoCard
                            isDocsExpired={isDocsExpired}
                            job={job}
                            handleRefresh={handleRefresh}
                          />
                        </Grid>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <Box sx={jobFeedEmptyBox}>
                  <Box>Please complete all required mandatory trainings</Box>
                  <Button
                    variant="outlined"
                    sx={{mt: 3}}
                    onClick={handleChecker}
                  >
                    Check Mandatory Trainings
                  </Button>
                </Box>
              )}
            </>
          )}
        </Grid>
        {loading && <ScrollLoader />}
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
      <TrainingChecker
        open={checker}
        onClose={handleChecker}
        requiredTrainings={requiredTrainings}
      />
      <ContractChecker
        open={contractChecker}
        onClose={handleContractChecker}
        requiredContracts={requiredContracts}
        generalCheck
      />
      <TimezoneChecker onClose={() => {}} />
      <MandatoryDocumentChecker
        setIsDocsExpired={setIsDocsExpired}
        onClose={() => {}}
      />
    </Box>
  );
};
