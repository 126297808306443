/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, CircularProgress, LinearProgress, Zoom} from '@mui/material';
import {BottomBar} from '../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {TimezoneChecker} from '../../../components/common/TimezoneChecker/TimezoneTracker';
import {MandatoryDocumentChecker} from '../../../components/common/MandatoryDocumentChecker/MandatoryDocumentTracker';
import {
  detailTitle,
  scrollLimitJobDetails,
} from '../JobDetailView/JobDetailViewStyles';
import {
  descBox,
  qrContainer,
  spanBox,
  widthContainer,
} from './CheckinViewStyle';
import {QRCodeSVG} from 'qrcode.react';
import {useEffect, useRef, useState} from 'react';
import {fetchStaffSecurityKey} from '../../../providers/api/staffSecurity';

import {generateCodeByPrivateKey} from '@digistaff/staff';
import {app} from '../../../providers/Accounts';

export const CheckinView = () => {
  const [progress, setProgress] = useState(0);
  const [qr, setQr] = useState('');
  const [loading, setLoading] = useState(true);
  const progressTimer = useRef<any>(null);
  const [securityKey, setSecurityKey] = useState('');
  const [hasFetchedKey, setHasFetchedKey] = useState(false);

  useEffect(() => {
    let didCancel = false;

    const fetchKey = async () => {
      try {
        const res: any = await fetchStaffSecurityKey();
        if (!didCancel) {
          setSecurityKey(res?.data?.getStaffSecurityKey?.key);
          setHasFetchedKey(true);
        }
      } catch (err) {
        if (!didCancel) {
          console.error(err);
          console.log(err);
        }
      }
    };

    if (!hasFetchedKey) {
      fetchKey().catch(err => console.error(err));
    }

    return () => {
      didCancel = true;
    };
  }, []);

  const startProgressBar = () => {
    const seconds = 4;
    const intervalTime = (seconds * 1000) / 100;
    const diff = 100 / 100;

    progressTimer.current = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress >= 100) {
          clearInterval(progressTimer.current);
          setLoading(true);
          loadCode();
          return 0;
        }
        return Math.min(oldProgress + diff, 100);
      });
    }, intervalTime);
  };

  const loadCode = () => {
    if (securityKey) {
      const res = generateCodeByPrivateKey(app, securityKey);

      setQr(res as string);
      setLoading(false);
      startProgressBar();
    }
  };

  useEffect(() => {
    if (hasFetchedKey) {
      loadCode();
    }
  }, [hasFetchedKey, securityKey]);

  return (
    <>
      <Navbar rightTop />
      <Box sx={scrollLimitJobDetails}>
        <Box sx={widthContainer}>
          <Box sx={detailTitle}>Checkin / Checkout</Box>
          <Box sx={{p: 2, display: 'flex', justifyContent: 'center'}}>
            You must have an internet connection to generate a QR code
          </Box>
          <Zoom in={true} style={{transitionDelay: '200ms'}}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Box sx={qrContainer}>
                {loading ? (
                  <CircularProgress sx={{color: 'info.main'}} />
                ) : (
                  <Box
                    sx={{
                      width: '400px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <QRCodeSVG
                      value={qr}
                      style={{height: 'auto', width: '90%'}}
                      fgColor="#3E82BF"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Zoom>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Box sx={{mb: 2, p: 2, width: {xs: '100%', md: '50%', lg: '35%'}}}>
              <LinearProgress
                variant="determinate"
                value={progress}
                color="info"
              />
            </Box>
          </Box>
          <Box sx={{px: 2}}>
            <Box component="span" sx={descBox}>
              Please Note:{' '}
            </Box>
            <span style={spanBox}>
              This code is unique to you and self generates every few seconds!
            </span>
          </Box>
          <Box sx={{pb: {xs: 3, sm: 2}}} />
        </Box>
      </Box>
      <BottomBar />
      <TimezoneChecker onClose={() => {}} />
      <MandatoryDocumentChecker onClose={() => {}} />
    </>
  );
};
