import {Box, Button} from '@mui/material';
import {continueBtn} from '../LandingView/LandingViewStyles';
import {Bridge} from '../../../Bridge';
import {
  GPSBtnBox,
  GPSContainer,
  GPSImage,
  GPSTextBox,
} from './EnableGPSViewStyles';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import AllowNotification from '../../../../assets/images/AllowNotification.jpg';

export const EnableNotificationView = () => {
  const navigate = useNavigate();
  const handleOpenSetting = async () => {
    try {
      await Bridge.openSettings();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const checkNotificationStatus = async () => {
      try {
        const notificationStatus =
          await Bridge.getPushNotificationAuthorizationStatus();

        if (notificationStatus === 'AUTHORIZED') {
          navigate(-1);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const intervalId = setInterval(checkNotificationStatus, 1000);
    return () => clearInterval(intervalId);
  }, [navigate]);

  return (
    <Box sx={GPSContainer}>
      <Box sx={GPSTextBox}>
        You Must Enable Notification Services in Settings
      </Box>
      <Box component="img" src={AllowNotification} sx={GPSImage} />
      <Box sx={GPSBtnBox}>
        <Button
          variant="outlined"
          sx={continueBtn}
          onPointerUp={handleOpenSetting}
        >
          Turn on
        </Button>
      </Box>
    </Box>
  );
};
