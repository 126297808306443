/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button} from '@mui/material';
import {useEffect, useState} from 'react';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {
  bottomSubPageLimit,
  certLoadingBox,
  contentBgLayout,
} from '../../ProfileViewStyles';
import {
  certBox,
  certCardBox,
  certDesc,
  certRemove,
  noCertBox,
  statusTitle,
} from '../../../../Public/Registration/RegistrationStyles';
import {continueBtn} from '../../../../Public/LandingView/LandingViewStyles';
import {fetchAllStaffCertifications} from '../../../../../providers/api/certifications';
import {ScrollLoader} from '../../../../../components/common/Loader/ScrollLoader';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {fetchStaffDocumentTenant} from '../../../../../providers/api/documents';

export const UploadCert = () => {
  const navigate = useNavigate();
  const [certArray, setCertArray] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [certStatus, setCertStatus] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    const fetchCertificationList = async () => {
      const staffId = localStorage.getItem('email');
      if (staffId) {
        try {
          const retrievedCert = await fetchAllStaffCertifications(staffId);
          setCertArray(retrievedCert);
        } catch (err) {
          console.log(err);
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchCertificationList().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  useEffect(() => {
    const tenantId = localStorage.getItem('tenantId');
    const checkCertStatus = async (cert: any) => {
      if (tenantId === 'default') {
        setCertStatus(prevStatus =>
          new Map(prevStatus).set(cert.id, 'uploaded')
        );
      } else {
        try {
          const res = await fetchStaffDocumentTenant(cert.id);

          if (res.status) {
            setCertStatus(prevStatus =>
              new Map(prevStatus).set(cert.id, res.status)
            );
          } else {
            setCertStatus(prevStatus =>
              new Map(prevStatus).set(cert.id, 'unknown')
            );
          }
        } catch (err) {
          console.log(err);
          console.log(err);
        }
      }
    };

    certArray.forEach((cert: any) => checkCertStatus(cert));
  }, [certArray]);

  const updateCert = (certObj: string) => {
    navigate('/documents/certifications/update', {state: {param1: certObj}});
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Upload Certifications">
          <Box sx={{p: 1}}>
            <Box sx={contentBgLayout}>
              {loading ? (
                <Box sx={certLoadingBox}>
                  <ScrollLoader />
                </Box>
              ) : (
                <>
                  {certArray && certArray.length === 0 ? (
                    <Box sx={noCertBox}>
                      <Box>You don&apos;t have any certificate added yet.</Box>
                      If you don&apos;t have any certificate to upload at the
                      moment, press next to continue
                    </Box>
                  ) : (
                    <Box sx={certBox}>
                      {certArray &&
                        certArray.map((c: any) => (
                          <Box sx={certCardBox} key={c?.id}>
                            <Box sx={statusTitle}>{c?.idName}</Box>
                            {c?.expiration !== null && (
                              <Box sx={certDesc}>
                                Expiry date:{' '}
                                {moment(c.expiration).format('DD MMM YYYY')}
                              </Box>
                            )}
                            <Box sx={certDesc}>
                              Status: {certStatus.get(c?.id)}
                            </Box>
                            <Box
                              sx={certRemove}
                              onPointerUp={() => updateCert(c)}
                            >
                              Update
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  )}
                </>
              )}
            </Box>
            <Box
              sx={{display: 'flex', flexDirection: 'column', gap: 2, mt: 2}}
              onPointerUp={() => navigate('/documents/certifications/add')}
            >
              <Button variant="outlined" sx={continueBtn}>
                Add Certificate
              </Button>
            </Box>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
