/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button} from '@mui/material';
import {BottomBar} from '../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {
  accTextBox,
  continueBtn,
} from '../../Public/LandingView/LandingViewStyles';
import {
  bottomButtonContainer,
  clearIcon,
  dropDownScrollLimit,
  filterBar,
  filterContentContainer,
  resetBox,
} from './DropDownViewStyles';
import {Clear} from '@mui/icons-material';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {LocationFilter} from './FilterSections/LocationFilter';
import {SalaryFilter} from './FilterSections/SalaryFilter';
import {CheckBoxFilter} from './FilterSections/CheckBoxFilter';
import {jobType, shiftTime} from './filterItemList';
import {CustomSlider} from '../../../components/common/Animation/CustomSlider';
import {
  fetchFilterPreference,
  loadCertificationList,
  loadIndustryList,
  loadLanguageList,
  updateFilter,
} from '../../../providers/api/jobFilter';
import {CustomTextLoad} from '../../../components/common/CustomTextLoad/CustomTextLoad';

import moment, {Moment} from 'moment';
import {DateFilter} from './FilterSections/DateFilter';
import {createMoneyFromFloat} from '@digistaff/common';

export const DropDownView = () => {
  const [industries, setIndustries] = useState<any>({});
  const [certifications, setCertifications] = useState<any>({});
  const [languages, setLanguages] = useState<any>({});
  const [loadingIndustry, setLoadingIndustry] = useState(true);
  const [loadingCert, setLoadingCert] = useState(true);
  const [loadingLang, setLoadingLang] = useState(true);
  const [sortValue, setSortValue] = useState({
    location: '',
    salary: [1],
    industries: [],
    jobType: [],
    certifications: [],
    languages: [],
    shiftTime: [],
  });
  const navigate = useNavigate();
  const {state} = useLocation();
  const staffId = localStorage.getItem('email');
  const [day, setDay] = useState<Moment | null>(null);

  useEffect(() => {
    loadIndustryFilter();
    loadCertFilter();
    loadLanguageFilter();
  }, [state]);

  useEffect(() => {
    if (!loadingIndustry && !loadingCert && !loadingLang) {
      fetchFilterPreference(staffId!)
        .then(res => {
          setSortValue(res);
          if (res.date && res.date.value.from) {
            const dayFromResponse = moment(res.date.value.from);
            setDay(dayFromResponse);
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err);
        });
    }
  }, [loadingIndustry, loadingCert, loadingLang]);

  const loadIndustryFilter = () => {
    loadIndustryList()
      .then(res => setIndustries(res))
      .catch(err => {
        console.log(err);
        console.log(err);
      })
      .finally(() => setLoadingIndustry(false));
  };

  const loadCertFilter = () => {
    loadCertificationList()
      .then(res => setCertifications(res))
      .catch(err => {
        console.log(err);
        console.log(err);
      })
      .finally(() => setLoadingCert(false));
  };

  const loadLanguageFilter = () => {
    loadLanguageList()
      .then(res => setLanguages(res))
      .catch(err => {
        console.log(err);
        console.log(err);
      })
      .finally(() => setLoadingLang(false));
  };

  const handleChange = (event: Event, newValue: number | number[]) =>
    setSortValue({...sortValue, salary: newValue as number[]});

  const selectProperties = (obj: any, type: string, cat: string) => {
    if (obj[cat].includes(type)) {
      setSortValue({
        ...sortValue,
        [cat]: obj[cat].filter((i: any) => i !== type),
      });
    } else {
      setSortValue({
        ...sortValue,
        [cat]: [...obj[cat], type],
      });
    }
  };

  const handleDayChange = (newValue: Moment | null) => setDay(newValue);

  const handleSubmit = () => {
    const sortValueCopy: Partial<{
      location: string;
      salary: number[];
      industries: never[];
      jobType: never[];
      certifications: never[];
      languages: never[];
      shiftTime: never[];
      date?: {
        operator: string;
        value: {
          from: string;
          to: string;
        };
      };
    }> = {
      location: sortValue.location,
      salary: [+createMoneyFromFloat(sortValue.salary[0], 'CAD').amount],
      industries: [...sortValue.industries],
      jobType: [...sortValue.jobType],
      certifications: [...sortValue.certifications],
      languages: [...sortValue.languages],
      shiftTime: [...sortValue.shiftTime],
    };

    if (day) {
      const localDay = new Date(day.format());

      const startOfDay = new Date(
        localDay.getFullYear(),
        localDay.getMonth(),
        localDay.getDate()
      ).toISOString();
      const endOfDay = new Date(
        localDay.getFullYear(),
        localDay.getMonth(),
        localDay.getDate(),
        23,
        59,
        59,
        999
      ).toISOString();

      sortValueCopy.date = {
        operator: 'between',
        value: {
          from: startOfDay,
          to: endOfDay,
        },
      };
    }

    updateFilter(staffId!, sortValueCopy)
      .then(() => {
        localStorage.setItem('filter', JSON.stringify(sortValueCopy));
        localStorage.removeItem('jobList');
        localStorage.removeItem('JLOffset');
        localStorage.removeItem('JLScroll');
        navigate('/jobs');
      })
      .catch(err => {
        console.log(err);
        console.log(err);
      });
  };

  const handleReset = () => {
    setSortValue({
      location: '',
      salary: [1],
      industries: [],
      jobType: [],
      certifications: [],
      languages: [],
      shiftTime: [],
    });
    setDay(null);
  };

  return (
    <>
      <Navbar rightTop />
      <CustomSlider direction="down" fadeIn={100}>
        <Box sx={filterBar}>
          <Box sx={resetBox} onPointerUp={handleReset}>
            Reset
          </Box>
          <Box sx={accTextBox}>Filters</Box>
          <Clear sx={clearIcon} onPointerUp={() => navigate(-1)} />
        </Box>
      </CustomSlider>
      <CustomSlider direction="down" fadeIn={200}>
        <Box sx={dropDownScrollLimit}>
          <Box sx={filterContentContainer}>
            <LocationFilter sortValue={sortValue} setSortValue={setSortValue} />
            <DateFilter day={day} handleDayChange={handleDayChange} />
            <SalaryFilter sortValue={sortValue} handleChange={handleChange} />
            {loadingIndustry ? (
              <Box sx={{m: 3}}>
                <CustomTextLoad height={14} mb={10} />
                <CustomTextLoad height={14} mb={10} />
                <CustomTextLoad height={14} mb={10} />
              </Box>
            ) : (
              <>
                {Object.keys(industries).length > 0 && (
                  <CheckBoxFilter
                    cat={industries}
                    sortValue={sortValue}
                    selectProperties={selectProperties}
                  />
                )}
                <CheckBoxFilter
                  cat={jobType}
                  sortValue={sortValue}
                  selectProperties={selectProperties}
                />
              </>
            )}
            {loadingCert ? (
              <Box sx={{m: 3}}>
                <CustomTextLoad height={14} mb={10} />
                <CustomTextLoad height={14} mb={10} />
                <CustomTextLoad height={14} mb={10} />
              </Box>
            ) : (
              <>
                {Object.keys(certifications).length > 0 && (
                  <CheckBoxFilter
                    cat={certifications}
                    sortValue={sortValue}
                    selectProperties={selectProperties}
                  />
                )}
                <CheckBoxFilter
                  cat={shiftTime}
                  sortValue={sortValue}
                  selectProperties={selectProperties}
                />
              </>
            )}
            {loadingLang ? (
              <Box sx={{m: 3}}>
                <CustomTextLoad height={14} mb={10} />
                <CustomTextLoad height={14} mb={10} />
                <CustomTextLoad height={14} mb={10} />
              </Box>
            ) : (
              <>
                {Object.keys(languages).length > 0 && (
                  <CheckBoxFilter
                    cat={languages}
                    sortValue={sortValue}
                    selectProperties={selectProperties}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </CustomSlider>
      <Box sx={bottomButtonContainer}>
        <Box sx={{px: 3}}>
          <Button
            variant="outlined"
            sx={continueBtn}
            onPointerUp={handleSubmit}
            disabled={loadingIndustry || loadingCert || loadingLang}
          >
            Update
          </Button>
        </Box>
      </Box>
      <BottomBar />
    </>
  );
};
