/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, CircularProgress} from '@mui/material';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {
  alphabetBox,
  alphabetBoxAlt,
  quizBtn,
  quizBtnAlt,
} from '../../../DigiScoreView/TrainingCenter/TrainingCenterStyles';
import {bottomSubPageLimit, circularBox} from '../../ProfileViewStyles';
import {useEffect, useState} from 'react';
import {
  fetchAgencyList,
  fetchTenantName,
} from '../../../../../providers/api/tenancy';
import {usePopUp} from '../../../../../hooks/usePopUp';
import {PopUp} from '../../../../../components/common/PopUp/PopUp';
import {setTenantKey} from '@digistaff/app';
import {app} from '../../../../../providers/Accounts';
import {useNavigate} from 'react-router-dom';
import {removeOldJobPosts} from '../../../JobView/JobHelper';
import {removeOldSchedule} from '../../../ScheduleView/ScheduleHelpers';
import {fetchStaffSuspendStatusByTenantId} from '../../../../../providers/api/profile';
import {removeTenantContracts} from '../../../JobView/ContractCheckerHelper';

export const SwitchProfileView = () => {
  const [agencyList, setAgencyList] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [currentAgent, setCurrentAgent] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const loadAgency = async () => {
      try {
        const staffId = localStorage.getItem('email');
        const tenantIds = await fetchAgencyList(staffId!);
        if (tenantIds !== null) {
          const tempList = await Promise.all(
            tenantIds.map(async (tenantId: string) => {
              const tenant = await fetchTenantName(tenantId);
              const isSuspended = await fetchStaffSuspendStatusByTenantId(
                tenantId
              );
              return {...tenant, isSuspended};
            })
          );

          setAgencyList(tempList);
          localStorage.setItem('tenantList', JSON.stringify(tempList));
        }
      } catch (err) {
        console.log(err);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    loadAgency().catch(err => {
      console.log(err);
      console.log(err);
    });
    loadCurrentAgency();
  }, []);

  const loadCurrentAgency = () => {
    const tenantId = localStorage.getItem('tenantId');

    tenantId && setCurrentAgent(tenantId);
  };

  const checkStaffSuspendStatus = async (tenantId: string) => {
    if (tenantId === 'default') return;
    try {
      const res = await fetchStaffSuspendStatusByTenantId(tenantId);
      if (res) {
        handlePopUp(
          'error',
          'Your account on this profile has been suspended by the agency admin. For further details, please get in touch with the agency.'
        );
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      console.log(error);
      return error;
    }
  };

  const selectAgency = async (tenantId: string) => {
    const isSuspended = await checkStaffSuspendStatus(tenantId);
    if (isSuspended) return;

    localStorage.setItem('tenantId', tenantId);
    removeOldJobPosts();
    removeOldSchedule();
    removeTenantContracts();
    localStorage.removeItem('conv_id');
    localStorage.removeItem('completedMandatory');
    setTenantKey(app, tenantId);
    setCurrentAgent(tenantId);
    handlePopUp('success', 'Switched agency');
    setTimeout(() => {
      navigate('/jobs');
    }, 700);
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Switch Profile">
          {loading ? (
            <Box sx={circularBox}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{px: 2}}>
              <Button
                variant="outlined"
                sx={currentAgent === 'default' ? quizBtnAlt : quizBtn}
                onPointerUp={() => selectAgency('default')}
              >
                <Box
                  sx={currentAgent === 'default' ? alphabetBoxAlt : alphabetBox}
                >
                  1
                </Box>
                DigiJobs
              </Button>
              {agencyList &&
                agencyList.map((agent: any, idx: number) => (
                  <Button
                    variant="outlined"
                    key={agent.id}
                    sx={agent.id === currentAgent ? quizBtnAlt : quizBtn}
                    name={agent.id}
                    onPointerUp={() => selectAgency(agent.id)}
                  >
                    <Box
                      sx={
                        agent.id === currentAgent ? alphabetBoxAlt : alphabetBox
                      }
                    >
                      {2 + idx}
                    </Box>
                    {agent.name} {agent.isSuspended && '(Suspended)'}
                  </Button>
                ))}
            </Box>
          )}
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
    </>
  );
};
