/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import {Navbar} from '../../../../../../components/common/NavBar/NavBar';
import {
  bottomSubPageLimit,
  flexAlign,
  photoIcon,
} from '../../../ProfileViewStyles';
import {PageWrapper} from '../../../../../../components/common/PageWrapper/PageWrapper';
import {useState} from 'react';
import {useFileUpload} from '../../../../../../hooks/useFileUpload';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {useNavigate} from 'react-router-dom';
import {statusList} from '../../../../../Public/Registration/ID/WorkDocRegView';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {PhotoCamera} from '@mui/icons-material';
import {checkBoxLarge} from '../../../../../../components/common/AvailabilityTable/AvailabilityTableStyles';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {BottomBar} from '../../../../../../components/common/BottomBar/BottomBar';
import {handleCustomError} from '../../../../../RouteErrorView/RouteErrorView';
import {useCurrentUser} from '../../../../../../hooks/useCurrentUser';
import {
  createStaffDocInGlobal,
  createWorkDocument,
  workStatusMappingToApi,
} from '../../../../../../providers/api/documents';

export const InAppNewWorkDoc = () => {
  const navigate = useNavigate();
  const [tempStatus, setTempStatus] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const staffId = localStorage.getItem('email');
  const {handlePopUp, popUp, setPopUp, status, message} = usePopUp();
  const {userValid, sessionInvalidRedirection, inAppErrMsg} = useCurrentUser();
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();

  const handleWorkStatusChange = (newWorkStatus: string) => {
    setTempStatus(newWorkStatus);
    setChecked(false);
  };

  const isApplied = () => tempStatus === 'applied status';

  const handleReturn = () => {
    handlePopUp(
      'success',
      'Your documents have been uploaded for verification'
    );
    setTimeout(() => navigate('/documents'), 700);
  };

  const handleUploadPhoto = async () => {
    try {
      const uploaded: any = await handleFileUpload(staffId!);
      return uploaded?.data?.result?.Location;
    } catch (error) {
      handleCustomError(error);
    }
  };

  const createWorkDocInGlobal = async (photo: string) => {
    try {
      const res = await createWorkDocument(staffId!, tempStatus, photo);
      const formattedType = workStatusMappingToApi[tempStatus];

      await createStaffDocInGlobal(
        staffId!,
        res?.data?.createStaffDocument?.id,
        formattedType
      );
    } catch (error) {
      handleCustomError(error);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);

    if (!isApplied() && !fileSelected) {
      handlePopUp('error', 'Please upload document');
      setSubmitting(false);
      return;
    }

    try {
      const userStatus = await userValid();
      if (!userStatus) {
        handlePopUp('error', inAppErrMsg);
        sessionInvalidRedirection();
        return;
      }

      let photo: any;
      if (isApplied()) {
        photo = '';
      } else {
        photo = await handleUploadPhoto();
      }

      await createWorkDocInGlobal(photo);
      handleReturn();
    } catch (error) {
      handleCustomError(error);
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Upload Eligibility to Work">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 3,
              mx: 2,
            }}
          >
            <Box sx={{my: 2, display: 'flex', flexDirection: 'column', gap: 2}}>
              <TextField
                value={tempStatus}
                select
                label="Work Status"
                sx={{width: '100%', textTransform: 'capitalize'}}
              >
                {statusList.map(o => (
                  <MenuItem
                    key={o}
                    value={o}
                    onClick={() => handleWorkStatusChange(o)}
                    sx={{textTransform: 'capitalize'}}
                  >
                    {o}
                  </MenuItem>
                ))}
              </TextField>
              {!isApplied() ? (
                <>
                  {tempStatus !== '' && (
                    <Button
                      variant="outlined"
                      sx={continueBtn}
                      component="label"
                    >
                      <PhotoCamera sx={photoIcon} />
                      Upload Eligibility to Work
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={changeHandler}
                      />
                    </Button>
                  )}
                </>
              ) : (
                <Box>
                  Please upload the CRA letter indicating you are legally
                  allowed to work in the{' '}
                  <Box component="span" sx={{fontWeight: 'bold'}}>
                    SIN Section
                  </Box>
                  . This letter will include a 10 digit application number.
                  <Box sx={{my: 1}}>
                    Selecting the Applied status means you do NOT have a valid
                    permit or sin but you have a letter from the CRA allowing
                    you to work
                  </Box>
                  <Box sx={{py: 1}} />
                  <Button
                    variant="outlined"
                    sx={continueBtn}
                    onPointerUp={handleSubmit}
                  >
                    Confirm
                  </Button>
                </Box>
              )}
              {tempStatus !== '' && !isApplied() && (
                <>
                  <Box sx={{my: 1}}>
                    <Box sx={flexAlign}>
                      <Checkbox
                        sx={checkBoxLarge}
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                      I hereby certify that, to the best of my knowledge, the
                      provided information is true and accurate.
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    sx={continueBtn}
                    onPointerUp={handleSubmit}
                    disabled={submitting || !checked}
                  >
                    {submitting ? (
                      <CircularProgress
                        size="1.3rem"
                        sx={{color: 'primary.light', fontSize: '12px'}}
                      />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </>
              )}
            </Box>
            <PopUp
              isOpen={popUp}
              onClose={() => setPopUp(false)}
              message={message}
              status={status}
            />
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
