/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {ScrollLoader} from '../../../../../components/common/Loader/ScrollLoader';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {fetchStaffPayout} from '../../../../../providers/api/payout';
import {bottomSubPageLimit, payoutDetailText} from '../../ProfileViewStyles';
import {formatMoneyToFloat} from '@digistaff/common';

export const SinglePayoutView = () => {
  const {id} = useParams();
  const [payout, setPayout] = useState<any>([]);
  const [payroll, setPayroll] = useState<any>();
  const [records, setRecords] = useState<any>([]);
  const [adjustments, setAdjustments] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [openShifts, setOpenShifts] = useState(false);
  const [openAdjustments, setOpenAdjustments] = useState(false);

  useEffect(() => {
    const loadPayout = async () => {
      try {
        if (id) {
          const res = await fetchStaffPayout(id);
          setRecords(JSON.parse(res.records));
          if (res.adjustments) setAdjustments(JSON.parse(res.adjustments));
          setPayroll(res.payroll);
          setPayout(res);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    loadPayout().catch(err => {
      console.log(err);
    });
  }, [id]);

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Payout History" />
        <Box sx={{py: 1}} />
        {loading ? (
          <ScrollLoader />
        ) : (
          <Box
            sx={{
              width: '100%',
              px: 3,
              pt: 1,
              pb: 3,
              backgroundColor: 'secondary.light',
              borderRadius: '5px',
            }}
          >
            <Box sx={payoutDetailText}>
              Payroll Date: {moment(payout?.pay_date).format('DD MMM YYYY')}
            </Box>

            <Box sx={payoutDetailText}>
              Status:{' '}
              <Box
                component="span"
                sx={{
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                }}
              >
                {payout?.status}
              </Box>
            </Box>
            <Divider />

            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="right">
                    <Typography fontWeight="bold">Gross Income</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontWeight="bold"
                      align="right"
                      textTransform="uppercase"
                    >
                      {formatMoneyToFloat(
                        payroll.gross_income,
                        payroll.currency
                      )}{' '}
                      {payroll.currency}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">Provincial Tax Deduction</TableCell>
                  <TableCell>
                    <Typography align="right" textTransform="uppercase">
                      {formatMoneyToFloat(
                        payroll.provincial_tax_deduction,
                        payroll.currency
                      )}{' '}
                      {payroll.currency}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">Federal Tax Deduction</TableCell>
                  <TableCell>
                    <Typography align="right" textTransform="uppercase">
                      {formatMoneyToFloat(
                        payroll.federal_tax_deduction,
                        payroll.currency
                      )}{' '}
                      {payroll.currency}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">CPP Deduction</TableCell>
                  <TableCell>
                    <Typography align="right" textTransform="uppercase">
                      {formatMoneyToFloat(
                        payroll.cpp_deduction,
                        payroll.currency
                      )}{' '}
                      {payroll.currency}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">EI Deduction</TableCell>
                  <TableCell>
                    <Typography align="right" textTransform="uppercase">
                      {formatMoneyToFloat(
                        payroll.ei_deduction,
                        payroll.currency
                      )}{' '}
                      {payroll.currency}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">
                    <Typography fontWeight="bold">Net Income</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontWeight="bold"
                      align="right"
                      textTransform="uppercase"
                      color="green"
                    >
                      {formatMoneyToFloat(payroll.net_income, payroll.currency)}{' '}
                      {payroll.currency}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )}
        <Divider />
        {records.length > 0 && (
          <Grid item xs={12} my={2}>
            <Accordion expanded={openShifts} sx={{overflow: 'auto'}}>
              <AccordionSummary onClick={() => setOpenShifts(!openShifts)}>
                <Typography variant="h6">Shifts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {records.map((record: any) => (
                  <Fragment key={record.business_id}>
                    <Typography variant="h6" marginTop="10px">
                      {record.business_name}
                    </Typography>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Shift</TableCell>
                          <TableCell>Rate</TableCell>
                          <TableCell>Regular Hours</TableCell>
                          <TableCell>Regular Pay</TableCell>
                          <TableCell>Overtime Hours</TableCell>
                          <TableCell>Overtime Pay</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {record.shifts.map((shift: any) => (
                          <TableRow key={shift.id}>
                            <TableCell>
                              {moment(shift.shift_date).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>{shift.shift_posting_name}</TableCell>
                            <TableCell>
                              {formatMoneyToFloat(shift.rate, payroll.currency)}
                            </TableCell>
                            <TableCell>
                              {shift.regular_hours
                                ? shift.regular_hours.hours
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {shift.regular_hours
                                ? formatMoneyToFloat(
                                    shift.regular_hours.pay,
                                    payroll.currency
                                  )
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {shift.overtime_hours
                                ? shift.overtime_hours.hours
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {shift.overtime_hours
                                ? formatMoneyToFloat(
                                    shift.overtime_hours.pay,
                                    payroll.currency
                                  )
                                : '-'}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography fontWeight="bold">
                              {formatMoneyToFloat(
                                record.total_regular_pay,
                                payroll.currency
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography fontWeight="bold">
                              {formatMoneyToFloat(
                                record.total_overtime_pay,
                                payroll.currency
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Fragment>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {adjustments.length > 0 && (
          <Grid item xs={12} my={2}>
            <Accordion expanded={openAdjustments} sx={{overflow: 'auto'}}>
              <AccordionSummary
                onClick={() => setOpenAdjustments(!openAdjustments)}
              >
                <Typography variant="h6">Adjustments</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Item</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adjustments.map((adj: any) => (
                      <TableRow key={adj.id}>
                        <TableCell sx={{textTransform: 'capitalize'}}>
                          {moment(adj.created_at).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell sx={{textTransform: 'capitalize'}}>
                          {adj.adjustment_type}
                        </TableCell>
                        <TableCell sx={{textTransform: 'capitalize'}}>
                          ${formatMoneyToFloat(adj.amount, adj.currency)}
                        </TableCell>
                        <TableCell sx={{textTransform: 'capitalize'}}>
                          {adj.item_name}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Box>
      <BottomBar />
    </>
  );
};
