/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {getAppShiftPosting, listMySuggestedShifts} from '@digistaff/shift';
import {getCrossTenantStaffAttribute} from '@digistaff/business';
import {app} from '../Accounts';
import {formatMoneyToFloat} from '@digistaff/common';

export const fetchMyShiftPostings = async (
  filterCriteria: any,
  limit: number,
  offset: number
) => {
  try {
    let filter: any;

    if (filterCriteria && Object.keys(filterCriteria).length > 0) {
      filter = {
        location: {
          address: filterCriteria.location,
          latitude: 0,
          longitude: 0,
        },
        date: filterCriteria.date ? filterCriteria.date : null,
        salary: {
          min:
            filterCriteria.salary.length !== 0
              ? filterCriteria.salary[0]
              : 15.5,
          max: filterCriteria.salary[1] ? filterCriteria.salary[1] : null,
        },
        industry: filterCriteria.industries,
        type: filterCriteria.jobType,
        certification: filterCriteria.certifications,
        time: filterCriteria.shiftTime,
        language: filterCriteria.languages,
        // pagination filter
        limit: limit,
        offset: offset,
      };
    }

    const res: any = await listMySuggestedShifts(app, filter);

    const totalCounts = res.count;

    const shiftPostingResults = res.result.map((post: any) => {
      return {
        id: post.id,
        jobTitle: post.name,
        jobDesc: [post.description],
        startTime: post.clock_in,
        endTime: post.clock_out,
        salary: formatMoneyToFloat(post.hourly_rate_amount, 'CAD'),
        business: post.business?.name,
        street: post.business_location?.street || '',
        city: post.business_location?.city || '',
        startDate: post.start_date,
        status: post.status,
        duration: post.duration,
        tenant_id: post.tenant_id,
        mandatory_trainings: post.mandatory_trainings,
        required_agency_trainings: post.required_agency_trainings,
        required_certifications: post.required_certifications,
        required_staff_custom_attributes: post.required_staff_custom_attributes,
      };
    });

    return {shiftPostingResults, totalCounts};
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchJobDetails = async (shiftPostingId: string) => {
  try {
    const res: any = await getAppShiftPosting(app, shiftPostingId);
    const item = res.data.getShiftInstance;

    const shiftRes: any = {
      id: item.id,
      jobTitle: item.nestGetShiftPosting.name,
      business: item.nestGetShiftPosting.nestGetBusiness?.name,
      street: item.nestGetShiftPosting.nestGetBusinessLocation?.street || '',
      postal: item.nestGetShiftPosting.nestGetBusinessLocation?.postal || '',
      city: item.nestGetShiftPosting.nestGetBusinessLocation?.city || '',
      startTime: item.nestGetShiftPosting.clock_in,
      endTime: item.nestGetShiftPosting.clock_out,
      latitude: Number(
        item.nestGetShiftPosting.nestGetBusinessLocation?.latitude.toFixed(4)
      ),
      longitude: Number(
        item.nestGetShiftPosting.nestGetBusinessLocation?.longitude.toFixed(4)
      ),
      salary: formatMoneyToFloat(
        item.nestGetShiftPosting.hourly_rate_amount,
        'CAD'
      ),
      jobDesc: item.nestGetShiftPosting.description,
      jobDuties: item.nestGetShiftPosting.duties,
      jobReq: item.nestGetShiftPosting.additional_requirements,
      status: item.status,
      startDate: item.start_date,
      tenant_id: item.nestGetShiftPosting.tenant_id,
    };

    if (item.nestGetShiftPosting.required_staff_custom_attributes) {
      const customAttributeList = [];
      for (
        let step = 0;
        step < item.nestGetShiftPosting.required_staff_custom_attributes.length;
        step++
      ) {
        const apiResult = await getCrossTenantStaffAttribute(
          app,
          item.nestGetShiftPosting.required_staff_custom_attributes[step],
          item.nestGetShiftPosting.tenant_id
        );

        if (apiResult.data.getTenantStaffAttribute)
          customAttributeList.push(apiResult.data.getTenantStaffAttribute);
      }
      shiftRes.required_staff_custom_attributes = customAttributeList;
    }

    return shiftRes;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const checkJobInstance = async (shiftPostingId: string) => {
  try {
    const res: any = await getAppShiftPosting(app, shiftPostingId);
    const item = res?.data?.getShiftInstance;

    return item;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};
