export const bottonBarContainer = {minWidth: '320px'};

export const bottomPaper = {position: 'fixed', bottom: 0, left: 0, right: 0};

export const bottomOuter = {textAlign: 'center', width: '100vw'};

export const centerIconBase = {
  color: 'white',
  width: '65px',
  height: '65px',
  borderRadius: '50%',
  padding: '5px',
  marginTop: '-15px',
  margin: '-45px auto',
  position: 'relative',
  border: '10px solid',
  borderColor: 'secondary.light',
  zIndex: 1000,
};

export const centerIcon = {
  color: 'white',
  width: '60px',
  height: '60px',
  borderRadius: '50%',
  backgroundColor: 'primary.light',
  padding: '5px',
  marginTop: '-33px',
  boxShadow: 3,
  zIndex: 1100,
  cursor: 'pointer',
};

export const bottomIconContainer = {
  minWidth: '20px',
  p: {xs: 0},
  '&:active': {
    '& .MuiSvgIcon-root': {transform: 'translate(0, -20%)', transition: '0.2s'},
  },
};
