/* eslint-disable @typescript-eslint/no-explicit-any */
import {PhotoCamera} from '@mui/icons-material';
import {Box, Button, CircularProgress, Grow, TextField} from '@mui/material';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {
  photoIcon,
  qualiBtnContainer,
  qualiTextField,
  tabOneBtnBox,
} from '../../../ProfileViewStyles';
import {ChangeEvent, useState} from 'react';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {SinTabGeneralProps} from '../../../../../../types/types';
import {
  createSinDocument,
  updateSinDocument,
  updateTenantStaffDocuments,
} from '../../../../../../providers/api/documents';
import {useFileUpload} from '../../../../../../hooks/useFileUpload';
import {useNavigate} from 'react-router-dom';
import moment, {Moment} from 'moment';
import {errorSin} from '../../../../../Public/Registration/RegistrationStyles';
import {CustomDatePicker} from '../../../../../../components/common/CustomDatePicker/CustomDatePicker';
import {useCurrentUser} from '../../../../../../hooks/useCurrentUser';
import {fetchAgencyList} from '../../../../../../providers/api/tenancy';
import {CustomDialog} from '../../../../../../components/common/Dialog/CustomDialog';
import {
  checkStaffSinUniqueness,
  checkStaffSinUniquenessInApp,
} from '../../../../../../providers/api/profile';

export const SinTabOne = ({
  setSinReg,
  setSinStatus,
  sinDocId,
  inApp,
  sinNo,
  sinExpiry,
}: SinTabGeneralProps) => {
  const [value, setValue] = useState<Moment | null>(
    sinExpiry ? sinExpiry : null
  );
  const [sin, setSin] = useState(sinNo ? sinNo : '');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [error, setError] = useState('');
  const staffId = localStorage.getItem('email');
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const {userValid, sessionInvalidRedirection, errMsg} = useCurrentUser();
  const [dialog, setDialog] = useState(false);

  const handleChange = (newValue: Moment | null) => setValue(newValue);

  const handleSin = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\s/g, '');
    if (newValue.length <= 9) {
      setSin(newValue);
    }
  };

  const handleDialog = () => setDialog(!dialog);
  const handleLoginPage = () => navigate('/');

  const handleSinReg = () => setSinReg && setSinReg(true);

  const handleSinStatus = () => setSinStatus && setSinStatus('sin');

  const idExpired = () => !moment(value).isAfter(moment());

  const createSinDoc = async () => {
    setSubmitting(true);

    const userStatus = await userValid();
    if (!userStatus) {
      handlePopUp('error', errMsg);
      sessionInvalidRedirection();
      return;
    }

    if (!fileSelected) {
      handlePopUp('error', 'Please upload document');
      setSubmitting(false);
      return;
    }

    const uploaded: any = await handleFileUpload(staffId!);
    const sinPhoto = uploaded.data.result.Location;
    const expiryDate = sin.startsWith('9') ? value : null;

    createSinDocument(staffId!, 'sin', sin!, expiryDate, sinPhoto)
      .then(res => {
        if (res.data.createStaffDocument.id !== null) {
          handleSinReg();
          handleSinStatus();
          closeDrawer();
        } else {
          handlePopUp('error', 'SIN number already registered');
          if (!inApp) {
            handleDialog();
          }
          setSubmitting(false);
        }
      })
      .catch(err => console.log(err));
  };

  const updateSinDoc = async () => {
    try {
      setSubmitting(true);
      const userStatus = await userValid();
      if (!userStatus) {
        handlePopUp('error', errMsg);
        sessionInvalidRedirection();
      }

      if (!fileSelected) {
        handlePopUp('error', 'Please upload document');
        setSubmitting(false);
        return;
      }

      if (sin !== sinNo) {
        if (inApp) {
          const sinNotUnique = await checkStaffSinUniquenessInApp(sin);
          if (sinNotUnique) {
            handlePopUp('error', 'SIN number already registered');
            setSubmitting(false);
            return;
          }
        } else {
          const sinNotUnique = await checkStaffSinUniqueness(sin);
          if (sinNotUnique) {
            handleDialog();
            setSubmitting(false);
            return;
          }
        }
      }

      const uploaded: any = await handleFileUpload(staffId!);
      const sinPhotoUpload = uploaded.data.result.Location;
      const expiryDate = sin.startsWith('9') ? value : null;

      const response: any = await updateSinDocument(
        sinDocId!,
        'sin',
        sin!,
        expiryDate,
        sinPhotoUpload
      );

      if (response.data.updateStaffDocument.id !== null) {
        if (inApp) {
          const tenantList = await fetchAgencyList(staffId!);

          if (tenantList && tenantList.length !== 0) {
            for (const tenantId of tenantList) {
              await updateTenantStaffDocuments(tenantId)(
                response.data.updateStaffDocument.id,
                'sin',
                'sin'
              );
            }
          }
        }
        handleSinReg();
        handleSinStatus();
        closeDrawer();
      } else {
        handlePopUp('error', 'SIN number already registered');
        setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      console.log(error);
      handlePopUp('error', 'Unable to upload document');
      setSubmitting(false);
    }
  };

  const updateSinStatus = () => {
    if (sinDocId) {
      updateSinDoc().catch(err => {
        console.log(err);
        console.log(err);
      });
    } else {
      createSinDoc().catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  };

  const closeDrawer = () => {
    handlePopUp(
      'success',
      'Your documents have been uploaded for verification'
    );
    const currentUrl = window.location.pathname;

    setTimeout(() => {
      if (currentUrl === '/registration/upload/sin') {
        navigate('/info/id');
      } else if (currentUrl === '/document/sin') {
        navigate('/documents');
      }
    }, 700);
  };

  const validateSin = () => {
    let sumSin = 0;
    let doubledNumberSin = false;

    if (sin) {
      for (let i = sin.length - 1; i >= 0; i--) {
        const currentDigit = parseInt(sin.charAt(i), 10);
        if (doubledNumberSin) {
          if (currentDigit * 2 > 9) {
            sumSin += currentDigit * 2 - 9;
          } else {
            sumSin += currentDigit * 2;
          }
        } else {
          sumSin += currentDigit;
        }
        doubledNumberSin = !doubledNumberSin;
      }
    }
    return sumSin % 10 === 0;
  };

  const handleSubmit = () => {
    if (sin && validateSin()) {
      if (sin.slice(0, 1) === '9') {
        if (!idExpired()) {
          updateSinStatus();
          setError('');
        } else {
          setError('Your ID is expired');
        }
      } else {
        updateSinStatus();
      }
    } else {
      setError('Please provide a valid SIN');
    }
  };

  return (
    <Grow in unmountOnExit>
      <Box sx={{width: '100%', mt: 2}}>
        <Box sx={tabOneBtnBox}>
          <Button variant="outlined" sx={continueBtn} component="label">
            <PhotoCamera sx={photoIcon} />
            Upload SIN (Front)
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={changeHandler}
            />
          </Button>
        </Box>
        <Box sx={tabOneBtnBox}>
          {error && <Box sx={errorSin}>{error}</Box>}
          <TextField
            sx={qualiTextField}
            type="text"
            name="exp"
            label="SIN number"
            value={sin}
            onChange={handleSin}
            inputProps={{
              pattern: '\\d*',
            }}
          />
        </Box>
        {sin && sin.slice(0, 1) === '9' && (
          <Box sx={tabOneBtnBox}>
            <CustomDatePicker
              value={value}
              handleChange={handleChange}
              label="Expiry Date"
            />
          </Box>
        )}
        <Box sx={qualiBtnContainer}>
          <Button
            variant="outlined"
            sx={continueBtn}
            disabled={submitting || !sin}
            onPointerUp={handleSubmit}
          >
            {submitting ? (
              <CircularProgress
                size="1.3rem"
                sx={{color: 'primary.light', fontSize: '12px'}}
              />
            ) : (
              'Save'
            )}
          </Button>
        </Box>
        <PopUp
          isOpen={popUp}
          onClose={closePopUp}
          message={message}
          status={status}
        />
        <CustomDialog
          open={dialog}
          close={handleDialog}
          title="This SIN number has already been registered. Do you want to proceed to the Login page?"
          leftOnClick={handleDialog}
          rightOnClick={handleLoginPage}
        />
      </Box>
    </Grow>
  );
};
