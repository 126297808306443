export const scoreHeader = {
  fontSize: '28px',
  fontWeight: 'bold',
  pb: 2,
  textAlign: 'center',
};

export const chartBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 3,
  height: '300px',
  position: 'relative',
};

export const scoreBox = {
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
};

export const badgeIcon = {width: '130px', mx: 2, mt: 2, mb: 1};

export const scoreDesc = {
  color: 'secondary.dark',
  mb: 2,
  textAlign: 'center',
  px: 1,
};

export const achievementTitleBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const badgeBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  mb: 1,
};
