export const widthContainer = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100vw',
  maxWidth: '100%',
  overflow: 'auto',
  px: 2,
};

export const qrContainer = {
  width: {xs: '100%', md: '50%', lg: '30%'},
  height: {xs: '280px', sm: '380px', md: '600px'},
  my: 0,
  mb: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const descBox = {
  fontWeight: 'bold',
  color: 'primary.main',
  display: 'flex',
  justifyContent: 'center',
};

export const spanBox = {
  display: 'flex',
  justifyContent: 'center',
};
