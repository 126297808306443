export const schedule = [
  {
    day: 'monday',
    label: 'Mon',
    morning: true,
    afternoon: true,
    night: true,
  },
  {
    day: 'tuesday',
    label: 'Tue',
    morning: true,
    afternoon: true,
    night: true,
  },
  {
    day: 'wednesday',
    label: 'Wed',
    morning: true,
    afternoon: true,
    night: true,
  },
  {
    day: 'thursday',
    label: 'Thu',
    morning: true,
    afternoon: true,
    night: true,
  },
  {
    day: 'friday',
    label: 'Fri',
    morning: true,
    afternoon: true,
    night: true,
  },
  {
    day: 'saturday',
    label: 'Sat',
    morning: true,
    afternoon: true,
    night: true,
  },
  {
    day: 'sunday',
    label: 'Sun',
    morning: true,
    afternoon: true,
    night: true,
  },
];
