import {
  Box,
  CircularProgress,
  CircularProgressProps,
  circularProgressClasses,
} from '@mui/material';
import {scrollLoaderCenterBox} from '../Loader/ScrollLoader';

export const CustomCircularProgress = (props: CircularProgressProps) => {
  return (
    <Box sx={scrollLoaderCenterBox}>
      <Box sx={{position: 'relative'}}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: theme =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: '#3E82BF',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    </Box>
  );
};
