export const scrollLimitJobDetails = {
  position: 'absolute',
  top: {xs: '58px', sm: '64px'},
  bottom: '56px',
  p: 0,
  overflowY: 'scroll',
};

export const mapBox = {
  width: '100%',
  height: '200px',
  border: '3px solid',
  borderColor: 'secondary.main',
  my: 2,
};

export const detailTitle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: {xs: '3rem', sm: '3.5rem'},
  fontSize: '1.3rem',
  fontWeight: 'bold',
};

export const jobBoxBorder = {
  border: '3px solid',
  borderColor: 'secondary.main',
  px: 2,
  pb: 2,
  mb: 3,
};

export const jobSubTitle = {
  textTransform: 'capitalize',
  fontWeight: 'bold',
  fontSize: '1.1rem',
  py: 1,
};

export const dropBtn = {
  width: '100%',
  height: '3rem',
  backgroundColor: 'secondary.main',
  color: 'primary.main',
  border: 'none',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
};

export const jobDetailsBtnBox = {
  mx: 2,
  mb: 3,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};
