/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  fetchAgencyEmploymentContractStatus,
  fetchAgencyEmploymentContractsList,
} from '../../../providers/api/contract';
import {handleCustomError} from '../../RouteErrorView/RouteErrorView';

export const checkStaffSignature = async (contract_list: any) => {
  if (contract_list.length !== 0) {
    const promises = contract_list.map((contract: any) => {
      return new Promise(resolve => {
        fetchAgencyEmploymentContractStatus(contract.id)
          .then(staffSignedContract => {
            const sign_status = staffSignedContract.length !== 0;
            const contractWithStatus = {...contract, sign_status};
            const contractName = JSON.stringify(contract.name);
            localStorage.setItem(
              `contract.${contract.id}.${contractName}`,
              String(sign_status)
            );
            resolve(contractWithStatus);
          })
          .catch(err => handleCustomError(err));
      });
    });

    return Promise.all(promises)
      .then(res => {
        return res;
      })
      .catch(err => handleCustomError(err));
  } else {
    return [];
  }
};

export const getContractsFromLocalStorage = () => {
  const keys = Object.keys(localStorage);
  const contracts = keys
    .filter(key => key.startsWith('contract'))
    .map(key => {
      const [, id, name] = key.split('.');
      const sign_status = JSON.parse(localStorage.getItem(key)!);
      return {id, name: JSON.parse(name), sign_status};
    });

  return contracts;
};

export const checkContractsList = async () => {
  try {
    const localStorageContracts = getContractsFromLocalStorage();
    if (localStorageContracts.length > 0) {
      const allSigned = localStorageContracts.every(
        (item: any) => item.sign_status === true
      );

      return {
        allSigned,
        contractsWithStaffSignatureStatus: localStorageContracts,
      };
    }

    const contractList = await fetchAgencyEmploymentContractsList();
    if (contractList.length === 0) {
      return {
        allSigned: true,
        contractsWithStaffSignatureStatus: [],
      };
    }

    const contractsWithStaffSignatureStatus: any = await checkStaffSignature(
      contractList
    );

    const allSigned = contractsWithStaffSignatureStatus.every(
      (item: any) => item.sign_status === true
    );

    return {allSigned, contractsWithStaffSignatureStatus};
  } catch (error) {
    handleCustomError(error);
    return error;
  }
};

// Check if staff signed all contracts if being approved to work in agency
export const loadContractChecker = async (
  setContractChecker: Function,
  setRequiredContracts: Function,
  tenantProfile: any
) => {
  try {
    const currentTenant = localStorage.getItem('tenantId');

    if (currentTenant === 'default') return;

    const staffVerificationStatus = tenantProfile?.verification_status;

    if (staffVerificationStatus === 'approved') {
      const contracts: any = await checkContractsList();

      if (!contracts?.allSigned) {
        setContractChecker(true);
        setRequiredContracts(contracts?.contractsWithStaffSignatureStatus);
      }
    }
  } catch (error) {
    handleCustomError(error);
  }
};

export const removeTenantContracts = () => {
  Object.keys(localStorage)
    .filter(key => key.startsWith('contract'))
    .forEach(key => localStorage.removeItem(key));
};
