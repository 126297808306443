/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {BottomBar} from '../../../../../../components/common/BottomBar/BottomBar';
import {EmptyPage} from '../../../../../../components/common/EmptyPageLoader/EmptyPageLoader';
import {ScrollLoader} from '../../../../../../components/common/Loader/ScrollLoader';
import {Navbar} from '../../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../../components/common/PageWrapper/PageWrapper';
import {fetchVacationHistory} from '../../../../../../providers/api/vacation';
import {bottomSubPageLimit, chequeEmptyBox} from '../../../ProfileViewStyles';

export const VacationHistoryView = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [vacationHistory, setVacationHistory] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [noAgent, setNoAgent] = useState(false);

  useEffect(() => {
    const tenantId = localStorage.getItem('tenantId');
    if (tenantId === 'default') {
      setNoAgent(true);
      setLoading(false);
    } else {
      loadHistory().catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  }, []);

  const loadHistory = async () => {
    try {
      const {vacationHist, count}: any = await fetchVacationHistory();
      setVacationHistory(vacationHist);
      setPageCount(count);
    } catch (err: any) {
      console.log(err);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event: any, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedHistory = vacationHistory.slice(startIndex, endIndex);

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Vacation History">
          {loading ? (
            <ScrollLoader />
          ) : noAgent ? (
            <Box sx={chequeEmptyBox}>
              You currently do not associate with an agency yet or you are
              currently in DigiJobs. Switch Profile and check your vacation
              history with an agency
            </Box>
          ) : (
            <>
              {vacationHistory.length === 0 ? (
                <Box sx={{mt: '-100px'}}>
                  <EmptyPage
                    msg="You don't have any vacation history yet. New record will be
                  shown here."
                  />
                </Box>
              ) : (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="right">Request Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedHistory.map((row: any) => (
                        <TableRow key={row.id}>
                          <TableCell scope="row">
                            {moment(row.start_date).format('DD MMM YYYY')}
                          </TableCell>
                          <TableCell align="center">
                            {moment(row.end_date).format('DD MMM YYYY')}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              textTransform: 'capitalize',
                              color:
                                row.status === 'approved'
                                  ? 'success.main'
                                  : row.status === 'pending'
                                  ? 'info.main'
                                  : row.status === 'cancelled'
                                  ? 'error.main'
                                  : 'primary.main',
                            }}
                          >
                            {row.status}
                          </TableCell>
                          <TableCell align="right">
                            {moment(row.created_at).format('DD MMM YYYY')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={pageCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
            </>
          )}
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
