/* eslint-disable @typescript-eslint/no-explicit-any */
import {getCurrentUser} from '@digistaff/app';
import {app} from '../../providers/Accounts';
import {useEffect} from 'react';
import {usePopUp} from '../../hooks/usePopUp';
import {PopUp} from '../../components/common/PopUp/PopUp';

// Prevent user minimized app for too long or lost connection
// Only for certain registration pages that are likely be interrupted
export const RegRoute = ({children}: any) => {
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();

  const fetchUser = async () => {
    try {
      await getCurrentUser(app);
    } catch (error) {
      handlePopUp(
        'error',
        'The registration time has exceeded the time limit. You can proceed with your application by logging in again. We will now redirect you to the login page.'
      );
      setTimeout(() => {
        window.location.href = `${window.location.origin}/`;
      }, 1000);
    }
  };

  useEffect(() => {
    fetchUser().catch(err => {
      console.log(err);
      console.log(err);
    });
  });

  return (
    <>
      {children}
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
    </>
  );
};
