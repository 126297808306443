import {Slide} from '@mui/material';
import {ReactElement} from 'react';
import {SlideWrapperProps} from '../../../types/types';

export const CustomSlider = (props: SlideWrapperProps) => (
  <Slide
    direction={props.direction}
    in={true}
    mountOnEnter
    unmountOnExit
    {...{timeout: props.fadeIn}}
  >
    {props.children as ReactElement}
  </Slide>
);
