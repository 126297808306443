/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  fetchCertification,
  listStaffCertifications,
} from '../../../providers/api/certifications';
import {withTenant} from '../../../providers/api/withTenant';
import {handleCustomError} from '../../RouteErrorView/RouteErrorView';

export const checkRequiredAgencyCerts = async (
  staffId: string,
  requiredCerts: any
) => {
  try {
    const res = await listStaffCertifications(staffId);

    const resTypes = new Set(
      res.map((cert: any) => cert.type.replace('certification_', ''))
    );

    const missingCerts = [];
    for (const cert of requiredCerts) {
      if (!resTypes.has(cert)) {
        const missingCert = await fetchCertification(cert);
        missingCerts.push(missingCert);
      }
    }

    if (missingCerts.length === 0) {
      return {hasAllCerts: true, certArray: []};
    } else {
      return {hasAllCerts: false, certArray: missingCerts};
    }
  } catch (error) {
    return handleCustomError(error);
  }
};

export const checkRequiredAgencyCertsInDigiJobs = (tenantId: string) =>
  withTenant(tenantId, checkRequiredAgencyCerts);
