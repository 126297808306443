/* eslint-disable @typescript-eslint/no-explicit-any */

import {app} from '../Accounts';
import {createSystemIssue} from '@digistaff/business';

export const createNewSystemIssue = async (session: any, desc: string) => {
  try {
    const payload = {
      username: session.lastAuthUser,
      id_token: session.idToken,
      refresh_token: session.refreshToken,
      access_token: session.accessToken,
      notes: desc,
      application: 'staff.digistaff.ca',
    };
    return await createSystemIssue(app, payload);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};
