/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import {Navbar} from '../../../../../../components/common/NavBar/NavBar';
import {bottomSubPageLimit, photoIcon} from '../../../ProfileViewStyles';
import {PageWrapper} from '../../../../../../components/common/PageWrapper/PageWrapper';
import {useState} from 'react';
import {useFileUpload} from '../../../../../../hooks/useFileUpload';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {PhotoCamera} from '@mui/icons-material';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {BottomBar} from '../../../../../../components/common/BottomBar/BottomBar';
import {handleCustomError} from '../../../../../RouteErrorView/RouteErrorView';
import {
  createGovernmentDocument,
  createStaffDocInGlobal,
} from '../../../../../../providers/api/documents';
import {useCurrentUser} from '../../../../../../hooks/useCurrentUser';
import {useNavigate} from 'react-router-dom';

export const InAppNewGovDoc = () => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState('');
  const [cert, setCert] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const staffId = localStorage.getItem('email');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const {userValid, sessionInvalidRedirection, inAppErrMsg} = useCurrentUser();
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();

  const emptyFields = () => {
    setCert('');
    setImageUrl('');
  };

  const handleCertChange = (newValue: string) => {
    emptyFields();
    setCert(newValue);
  };

  const handleReturn = () => {
    handlePopUp(
      'success',
      'Your documents have been uploaded for verification'
    );
    setTimeout(() => navigate('/documents'), 700);
  };

  const handleUploadPhoto = async () => {
    try {
      const uploaded: any = await handleFileUpload(staffId!);
      return uploaded?.data?.result?.Location;
    } catch (error) {
      handleCustomError(error);
    }
  };

  const createGovDocInGlobal = async (photo: string) => {
    try {
      const res = await createGovernmentDocument(staffId!, cert, photo);

      const formattedType =
        cert === 'passport'
          ? 'mandatory_government_passport'
          : 'mandatory_government_driverLicense';

      await createStaffDocInGlobal(
        staffId!,
        res?.data?.createStaffDocument?.id,
        formattedType
      );
    } catch (error) {
      handleCustomError(error);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);

    if (!fileSelected) {
      handlePopUp('error', 'Please upload document');
      setSubmitting(false);
      return;
    }

    try {
      const userStatus = await userValid();
      if (!userStatus) {
        handlePopUp('error', inAppErrMsg);
        sessionInvalidRedirection();
        return;
      }

      const photo = await handleUploadPhoto();
      await createGovDocInGlobal(photo);
      handleReturn();
    } catch (error) {
      handleCustomError(error);
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Upload Government Issued ID">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mx: 2,
              mt: 3,
            }}
          >
            <Box component="img" src={imageUrl} sx={{width: '100%'}} />
            <TextField
              value={cert}
              select
              label="select document type"
              sx={{width: '100%', textTransform: 'capitalize'}}
            >
              {['passport', 'driver license'].map(o => (
                <MenuItem
                  key={o}
                  value={o}
                  sx={{textTransform: 'capitalize'}}
                  onClick={() => handleCertChange(o)}
                >
                  {o}
                </MenuItem>
              ))}
            </TextField>
            {cert !== '' && (
              <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
                <Button variant="outlined" sx={continueBtn} component="label">
                  <PhotoCamera sx={photoIcon} />
                  Upload Government Issued ID
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={changeHandler}
                  />
                </Button>
                <Button
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={handleSubmit}
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress
                      size="1.3rem"
                      sx={{color: 'primary.light', fontSize: '12px'}}
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </Box>
            )}
            <PopUp
              isOpen={popUp}
              onClose={closePopUp}
              message={message}
              status={status}
            />
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
