/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {getSinDocument} from '../../../../../../providers/api/documents';
import {Box, Button} from '@mui/material';
import {
  bottomSubPageLimit,
  flexCtrCtr,
  roundBtn,
  roundBtnSelected,
} from '../../../ProfileViewStyles';
import {SinTabOne} from '../DocumentsSubSections/SinTabOne';
import {SinTabTwo} from '../DocumentsSubSections/SinTabTwo';
import {BottomBar} from '../../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../../components/common/PageWrapper/PageWrapper';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';

export const InAppSinDocView = () => {
  const [sinTab, setSinTab] = useState(-1);
  const [sinPhoto, setSinPhoto] = useState('');
  const [sinNo, setSinNo] = useState('');
  const [sinExpiry, setSinExpiry] = useState('');
  const [retrievedDocType, setRetrievedDocType] = useState('');
  const location = useLocation();
  const sinDocId = location.state.param1;
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const navigate = useNavigate();

  useEffect(() => {
    getData().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const getData = async () => {
    if (sinDocId) {
      try {
        const docRetrieved: any = await getSinDocument(sinDocId!);
        if (docRetrieved) {
          if (docRetrieved.doc_type === 'sin') {
            setSinTab(0);
          } else if (docRetrieved.doc_type === 'cra') {
            setSinTab(1);
          }
          setRetrievedDocType(docRetrieved.doc_type);
          setSinNo(docRetrieved.doc_no);
          setSinPhoto(docRetrieved._photo);
          setSinExpiry(docRetrieved.expiry_date);
        } else {
          handlePopUp(
            'error',
            'Document unavailable. Now redirecting you to upload the required document...'
          );
          setTimeout(() => {
            navigate('/documents');
          }, 1000);
        }
      } catch (err) {
        console.log(err);
        console.log(err);
      }
    }
  };

  const switchTab = (idx: string) => {
    if (idx === '0') {
      setSinTab(0);
    } else {
      setSinTab(1);
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Update Government Issued ID">
          <Box sx={{mx: 2, mt: 3}}>
            <Box component="img" src={sinPhoto} sx={{width: '100%'}} />
            <Box sx={flexCtrCtr}>
              <Box sx={{display: 'flex', justifyContent: 'center', gap: 3}}>
                <Button
                  variant="outlined"
                  sx={sinTab === 0 ? roundBtnSelected : roundBtn}
                  onPointerUp={() => switchTab('0')}
                >
                  I have a valid SIN
                </Button>
                <Button
                  variant="outlined"
                  sx={sinTab === 1 ? roundBtnSelected : roundBtn}
                  onPointerUp={() => switchTab('1')}
                >
                  I do NOT have a SIN, but I have registered for one
                </Button>
              </Box>
            </Box>
            {sinTab === 0 && (
              <SinTabOne
                sinNo={sinNo}
                sinExpiry={sinExpiry}
                sinPhoto={sinPhoto}
                sinDocId={sinDocId}
                retrievedDocType={retrievedDocType}
                inApp
              />
            )}
            {sinTab === 1 && (
              <SinTabTwo
                sinPhoto={sinPhoto}
                sinDocId={sinDocId}
                retrievedDocType={retrievedDocType}
                inApp
              />
            )}
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
