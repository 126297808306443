/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {PhotoCamera} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grow,
  MenuItem,
  TextField,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useFileUpload} from '../../../../hooks/useFileUpload';
import {usePopUp} from '../../../../hooks/usePopUp';
import {
  createGovernmentDocument,
  getGovernmentDocument,
  updateGovernmentDocument,
} from '../../../../providers/api/documents';
import {useLocation, useNavigate} from 'react-router-dom';
import {photoIcon} from '../../../Private/ProfileView/ProfileViewStyles';
import {
  continueBtn,
  userScrollLimit,
} from '../../LandingView/LandingViewStyles';
import {PopUp} from '../../../../components/common/PopUp/PopUp';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {useCurrentUser} from '../../../../hooks/useCurrentUser';

export const GovernmentDocRegView = () => {
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [cert, setCert] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const {handleFileUpload, changeHandler, selectedFile, fileSelected} =
    useFileUpload();
  const staffId = localStorage.getItem('email');
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const govDocId = location.state.param1;
  const navigate = useNavigate();
  const {userValid, sessionInvalidRedirection, errMsg} = useCurrentUser();

  useEffect(() => {
    fetchGovernmentDocument().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const fetchGovernmentDocument = async () => {
    if (govDocId) {
      try {
        const retrievedDoc = await getGovernmentDocument(govDocId);
        if (retrievedDoc) {
          setCert(retrievedDoc.doc_type);
          setImageUrl(retrievedDoc._photo);
        }
      } catch (err) {
        console.log(err);
        console.log(err);
      }
    }
  };

  const certNotCompleted = () => {
    if (!fileSelected || !cert) {
      return true;
    }
    return false;
  };

  const createGovDoc = async () => {
    setSubmitting(true);

    const userStatus = await userValid();
    if (!userStatus) {
      handlePopUp('error', errMsg);
      sessionInvalidRedirection();
      return;
    }

    if (!fileSelected) {
      handlePopUp('error', 'Please upload document');
      setSubmitting(false);
      return;
    }

    const uploaded: any = await handleFileUpload(staffId!);
    const govPhoto = uploaded.data.result.Location;

    const createdDocument = await createGovernmentDocument(
      staffId!,
      cert,
      govPhoto
    );
    if (createdDocument === null) {
      handlePopUp('error', 'Unable to upload document, please contact support');
      setSubmitting(false);
      return;
    } else {
      autoReturnToDocCenter();
      setSubmitting(false);
    }
  };

  const updateGovDoc = async () => {
    setSubmitting(true);

    const userStatus = await userValid();
    if (!userStatus) {
      handlePopUp('error', errMsg);
      sessionInvalidRedirection();
      return;
    }

    if (!fileSelected) {
      handlePopUp('error', 'Please upload document');
      setSubmitting(false);
      return;
    }

    const uploaded: any = await handleFileUpload(staffId!);
    const govPhoto = uploaded.data.result.Location;

    const updatedDocument = await updateGovernmentDocument(
      govDocId!,
      cert,
      govPhoto
    );
    if (updatedDocument === null) {
      handlePopUp('error', 'Unable to upload document, please contact support');
      setSubmitting(false);
      return;
    } else {
      autoReturnToDocCenter();
      setSubmitting(false);
    }
  };

  const handleSubmit = () => {
    if (govDocId) {
      updateGovDoc().catch(err => {
        console.log(err);
        console.log(err);
      });
    } else {
      createGovDoc().catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  };

  const autoReturnToDocCenter = () => {
    handlePopUp(
      'success',
      'Your documents have been uploaded for verification'
    );
    setTimeout(() => navigate('/info/id'), 700);
  };

  const emptyFields = () => {
    setCert('');
    setImageUrl('');
  };

  const handleCertChange = (newValue: string) => {
    emptyFields();
    setCert(newValue);
  };

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/info/id" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Upload Government Issued ID">
          <Box
            id="license-field"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mt: 2,
              px: 2,
            }}
          >
            <TextField
              value={cert}
              select
              label="select document type"
              sx={{width: '100%', textTransform: 'capitalize'}}
            >
              {['passport', 'driver license'].map(o => (
                <MenuItem
                  id={o}
                  key={o}
                  value={o}
                  sx={{textTransform: 'capitalize'}}
                  onClick={() => handleCertChange(o)}
                >
                  {o}
                </MenuItem>
              ))}
            </TextField>
            {cert !== '' && (
              <Grow in={true} unmountOnExit>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
                  <Button
                    id="upload-btn"
                    variant="outlined"
                    sx={continueBtn}
                    component="label"
                  >
                    <PhotoCamera sx={photoIcon} />
                    Upload Government Issued ID
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={changeHandler}
                    />
                  </Button>
                  <Button
                    id="submit-btn"
                    variant="outlined"
                    sx={continueBtn}
                    onPointerUp={handleSubmit}
                    disabled={certNotCompleted() || submitting}
                  >
                    {submitting ? (
                      <CircularProgress
                        size="1.3rem"
                        sx={{color: 'primary.light', fontSize: '12px'}}
                      />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Box>
              </Grow>
            )}
            <PopUp
              isOpen={popUp}
              onClose={closePopUp}
              message={message}
              status={status}
            />
          </Box>
        </PageWrapper>
      </Box>
    </>
  );
};
