/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createStaffDocumentTenant,
  createStaffProfileTenant,
  getStaffProfile,
  listStaffInviteTenants,
} from '@digistaff/staff';
import {app, setTenantKey} from '../Accounts';
import {getStaffTenant} from '@digistaff/business';
import {DocumentStatus} from './documents';

export const fetchAgencyList = async (staffId: string) => {
  try {
    const res: any = await getStaffProfile(app, staffId);

    if (res?.data?.getStaffProfile?.timezone_offset !== null)
      localStorage.setItem(
        'staffAddressTimezonOffset',
        res?.data?.getStaffProfile?.timezone_offset
      );

    return res?.data?.getStaffProfile?.tenants;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const inAppFetchAgencyList = async (staffId: string) => {
  try {
    const res: any = await getStaffProfile(app, staffId);

    return res?.data?.getStaffProfile?.tenants;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchTenantName = async (tenantId: string) => {
  try {
    const res = await getStaffTenant(app, tenantId);

    return res.data.getTenant;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const fetchStaffInviteTenants = async (
  staffId: string,
  staffPhoneNo: string
) => {
  try {
    const emailCheck: any = await listStaffInviteTenants(app, {
      filter: {email: staffId},
    });

    const phoneCheck: any = await listStaffInviteTenants(app, {
      filter: {phone: staffPhoneNo},
    });

    const combinedCheck = [
      ...emailCheck.data.listStaffInviteTenants,
      ...phoneCheck.data.listStaffInviteTenants,
    ];

    const uniqueTenants = new Set();

    const filteredTenants = combinedCheck.filter((tenant: any) => {
      if (uniqueTenants.has(tenant.tenant_id)) {
        return false;
      } else {
        uniqueTenants.add(tenant.tenant_id);
        return true;
      }
    });

    return filteredTenants;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const createStaffDocumentInTenant = async (
  staffId: string,
  tenantId: string,
  document: any
) => {
  try {
    const payload = {
      status:
        document[0]?.type === 'mandatory_work_applied'
          ? DocumentStatus.approved
          : DocumentStatus.pending,
      staff_id: staffId,
      type: document[0]?.type,
      id: document[0]?.id,
    };
    setTenantKey(app, tenantId);

    const res: any = await createStaffDocumentTenant(app, payload);

    return res.data;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const createStaffProfileInTenant = async (
  staffId: string,
  tenantId: string
): Promise<any> => {
  try {
    const payload = {
      id: staffId,
      is_suspended: false,
      verification_status: DocumentStatus.pending,
    };

    setTenantKey(app, tenantId);

    const res: any = await createStaffProfileTenant(app, payload);

    return res.data;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};
