/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button} from '@mui/material';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {bottomSubPageLimit} from '../../ProfileViewStyles';
import {useLocation} from 'react-router-dom';
import {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {termsBox} from '../../../../Public/Registration/RegistrationStyles';
import {Bridge} from '../../../../../Bridge';
import {Download} from '@mui/icons-material';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const ViewContract = () => {
  const {state} = useLocation();
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({numPages}: any) => setNumPages(numPages);

  const zoomIn = () => setScale(scale + 0.1);

  const zoomOut = () => {
    if (scale > 0.1) {
      setScale(scale - 0.1);
    }
  };

  const downloadContract = async () => {
    await Bridge.openURL(state.url);
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title={state.name}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              m: 1,
            }}
            onPointerUp={downloadContract}
          >
            Download <Download />
          </Box>
          <Box sx={termsBox} id="termBox">
            <div
              style={{
                overflow: 'auto',
                width: '100%',
                height: '100%',
              }}
            >
              <div
                style={{
                  transform: `scale(${scale})`,
                  transformOrigin: '0 0',
                }}
              >
                <Document
                  file={state.url}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </div>
            </div>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              mt: 2,
              flexWrap: 'wrap',
            }}
          >
            <Button variant="outlined" onClick={zoomIn} sx={{flexGrow: 1}}>
              Zoom In
            </Button>
            <Button variant="outlined" onClick={zoomOut} sx={{flexGrow: 1}}>
              Zoom Out
            </Button>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
