import {Box} from '@mui/material';
import {emptyPage} from '../../../views/Private/ScheduleView/ScheduleViewStyles';
import {ScrollLoader} from '../Loader/ScrollLoader';

export interface EmptyPageMsgProps {
  msg: string;
}

export const EmptyPageLoader = () => {
  return (
    <Box sx={emptyPage}>
      <ScrollLoader />
    </Box>
  );
};

export const EmptyPage = ({msg}: EmptyPageMsgProps) => {
  return <Box sx={emptyPage}>{msg}</Box>;
};
