import {Box, Button} from '@mui/material';
import {QuizNavBtnProps} from '../../../types/types';
import {continueBtn} from '../../../views/Public/LandingView/LandingViewStyles';

export const QuizNavigationButton = (props: QuizNavBtnProps) => {
  return (
    <Box sx={{display: 'flex', gap: 3}}>
      <Button
        variant="outlined"
        sx={continueBtn}
        disabled={props.leftDisabled}
        onPointerUp={props.leftOnClick}
        id="previous-btn"
      >
        {props.leftBtnText ? props.leftBtnText : 'previous'}
      </Button>
      <Button
        variant="outlined"
        sx={continueBtn}
        disabled={props.rightDisabled}
        onPointerUp={props.rightOnClick}
        id="next-btn"
      >
        {props.rightBtnText ? props.rightBtnText : 'next'}
      </Button>
    </Box>
  );
};
