import {Box, Button, TextField} from '@mui/material';
import React, {useState} from 'react';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {discardBtn, textFieldEmer} from '../../../ProfileViewStyles';
import {VacationModalBoxProps} from '../../../../../../types/types';

export const VacationModalBox = ({
  handleModal,
  handleCancel,
}: VacationModalBoxProps) => {
  const [cxl, setCxl] = useState('');

  const handleText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCxl(e.target.value);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        height: '35vh',
        bgcolor: 'primary.light',
        boxShadow: 24,
        borderRadius: '8px',
        outline: 'none',
        overflow: 'scroll',
        p: 2,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          my: 1,
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        Confirm cancel your vacation?
        <Box sx={{my: 1}}>
          Type <b>CANCEL VACATION</b> in the field below to confirm:
        </Box>
      </Box>
      <Box sx={{my: 2}}>
        <TextField sx={textFieldEmer} value={cxl} onChange={handleText} />
      </Box>
      <Box sx={{display: 'flex', gap: 2}}>
        <Button variant="outlined" sx={continueBtn} onPointerUp={handleModal}>
          back
        </Button>
        <Button
          variant="outlined"
          sx={discardBtn}
          onPointerUp={handleCancel}
          disabled={cxl !== 'CANCEL VACATION'}
        >
          next
        </Button>
      </Box>
    </Box>
  );
};
