/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import {AppointmentTimeSlots} from '../../../../../types/types';
import {fetchTimeSlotAvailability} from '../../../../../providers/api/appointment';
import {handleCustomError} from '../../../../RouteErrorView/RouteErrorView';

export const generateTimeSlots = (
  times: any,
  currentDate: any,
  appriseHours: number,
  timezoneLocationSetting: any
) => {
  const timeSlots: AppointmentTimeSlots = {};
  const currentDateTime = moment();
  const nextAvailableDateTime = currentDateTime
    .clone()
    .add(appriseHours, 'hours');

  for (const day in times) {
    const dayTimes = times[day];

    const appointmentTimeZoneSetting = timezoneLocationSetting.offset;

    const timezoneOffset =
      (appointmentTimeZoneSetting - new Date().getTimezoneOffset()) * 60;

    const start = new Date(parseTimeString(dayTimes.startTime, timezoneOffset));

    const end = new Date(parseTimeString(dayTimes.endTime, timezoneOffset));

    const dayTimeSlots = [];

    while (start <= end) {
      const time = moment(start.toISOString().substring(11, 16), 'HH:mm');
      const timeString = time.format('hh:mm A');
      const timeSlotDateTime = currentDate
        .clone()
        .day(day)
        .hour(start.getUTCHours())
        .minute(start.getUTCMinutes());
      const disabled = timeSlotDateTime.isBefore(nextAvailableDateTime);

      dayTimeSlots.push({time: timeString, disabled});
      start.setMinutes(start.getMinutes() + dayTimes.intervalMinutes);
    }

    timeSlots[day] = dayTimeSlots;
  }

  return timeSlots;
};

const parseTimeString = (time: string, originTimezoneOffset: number) => {
  let day = 2;

  const a = time.split(':');

  const timeStringInSeconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  let tmp = timeStringInSeconds - originTimezoneOffset;

  if (tmp < 0) {
    tmp = 86400 + tmp;
    day = 1;
  }

  return `1970-01-0${day}T${secondsToTimeString(tmp)}Z`;
};

const secondsToTimeString = (input: number) => {
  let hours: any = Math.floor(input / 3600);
  let minutes: any = Math.floor((input - hours * 3600) / 60);
  let seconds: any = input - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
};

export const isDisabled = (
  startTime: string,
  date: moment.Moment,
  appriseHours: number
) => {
  const currentDateTime = moment();
  const nextAvailableDateTime = currentDateTime
    .clone()
    .add(appriseHours, 'hours');

  return startTime === null || date.isBefore(nextAvailableDateTime, 'day');
};

export const generateDates = (
  dates: any,
  appriseHours: number,
  customDateSetting: any[],
  simulatedDates: number
) => {
  const newDates = [];

  for (let i = 1; i < simulatedDates; i++) {
    const currentDate = moment().add(i, 'days');
    const dayOfWeek = currentDate.format('dddd').toLowerCase();

    const dateObj = {
      date: currentDate,
      disabled: isDisabled(
        dates[dayOfWeek].startTime,
        currentDate,
        appriseHours
      ),
      customBusinessDate: false,
    };

    newDates.push(dateObj);
  }

  for (let i = 0; i < customDateSetting.length; i++) {
    const customDate = moment(new Date(customDateSetting[i].start_date));

    const startTime = customDate.format('HH:mm:ss');
    const customDateObj = {
      date: customDate,
      disabled: isDisabled(startTime, customDate, appriseHours),
      customBusinessDate: true,
      customSetting: customDateSetting[i],
    };

    const dateIndex = newDates.findIndex(d =>
      d.date.isSame(customDateObj.date, 'day')
    );

    if (dateIndex === -1) {
      newDates.push(customDateObj);
    } else {
      newDates[dateIndex] = customDateObj;
    }
  }

  newDates.sort((a, b) => a.date.valueOf() - b.date.valueOf());

  return newDates;
};

export const generateAndDisableTimeSlots = async (
  times: any,
  availableSlots: any,
  selectedDate: any,
  appriseHours: number,
  timezoneLocationSetting: any,
  setTimeSlot: Function
) => {
  try {
    const timeSlotsTemp = generateTimeSlots(
      times,
      moment(selectedDate, 'DD/MM/YYYY'),
      appriseHours,
      timezoneLocationSetting
    );

    const selectedWeekday = moment(selectedDate, 'DD/MM/YYYY')
      .format('dddd')
      .toLowerCase();
    const selectedTimeSlots = timeSlotsTemp[selectedWeekday];

    const availableTimeSlots = await fetchTimeSlotAvailability(
      selectedDate,
      selectedTimeSlots.map(slot => slot.time),
      availableSlots
    );

    const mergedSlots = selectedTimeSlots.map(slot => {
      const available = availableTimeSlots.includes(slot.time);
      return {...slot, available};
    });

    setTimeSlot(mergedSlots);
  } catch (err) {
    handleCustomError(err);
  }
};

export const extractBusinessDetails = (
  selectedWeekday: any,
  selectedLocation: any
) => {
  return {
    weekEnding: selectedWeekday?.toISOString(),
    businessLocationId: selectedLocation.businessLocationId,
    businessName: selectedLocation.businessName,
    businessStreet: selectedLocation.businessLocation?.street,
    businessCity: selectedLocation.businessLocation?.city,
    businessPostal: selectedLocation.businessLocation?.postal,
    businessProvince: selectedLocation.businessLocation?.province,
    businessCountry: selectedLocation.businessLocation?.country,
  };
};

export const extractAppointmentPayload = (
  typePurpose: string,
  selectedLocation: any,
  selectedWeekday: any,
  optionalNotes: any,
  reason: any
) => {
  const customPayload = selectedLocation
    ? extractBusinessDetails(selectedWeekday, selectedLocation)
    : null;
  const note = typePurpose === 'payout' ? optionalNotes : reason;
  return {customPayload, note};
};

export const formatTime = (date: any) => moment(date).format('HH:mm:ss');

export const checkCustomSettings = (settings: any, selectedDate: any) =>
  settings.find(
    (setting: any) => formatTime(setting.start_date) === selectedDate
  );
