/* eslint-disable @typescript-eslint/no-explicit-any */
import {app} from '../Accounts';
import {
  countPayrollStaff,
  getPayrollStaff,
  listPayrollStaff,
} from '@digistaff/payroll';

export const listStaffPayouts = async (staffId: string) => {
  try {
    const filter = {
      staff_id: staffId,
    };
    const options = {
      filter,
      order: [{column: 'pay_date', order: 'desc'}],
    };
    const res: any = await listPayrollStaff(app, options);
    const payoutHistory = res.data.listPayrollStaff;

    return payoutHistory;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const countAllPayouts = async (staffId: string) => {
  try {
    const filterCriteria = {staff_id: staffId};
    const options = {filter: filterCriteria};
    const res: any = await countPayrollStaff(app, options);
    const count = res.data.countPayrollStaff;

    return count;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchStaffPayout = async (staffPayoutId: string) => {
  try {
    const res: any = await getPayrollStaff(app, staffPayoutId);
    const payout = res.data.getPayrollStaff;

    return payout;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};
