/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, CircularProgress} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';

export const SignatureBoard = ({
  sigPadRef,
  onConfirm,
  open,
  handleClear,
  loadingSignature,
}: any) => {
  const getSignBoxWidth = () => {
    let signBoxWidth = 300;
    const termBox = document.getElementById('termBox');

    if (termBox) {
      signBoxWidth = termBox.offsetWidth - 30;
    }

    return signBoxWidth;
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Please sign to confirm your submission</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            height: '300px',
            width: '100%',
            border: '1px solid',
            borderColor: 'secondary.main',
            overflow: 'hidden',
          }}
          id="termBox"
        >
          <SignatureCanvas
            ref={sigPadRef}
            canvasProps={{
              width: getSignBoxWidth(),
              height: 300,
              className: 'sigCanvas',
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {!loadingSignature && <Button onClick={handleClear}>Clear</Button>}
        <Button onClick={onConfirm}>
          {loadingSignature ? (
            <CircularProgress
              size="1rem"
              sx={{color: 'info.main', fontSize: '12px'}}
            />
          ) : (
            'Confirm Signature'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
