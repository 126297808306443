import {createTheme} from '@mui/material/styles';

export const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 767,
      lg: 1280,
      xl: 1500,
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    button: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      '@media (min-width:300px)': {
        fontSize: '0.8rem',
      },
      '@media (min-width:768px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1rem',
      },
      textTransform: 'capitalize',
    },
    h3: {
      textTransform: 'capitalize',
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '2rem',
      '@media (min-width:768px)': {
        fontSize: '2.2rem',
      },
    },
    h4: {
      textTransform: 'capitalize',
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '1.8rem',
    },
    h5: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      '@media (min-width:0px)': {
        fontSize: '1.3rem',
      },
      '@media (min-width:768px)': {
        fontSize: '1.5rem',
      },
    },
    body1: {
      fontFamily: 'Open Sans',
      fontWeight: 500,
      '@media (min-width:300px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
    },
    body2: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      '@media (min-width:600px)': {
        fontSize: '0.9rem',
      },
    },
  },

  palette: {
    mode: 'light',
    primary: {
      main: '#000000',
      light: '#FFF',
      dark: '#F7F7F7',
    },
    secondary: {
      light: '#efefef',
      main: '#dcdcdc',
      dark: '#AEAEAE',
    },
    warning: {
      light: '#ff9800',
      main: '#ed6c02',
      dark: '#e65100',
    },
    info: {
      light: '#03a9f4',
      main: '#3E82BF',
      dark: '#01579b',
    },
    error: {
      light: '#ef5350',
      main: '#d32f2f',
      dark: '#c62828',
    },
    success: {
      light: '#e4f7e6',
      main: '#2e7d32',
      dark: '#1b5e20',
    },
  },

  components: {
    MuiCssBaseline: {},
    // disable ripple
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: ({ownerState}) => ({
          borderRadius: 0,
          boxShadow: 'none',
          boxSizing: 'border-box',
          backgroundColor: '#dcdcdc',
          color: '#000000',
          height: '2.5rem',
          px: 0,
          '&:hover': {
            boxShadow: 'none',
            border: 0,
            backgroundColor: '#000000',
            color: '#ffffff',
          },

          ...(ownerState.variant === 'outlined' && {
            backgroundColor: '#ffffff',
            border: `solid ${ownerState.color || '#000000'} 1px`,
            '&:hover': {
              boxShadow: 'none',
              border: `solid ${ownerState.color || '#000000'} 1px`,
              backgroundColor: ownerState.color || '#ffffff',
              color: ownerState.color || '#000000',
            },
          }),

          ...(ownerState.variant === 'text' && {
            backgroundColor: 'transparent',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: 'transparent',
              fontWeight: 700,
              border: 0,
              color: ownerState.color || '#000000',
            },
          }),
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          py: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          px: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: '#dcdcdc',
          py: 0,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    // Tab background color
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: '#dcdcdc',
          boxSizing: 'border-box',
          minHeight: '2.5rem',
          maxHeight: '2.5rem',
          color: '#000000',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          '&.Mui-selected': {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          '& .MuiTabs-indicator': {
            height: 0,
          },
        },
      },
    },
    // delete bottom shadow of Active Tabs
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            height: 0,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        // disableUnderline: true,
        IconComponent: () => null, // remove ArrowDropDownIcon
      },
    },

    // set pagination select background white
    MuiTablePagination: {
      styleOverrides: {
        select: {
          '&.MuiSelect-select': {backgroundColor: 'white'},
        },
      },
    },
  },
});
