import {
  init,
  isSessionValid,
  forgotPassword,
  changePassword,
  signOut,
  getCurrentUser,
  getUserAttributes,
  signInWithEmailAndPassword,
  googleLogin,
  createUserWithEmailAndPassword,
  setTenantKey,
  confirmForgotPassword,
} from '@digistaff/app';
console.log('CLOUD_ENVIRONMENT', process.env.CLOUD_ENVIRONMENT);

const tenantKeyLocal = localStorage.getItem('tenantId');

const currentTenant = tenantKeyLocal ? tenantKeyLocal : 'default';

const app = init({
  ClientId: process.env.COGNITO_CLIENT_ID as string,
  environment: process.env.CLOUD_ENVIRONMENT as string,
  tenantKey: currentTenant as string,
});

export {
  app,
  init,
  isSessionValid,
  forgotPassword,
  changePassword,
  signOut,
  getCurrentUser,
  getUserAttributes,
  signInWithEmailAndPassword,
  googleLogin,
  createUserWithEmailAndPassword,
  setTenantKey,
  confirmForgotPassword,
};
