export const navBarGridContainer = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};

export const logoBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
};

export const logo = {
  height: '2.6rem',
  display: 'flex',
  cursor: 'pointer',
};

export const absolutePointer = {
  cursor: 'pointer',
  position: 'absolute',
  fontSize: '1.8rem',
  color: 'info.main',
};

export const rightBadge = {
  cursor: 'pointer',
  position: 'absolute',
  right: '20px',
  fontSize: '1.8rem',
  color: 'info.main',
};

export const navBarBox = {
  zIndex: 1251,
  backgroundColor: 'primary.light',
  boxShadow: 'none',
  borderBottom: '1px solid',
  borderColor: 'secondary.light',
};
