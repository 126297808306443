export const cardContainer = {
  px: 1,
  pt: 3,
  pb: '1px',
  backgroundColor: 'secondary.light',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: {xs: 'column', md: 'row'},
};

export const cardBox = {
  backgroundColor: 'secondary.main',
  my: 1,
  borderRadius: '10px',
  position: 'relative',
  boxShadow: 4,
  maxWidth: '400px',
};

export const leftPart = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
};

export const leftIcon = {
  fontSize: 140,
  color: 'secondary.light',
  position: 'absolute',
  left: '15px',
};

export const rightPart = {
  backgroundColor: 'primary.light',
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
  py: 2,
  position: 'relative',
};

export const editDelete = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 3,
  pr: 3,
};

export const emptyBox = {
  height: '60vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};
