/* eslint-disable @typescript-eslint/no-unused-vars */
import {Box} from '@mui/material';
import {BottomBar} from '../../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../../components/common/PageWrapper/PageWrapper';
import {bottomSubPageLimit} from '../../../ProfileViewStyles';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {EmployeeVacationView} from './EmployeeVacationView';
import {useState} from 'react';
import {ContractorVacationView} from './ContractorVacationView';

export const RequestVacationView = () => {
  const [staffProfile, setStaffProfile] = useState('employee'); //TODO fetch staff work status

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Vacation Request">
          {staffProfile === 'employee' && <EmployeeVacationView />}
          {staffProfile === 'ic' && <ContractorVacationView />}
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
