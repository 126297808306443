/* eslint-disable @typescript-eslint/no-explicit-any */
import {Wrapper} from '@googlemaps/react-wrapper';

const GoogleMapsProvider = ({children}: any) => {
  const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAP as string;

  return (
    <Wrapper apiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
      {children}
    </Wrapper>
  );
};

export default GoogleMapsProvider;
