import {Box, Divider} from '@mui/material';
import {WrapperProps} from '../../../types/types';
import {
  divider,
  largeCenterTitle,
  titleContainer,
} from '../../../views/Private/ProfileView/ProfileViewStyles';

export const PageWrapper = (props: WrapperProps) => {
  return (
    <Box sx={{width: '100vw', maxWidth: '100%'}}>
      <Box sx={titleContainer}>
        <Box sx={largeCenterTitle}>{props.title}</Box>
      </Box>
      <Divider sx={divider} />
      {props.children}
    </Box>
  );
};
