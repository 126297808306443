/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Divider,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {CustomTextLoad} from '../../../../components/common/CustomTextLoad/CustomTextLoad';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {getDigiStaffId, getVendorList} from '../../../../providers/api/profile';
import {
  bottomSubPageLimit,
  circularBox,
  titleContainer,
  userId,
} from '../ProfileViewStyles';
import {ReportBugDialog} from '../../../../components/common/ReportBugDialog/ReportBugDialog';

export const UserIDView = () => {
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(true);
  const staffId = localStorage.getItem('email');
  const [vendorList, setVendorList] = useState<any[]>([]);
  const [vendorLoading, setVendorLoading] = useState(true);
  const [bugDialog, setBugDialog] = useState(false);
  const tenantId = localStorage.getItem('tenantId');

  useEffect(() => {
    const loadDigiStaffID = () => {
      getDigiStaffId(staffId!)
        .then(res => setId(res))
        .catch(err => {
          console.log(err);
          console.log(err);
        })
        .finally(() => setLoading(false));
    };
    const loadVendorList = () => {
      if (tenantId === 'default') {
        setVendorLoading(false);
        return;
      }
      getVendorList(staffId!)
        .then((res: any) => setVendorList(res))
        .catch(err => {
          console.log(err);
          console.log(err);
        })
        .finally(() => setVendorLoading(false));
    };

    loadDigiStaffID();
    loadVendorList();
  }, [tenantId, staffId]);

  const handleBugDialog = () => setBugDialog(!bugDialog);

  const rows = vendorList.map(vendor => ({
    id: vendor.nestGetBusinessLocation.id,
    location: `${vendor.nestGetBusinessLocation.street}, ${vendor.nestGetBusinessLocation.city}, ${vendor.nestGetBusinessLocation.postal}`,
    pin: vendor.vendor_id,
  }));

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <Box sx={{width: '100vw', maxWidth: '100%'}}>
          <Box sx={titleContainer}>
            <Box
              sx={{
                fontSize: '30px',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Digistaff ID
            </Box>
            {loading ? (
              <Box sx={userId}>
                <CustomTextLoad height={30} width={250} />
              </Box>
            ) : (
              <Box sx={userId}>{id}</Box>
            )}
            <Button variant="outlined" onClick={handleBugDialog} sx={{my: 1}}>
              Report Bug
            </Button>
          </Box>
          <Divider sx={{width: '100%', pb: 1}} />
          <Box sx={{p: 1}}>
            {vendorLoading ? (
              <Box sx={circularBox}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {rows.length > 0 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            width: '60%',
                          }}
                        >
                          Location
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            width: '40%',
                          }}
                        >
                          Vendor ID
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            <Box sx={{fontWeight: 'bold', fontSize: '16px'}}>
                              {row.location}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{textTransform: 'uppercase'}}
                          >
                            {row.pin}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <BottomBar />
      <ReportBugDialog open={bugDialog} onClose={handleBugDialog} />
    </>
  );
};
