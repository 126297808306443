/* eslint-disable @typescript-eslint/no-explicit-any */
import {ArrowRight} from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {CustomTextLoad} from '../../../../../components/common/CustomTextLoad/CustomTextLoad';
import {EmptyPage} from '../../../../../components/common/EmptyPageLoader/EmptyPageLoader';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {
  countAllPayouts,
  listStaffPayouts,
} from '../../../../../providers/api/payout';
import {bottomSubPageLimit} from '../../ProfileViewStyles';
import {formatMoneyToFloat} from '@digistaff/common';

export const PayoutHistoryView = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const staffId = localStorage.getItem('email');
  const [paymentHistory, setPaymentHistory] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedHistory = paymentHistory.slice(startIndex, endIndex);

  useEffect(() => {
    const loadPayoutHistory = async () => {
      try {
        if (staffId) {
          const payoutRes = await listStaffPayouts(staffId);
          const countRes = await countAllPayouts(staffId);
          setPaymentHistory(payoutRes);
          setCount(countRes);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    loadPayoutHistory().catch(err => {
      console.log(err);
    });
  }, [staffId]);

  const handleChangePage = (event: any, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Payout History">
          {loading ? (
            <Box sx={{width: '100%'}}>
              <CustomTextLoad height={500} fullWidth />
            </Box>
          ) : (
            <>
              {paginatedHistory.length === 0 ? (
                <Box sx={{position: 'absolute', top: '-50px'}}>
                  <EmptyPage
                    msg="You don't have any payout history yet. Completed payout history will be
                  shown here."
                  />
                </Box>
              ) : (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Pay Date</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedHistory.map((row: any) => (
                        <TableRow
                          key={row.id}
                          onPointerUp={() => navigate(`/payments/${row.id}`)}
                          sx={{cursor: 'pointer'}}
                        >
                          <TableCell component="th" scope="row">
                            <Box>
                              {moment(row.pay_date).format('DD MMM YYYY')}
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{aligh: 'right', color: 'success.main'}}
                          >
                            $
                            {formatMoneyToFloat(
                              row.payroll.net_income,
                              row.payroll.currency
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              textTransform: 'capitalize',
                            }}
                          >
                            {row.status}
                          </TableCell>
                          <TableCell align="right" sx={{width: '5px', px: 0}}>
                            <Box sx={{pt: '5px'}}>
                              <ArrowRight />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
            </>
          )}
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
