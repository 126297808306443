import {Box, Button} from '@mui/material';
import moment from 'moment';
import {useState} from 'react';
import {CustomDialog} from '../../../../../components/common/Dialog/CustomDialog';
import {CustomMap} from '../../../../../components/common/GoogleMap/Map';
import {PopUp} from '../../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../../hooks/usePopUp';
import {mapBox} from '../../../JobDetailView/JobDetailViewStyles';
import {appointmentTitle, discardBtn} from '../../ProfileViewStyles';
import {ScheduledAppointmentProps} from '../../../../../types/types';

export const ScheduledAppointmentTab = ({
  latLng,
  address,
  booking,
  cxlBooking,
}: ScheduledAppointmentProps) => {
  const [dialog, setDialog] = useState(false);
  const [discard, setDiscard] = useState(false);
  const {popUp, closePopUp, handlePopUp, message, status} = usePopUp();

  const goToMaps = (address: string) => {
    const encodedAddress = encodeURI(address);
    const googleMapsLink = `https://maps.google.com/?q=${encodedAddress}`;
    window.location.href = googleMapsLink;
  };

  const handleDialog = () => setDialog(!dialog);

  const handleDiscard = () => setDiscard(!discard);

  const secondConfirm = () => {
    handlePopUp('success', 'Your booking has been cancelled');
    setTimeout(() => cxlBooking(), 700);
  };

  const closeAllModals = () => {
    setDialog(false);
    setDiscard(false);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, px: 1}}>
      <Box sx={mapBox}>
        <CustomMap latLng={latLng} />
      </Box>
      <Box
        sx={{border: '3px solid', borderColor: 'secondary.main', p: 1, mb: 2}}
      >
        <Box sx={{cursor: 'pointer'}} onPointerUp={() => goToMaps(address)}>
          <Box sx={appointmentTitle}>
            Address
            <Box
              component="span"
              sx={{fontSize: '12px', color: 'secondary.dark', ml: 2}}
            >
              Click here to open Google Maps
            </Box>
          </Box>
          <Box sx={{mt: 2}}>{address}</Box>
        </Box>
        <Box sx={{mt: 2}}>
          <Box sx={appointmentTitle}>Appointment&apos;s Details</Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, mt: 2}}>
            <Box sx={{textTransform: 'capitalize'}}>
              Appointment Type: {booking[0].nestGetAppointmentCategory?.name}
            </Box>
            <Box>
              Appointment Date:{' '}
              {moment(booking[0].appointment_date).format(
                'DD MMM YYYY, h:mm a'
              )}
            </Box>
            {booking[0].notes && (
              <Box>Appointment Reason: {booking[0].notes}</Box>
            )}
          </Box>
        </Box>
      </Box>
      <Button sx={discardBtn} variant="outlined" onPointerUp={handleDialog}>
        cancel appointment
      </Button>
      <Box sx={{height: '10px'}} />
      {/* First Dialog */}
      <CustomDialog
        open={dialog}
        close={handleDialog}
        title="Are you sure you want to cancel this appointment? "
        leftOnClick={handleDialog}
        rightOnClick={handleDiscard}
      />
      {/* Second Dialog */}
      <CustomDialog
        open={discard}
        close={handleDiscard}
        title="Once you cancel this appointment, we will send you a push notification and an SMS to confirm your cancellation"
        leftOnClick={closeAllModals}
        rightOnClick={secondConfirm}
      />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </Box>
  );
};
