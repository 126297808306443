/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {GoogleMap, MarkerF} from '@react-google-maps/api';
import {customMapStyle, mapStyles} from './mapStyle';

export const CustomMap = ({latLng}: any) => {
  return (
    <GoogleMap
      id="map"
      mapContainerStyle={mapStyles}
      zoom={11}
      center={latLng}
      options={{styles: customMapStyle}}
    >
      <MarkerF position={latLng} />
    </GoogleMap>
  );
};
