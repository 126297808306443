import {LoginView} from '../views/Public/LoginView/LoginView';
import {PasswordRecoveryView} from '../views/Public/PasswordRecoveryView/PasswordRecoveryView';
import {ToBeDevelopedView} from '../views/Private/ProfileView/ProfileSubPage/ToBeDevelopedView';
import {AvailabilityView} from '../views/Public/Registration/AvailabilityView';
import {BasicInfoView} from '../views/Public/Registration/BasicInfoView';
import {CertificationView} from '../views/Public/Registration/CertificationView';
import {EmergencyRegisterView} from '../views/Public/Registration/EmergencyRegisterView';
import {FinalSubmissionView} from '../views/Public/Registration/FinalSubmissionView';
import {PersonalInfoView} from '../views/Public/Registration/PersonalInfoView';
import {PreparationView} from '../views/Public/Registration/PreparationView';
import {PrivacyTermsView} from '../views/Public/Registration/PrivacyTermsView';
import {TermsView} from '../views/Public/Registration/TermsView';
import {UploadIdView} from '../views/Public/Registration/ID/UploadIdView';
import RouteErrorView from '../views/RouteErrorView/RouteErrorView';
import {PublicRoute} from './guards/PublicRoute';
import {LandingView} from '../views/Public/LandingView/LandingView';
import {AppInstructionView} from '../views/Public/Registration/AppInstructionView';
import {EnableNotificationView} from '../views/Public/EnableGPSView/EnableNotificationView';
import {GovernmentDocRegView} from '../views/Public/Registration/ID/GovernmentDocRegView';
import {SinDocRegView} from '../views/Public/Registration/ID/SinDocRegView';
import {WorkDocRegView} from '../views/Public/Registration/ID/WorkDocRegView';
import {EnableGPSView} from '../views/Public/EnableGPSView/EnableGPSView';
import {EnableGPSAndroidView} from '../views/Public/EnableGPSView/EnableGPSAndroidView';
import {RegRoute} from './guards/RegRoute';

export const publicRoutes = [
  {
    path: '/',
    element: (
      <PublicRoute>
        <LandingView />
      </PublicRoute>
    ),
    children: [],
    errorElement: (
      <PublicRoute>
        <RouteErrorView />
      </PublicRoute>
    ),
  },
  {
    path: '/login',
    element: (
      <PublicRoute>
        <LoginView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/password-recovery',
    element: (
      <PublicRoute>
        <PasswordRecoveryView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/tbc',
    element: (
      <PublicRoute>
        <ToBeDevelopedView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/enable-notification',
    element: (
      <PublicRoute>
        <EnableNotificationView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/terms',
    element: (
      <PublicRoute>
        <TermsView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/privacy',
    element: (
      <PublicRoute>
        <PrivacyTermsView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/preparation',
    element: (
      <PublicRoute>
        <PreparationView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/info/account',
    element: (
      <PublicRoute>
        <BasicInfoView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/info/personal',
    element: (
      <PublicRoute>
        <PersonalInfoView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/info/availability',
    element: (
      <PublicRoute>
        <RegRoute>
          <AvailabilityView />
        </RegRoute>
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/info/id',
    element: (
      <PublicRoute>
        <RegRoute>
          <UploadIdView />
        </RegRoute>
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/info/cert',
    element: (
      <PublicRoute>
        <CertificationView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/info/emergency',
    element: (
      <PublicRoute>
        <EmergencyRegisterView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/review',
    element: (
      <PublicRoute>
        <FinalSubmissionView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/registered',
    element: (
      <PublicRoute>
        <AppInstructionView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/registration/upload/gov',
    element: (
      <PublicRoute>
        <RegRoute>
          <GovernmentDocRegView />
        </RegRoute>
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/registration/upload/sin',
    element: (
      <PublicRoute>
        <RegRoute>
          <SinDocRegView />
        </RegRoute>
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/registration/upload/work',
    element: (
      <PublicRoute>
        <RegRoute>
          <WorkDocRegView />
        </RegRoute>
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/location/ios',
    element: (
      <PublicRoute>
        <EnableGPSView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/location/android',
    element: (
      <PublicRoute>
        <EnableGPSAndroidView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
];
