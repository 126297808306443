import {Box, Button} from '@mui/material';
import {Confetti} from '../../../components/common/Animation/Confetti';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {continueBtn, userScrollLimit} from '../LandingView/LandingViewStyles';
import {getCurrentUser, signInWithEmailAndPassword} from '@digistaff/app';
import {app} from '../../../providers/Accounts';

import {useNavigate} from 'react-router-dom';

export const AppInstructionView = () => {
  const staffId = sessionStorage.getItem('email');
  const pw = sessionStorage.getItem('pw');
  const navigate = useNavigate();

  const toJobFeed = async () => {
    try {
      await getCurrentUser(app);
      navigate('/agency/select');
    } catch (err) {
      // Prevent staff login status lost and login user again
      if (staffId && pw) {
        signInWithEmailAndPassword(app, staffId, pw)
          .then(() => {
            localStorage.setItem('email', staffId);
            sessionStorage.removeItem('pw');
            navigate('/agency/select');
          })
          .catch(err => {
            console.log(err);
            console.log(err);
          });
      } else {
        navigate('/');
      }
    }
  };

  return (
    <>
      <Navbar />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Registration Completed">
          <>{Confetti(150)}</>
          <Box sx={{width: '100%', px: 2}}>
            <Box
              sx={{
                textAlign: 'center',
                height: {xs: '50vh', sm: '70vh'},
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 3,
                fontSize: {xs: '14px', sm: '21px'},
              }}
            >
              Congrats! Your information has been submitted for review.
              <Box sx={{width: '80%'}}>
                <Button
                  id="next-btn"
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={toJobFeed}
                >
                  next
                </Button>
              </Box>
            </Box>
          </Box>
        </PageWrapper>
      </Box>
    </>
  );
};
