/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {Info} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Snackbar,
  TextField,
} from '@mui/material';
import moment, {Moment} from 'moment';
import {useEffect, useState} from 'react';
import {AddressAutoComplete} from '../../../../components/common/AddressAutoComplete/AddressAutoComplete';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {CustomDatePicker} from '../../../../components/common/CustomDatePicker/CustomDatePicker';
import {CustomTextLoad} from '../../../../components/common/CustomTextLoad/CustomTextLoad';
import {CustomDialog} from '../../../../components/common/Dialog/CustomDialog';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {PopUp} from '../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../hooks/usePopUp';
import {
  fetchUserProfileFull,
  genderMappingFromGQL,
  updateStaffProfileWithPayload,
} from '../../../../providers/api/profile';
import {continueBtn} from '../../../Public/LandingView/LandingViewStyles';
import {genderList} from '../../../Public/Registration/PersonalInfoView';
import {
  bottomSubPageLimit,
  contentBgLayout,
  textFieldEmer,
  gridTextBox,
  discardBtn,
  saveBtn,
  textFieldAltCapitalized,
} from '../ProfileViewStyles';

export const UserProfile = () => {
  const [edit, setEdit] = useState(false);
  const [dialog, setDialog] = useState(false);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [snackBar, setSnackBar] = useState(false);
  const [info, setInfo] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    phone: '',
    address: '',
    email: '',
    birth: '',
    gender: '',
    sin: '',
    workStatus: '',
  });
  const [location, setLocation] = useState<any>({});
  const [calendar, setCalendar] = useState<Moment | null>(null);
  const [loading, setLoading] = useState(true);
  const staffId = localStorage.getItem('email');

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    const type = {operator: 'like', value: '%mandatory_work%'};
    fetchUserProfileFull(staffId!, type)
      .then((res: any) => {
        setInfo(res);
        setCalendar(moment(res.birthday, 'DD MMM YYYY'));
      })
      .catch(err => {
        console.log(err);
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleCalChange = (newValue: Moment | null) => setCalendar(newValue);

  const handleDiscard = () => {
    setLocation(location);
    setDialog(false);
    setEdit(false);
  };

  const handleSave = () => {
    const payload = {
      street: location.street,
      city: location.city,
      province: location.province,
      postal: location.postal,
      country: location.country,
      latitude: location.latitude,
      longitude: location.longitude,
    };

    if (Object.values(payload).every(item => item === undefined)) {
      handlePopUp('error', 'Please provide a valid address');
    } else {
      updateStaffProfileWithPayload(staffId!, payload)
        .then(() => {
          handlePopUp('success', 'Your information was successfully updated!');
          setDialog(false);
          setEdit(false);
          localStorage.removeItem('lastFetch');
        })
        .catch(err => {
          console.log(err);
          console.log(err);
          handlePopUp('error', 'Unable to update your information');
        });
    }
  };

  const handleDialog = () => setDialog(!dialog);
  const handleSnackBar = () => setSnackBar(!snackBar);

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Personal Information">
          <Box sx={{py: 1}} />
          <Box sx={contentBgLayout}>
            {loading ? (
              <Box sx={{px: 1, mt: 1}}>
                {Array.from(new Array(10)).map((_, index) => (
                  <CustomTextLoad height={54} mb={14} key={index} />
                ))}
              </Box>
            ) : (
              <Grid container>
                <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                  <TextField
                    label="First Name"
                    sx={textFieldEmer}
                    value={info.firstName}
                    disabled
                    onChange={e =>
                      setInfo({...info, firstName: e.target.value})
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                  <TextField
                    label="Middle Name(Optional)"
                    sx={textFieldEmer}
                    value={info.middleName}
                    disabled
                    onChange={e =>
                      setInfo({...info, middleName: e.target.value})
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                  <TextField
                    label="Last Name"
                    sx={textFieldEmer}
                    value={info.lastName}
                    disabled
                    onChange={e => setInfo({...info, lastName: e.target.value})}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                  <TextField
                    label="Phone"
                    sx={textFieldEmer}
                    value={info.phone}
                    onChange={e => setInfo({...info, phone: e.target.value})}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                  <TextField
                    label="Email"
                    sx={textFieldEmer}
                    value={info.email}
                    onChange={e => setInfo({...info, email: e.target.value})}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                  <AddressAutoComplete
                    initialValue={info.address}
                    onChange={address => {
                      setLocation(address);
                    }}
                    disabled={edit ? false : true}
                    style={textFieldEmer}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                  <CustomDatePicker
                    value={calendar}
                    handleChange={handleCalChange}
                    label="Birthday"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                  <TextField
                    label="Gender"
                    sx={textFieldEmer}
                    value={genderMappingFromGQL[info.gender]}
                    disabled
                    select
                  >
                    {genderList.map(option => (
                      <MenuItem
                        key={option}
                        value={option}
                        onPointerUp={() => setInfo({...info, gender: option})}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                  <TextField
                    label="Work Status"
                    sx={textFieldAltCapitalized}
                    value={info.workStatus}
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onPointerUp={handleSnackBar}>
                            <Info />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                  {edit ? (
                    <Button
                      variant="outlined"
                      sx={discardBtn}
                      onPointerUp={handleDialog}
                    >
                      Discard
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={continueBtn}
                      onPointerUp={() => setEdit(prev => !prev)}
                    >
                      Edit
                    </Button>
                  )}
                </Grid>
                {edit && (
                  <Grid item xs={12} md={6} lg={4} sx={gridTextBox}>
                    <Button
                      variant="outlined"
                      sx={saveBtn}
                      onPointerUp={handleSave}
                    >
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
        duration={2000}
      />
      <CustomDialog
        open={dialog}
        close={handleDialog}
        title="Are you sure you want to discard all changes?"
        leftOnClick={handleDialog}
        rightOnClick={handleDiscard}
        customLeftText="Go Back"
        customRightText="Discard"
        customRightBtn={discardBtn}
      />
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        open={snackBar}
        autoHideDuration={6000}
        onClose={handleSnackBar}
        message="Note archived"
        sx={{width: '100%'}}
      >
        <Alert severity="info" sx={{fontSize: '12px'}}>
          You can update your work status in Me {'>'} Documents!
        </Alert>
      </Snackbar>
    </>
  );
};
