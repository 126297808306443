/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ArrowLeft,
  AccessTime,
  ArrowCircleUp,
  Cancel,
  CheckCircle,
} from '@mui/icons-material';
import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {
  DocumentStatus,
  fetchStaffDocumentTenant,
  govDocTypeFromGQL,
  govStatusList,
  sinDocTypeFromGQL,
  sinStatusList,
  workStatusList,
  workStatusMappingFromGQL,
} from '../../../../../providers/api/documents';
import {
  requireArrow,
  requireBox,
  requireContainer,
  statusTitle,
} from '../../../../Public/Registration/RegistrationStyles';
import {
  bottomSubPageLimit,
  docuDetailsBox,
  expiryBox,
  firstLiner,
  flexAlign,
  qualiInstruct,
  uploadDocumentCardBox,
  uploadDocumentCardContainer,
} from '../../ProfileViewStyles';
import {checkExpiry} from './DocumentsView';

export const UploadIdentities = () => {
  const [workStatus, setWorkStatus] = useState('');
  const {state} = useLocation();
  const [govDoc, setGovDoc] = useState<any>({});
  const [sinDoc, setSinDoc] = useState<any>({});
  const [workDoc, setWorkDoc] = useState<any>({});
  const [certStatus, setCertStatus] = useState<Map<string, JSX.Element>>(
    new Map()
  );
  const navigate = useNavigate();
  const [docStatus, setDocStatus] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    const handleDocStatus = (documents: any) => {
      documents.map((doc: any) => {
        if (govStatusList.includes(doc.type)) {
          setGovDoc(doc);
        }

        if (sinStatusList.includes(doc.type)) {
          setSinDoc(doc);
        }

        if (workStatusList.includes(doc.type)) {
          setWorkDoc(doc);
          setWorkStatus(workStatusMappingFromGQL[doc.type]);
        }
      });
    };
    handleDocStatus(state);
  }, [state]);

  useEffect(() => {
    const checkCertStatus = async (cert: any) => {
      const tenantId = localStorage.getItem('tenantId');
      if (tenantId === 'default') {
        updateCertStatus(
          cert.id,
          <ArrowCircleUp sx={{color: 'primary.light'}} />
        );
        setDocStatus(prevStatus =>
          new Map(prevStatus).set(cert.id, 'uploaded')
        );
      } else {
        try {
          const res = await fetchStaffDocumentTenant(cert.id);
          let icon;
          if (res.status) {
            if (res.status === DocumentStatus.rejected) {
              icon = <Cancel sx={{color: 'error.main'}} />;
            } else if (res.status === DocumentStatus.approved) {
              if (checkExpiry(cert.expiration) < 30) {
                icon = <AccessTime sx={{color: 'warning.dark'}} />;
              } else {
                icon = <CheckCircle sx={{color: 'success.main'}} />;
              }
            } else if (res.status === DocumentStatus.pending) {
              icon = <ArrowCircleUp sx={{color: 'warning.light'}} />;
            } else if (res.status === DocumentStatus.resubmit) {
              icon = <Cancel sx={{color: 'warning.light'}} />;
            } else {
              icon = <AccessTime />;
            }

            updateCertStatus(cert.id, icon);
            setDocStatus(prevStatus =>
              new Map(prevStatus).set(cert.id, res.status)
            );
          } else {
            icon = <AccessTime />;
            updateCertStatus(cert.id, icon);
            setDocStatus(prevStatus =>
              new Map(prevStatus).set(cert.id, 'unknown')
            );
          }
        } catch (err) {
          console.log(err);
          console.log(err);
        }
      }
    };

    if (govDoc.id) {
      checkCertStatus(govDoc).catch(err => {
        console.log(err);
        console.log(err);
      });
    }
    if (sinDoc.id) {
      checkCertStatus(sinDoc).catch(err => {
        console.log(err);
        console.log(err);
      });
    }
    if (workDoc.id) {
      checkCertStatus(workDoc).catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  }, [govDoc, sinDoc, workDoc]);

  const updateCertStatus = (certId: string, statusIcon: JSX.Element) => {
    setCertStatus(prevStatus => new Map(prevStatus).set(certId, statusIcon));
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Upload Documents">
          <Box sx={{py: '4px'}} />
          <Box sx={qualiInstruct}>
            You will need to prove your identity and eligibility to work before
            starting your insurable employment. Please upload the required
            documents below.
          </Box>
          <Box sx={uploadDocumentCardContainer}>
            <Box
              sx={uploadDocumentCardBox}
              onClick={() =>
                navigate('/document/government', {state: {param1: govDoc.id}})
              }
            >
              <Box sx={flexAlign}>
                <>{certStatus.get(govDoc.id)}</>
                <Box sx={statusTitle}>Government ID</Box>
                <Box sx={requireContainer}>
                  <ArrowLeft sx={requireArrow} />
                  <Box sx={requireBox}>Required</Box>
                </Box>
              </Box>
              <Box sx={docuDetailsBox}>
                <Box sx={firstLiner}>
                  Type: {govDocTypeFromGQL[govDoc.type]}
                </Box>
                <Box sx={expiryBox}>status: {docStatus.get(govDoc.id)}</Box>
              </Box>
            </Box>
            <Box
              sx={uploadDocumentCardBox}
              onClick={() =>
                navigate('/document/sin', {state: {param1: sinDoc.id}})
              }
            >
              <Box sx={flexAlign}>
                <>{certStatus.get(sinDoc.id)}</>
                <Box sx={statusTitle}>Proof of SIN</Box>
                <Box sx={requireContainer}>
                  <ArrowLeft sx={requireArrow} />
                  <Box sx={requireBox}>Required</Box>
                </Box>
              </Box>
              <Box sx={docuDetailsBox}>
                <Box sx={firstLiner}>
                  Type: {sinDocTypeFromGQL[sinDoc.type]}
                </Box>
                <Box sx={expiryBox}>status: {docStatus.get(sinDoc.id)}</Box>
              </Box>
            </Box>
            <Box
              sx={uploadDocumentCardBox}
              onClick={() =>
                navigate('/document/work', {
                  state: {param1: workDoc.id, param2: workStatus},
                })
              }
            >
              <Box sx={flexAlign}>
                <>{certStatus.get(workDoc.id)}</>
                <Box sx={statusTitle}>Eligibility to Work</Box>
                <Box sx={requireContainer}>
                  <ArrowLeft sx={requireArrow} />
                  <Box sx={requireBox}>Required</Box>
                </Box>
              </Box>
              <Box sx={docuDetailsBox}>
                <Box sx={firstLiner}>type: {workStatus}</Box>
                <Box sx={expiryBox}>status: {docStatus.get(workDoc.id)}</Box>
              </Box>
            </Box>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
