/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, CircularProgress, Grid, Typography} from '@mui/material';
import {
  applyButton,
  boardContainer,
  businessLocation,
  cardContainer,
  detailButton,
  infoJobTitle,
  jobDesc,
  jobDescBox,
  jobDescIcon,
  jobInfoBox,
  jobNatureBox,
  jobStatusBox,
} from './JobViewStyles';
import {LocalAtm, AccessTimeFilled, BarChart, Star} from '@mui/icons-material';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';
import {useEffect, useState} from 'react';
import {
  applyNewShift,
  fetchJobApplicationStatus,
} from '../../../providers/api/shift';
import {inviteStaffToBusiness} from '@digistaff/business';
import {fetchStaffProfileRaw} from '../../../providers/api/profile';
import {app} from '../../../providers/Accounts';
import {TrainingChecker} from '../../../components/common/TrainingChecker/TrainingChecker';
import {JobConfirmationDialog} from '../../../components/common/JobConfirmationDialog/JobConfirmationDialog';
import {StaffCustomAttributeForm} from '../../../components/common/StaffCustomAttributeForm/StaffCustomAttributeForm';
import {
  checkTenantVerificationStatus,
  checkTenantVerificationStatusInDigiJobs,
  passedAllRequiredAgencyTrainings,
  passedAllRequiredAgencyTrainingsInDigiJobs,
  removeOldJobPosts,
  resubmitApplicationToTenant,
  signedAllContracts,
  signedAllContractsInDigiJobs,
} from './JobHelper';
import {ShiftStatus} from '@digistaff/shift';
import {getStaffProfileCrossTenant} from '@digistaff/staff';
import {ContractChecker} from '../../../components/common/ContractChecker/ContractTracker';
import {SwitchProfileDialog} from '../../../components/common/SwitchProfileDialog/SwitchProfileDialog';

import {
  checkRequiredAgencyCerts,
  checkRequiredAgencyCertsInDigiJobs,
} from './CheckCertHelper';
import {CertChecker} from '../../../components/common/CertChecker/CertChecker';
import {checkJobInstance} from '../../../providers/api/shiftPosting';
import {useCurrentUser} from '../../../hooks/useCurrentUser';
import {removeOldSchedule} from '../ScheduleView/ScheduleHelpers';

import {checkRequiredStaffCustomAttributes} from '../../../providers/helpers/checkers';

export const InfoCard = ({job, handleRefresh, isDocsExpired}: any) => {
  const navigate = useNavigate();
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const staffId: string = localStorage.getItem('email')!;
  const [submitting, setSubmitting] = useState(false);
  const [applyStatus, setApplyStatus] = useState(job.status);
  const tenantId = localStorage.getItem('tenantId');
  const [checker, setChecker] = useState(false);
  const [contractChecker, setContractChecker] = useState(false);
  const [requiredTrainings, setRequiredTrainings] = useState<any>([]);
  const [requiredContracts, setRequiredContracts] = useState<any>([]);
  const [requiredCerts, setRequiredCerts] = useState<any>([]);
  const [hasConfJob, setHasConfJob] = useState(false);
  const [switchProfileDialog, setSwitchProfileDialog] = useState(false);
  const [certChecker, setCertChecker] = useState(false);
  const {userValid, sessionInvalidRedirection, inAppErrMsg} = useCurrentUser();

  const [requiredStaffCustomAttributes, setRequiredStaffCustomAttributes] =
    useState<any>([]);

  useEffect(() => {
    if (job.status === ShiftStatus.default) {
      setApplyStatus('default');
    }
  }, [job.status]);

  const goToJobDetails = () =>
    navigate(`/jobs/${job.id}`, {
      state: {
        jobTenantId: job.tenant_id,
        required_agency_trainings: job.required_agency_trainings,
        required_certifications: job.required_certifications,
      },
    });

  const handleChecker = () => setChecker(!checker);
  const handleContractChecker = () => setContractChecker(!contractChecker);
  const handleCertChecker = () => setCertChecker(!certChecker);
  const handleProfileDialog = () =>
    setSwitchProfileDialog(!switchProfileDialog);

  const handleHasConfJob = () => setHasConfJob(!hasConfJob);

  const applyJob = () => {
    applyNewShift(staffId!, job.id, job.startDate)
      .then((res: any) => {
        if (res.data.createShift.id === null) {
          setHasConfJob(true);
        } else {
          handlePopUp('success', 'You have successfully applied!');
          setApplyStatus('applied');
          removeOldSchedule();
          removeOldJobPosts();
        }
      })
      .catch(err => {
        console.log(err);
        console.log(err);
        handlePopUp('error', 'Unable to apply');
      })
      .finally(() => setSubmitting(false));
  };

  const applyTenantJob = async () => {
    setSubmitting(true);
    try {
      const profile_tenants = await checkTenantVerificationStatusInDigiJobs(
        job.tenant_id
      )(staffId!);

      if (
        profile_tenants &&
        profile_tenants?.data?.listStaffProfileTenants?.length === 0
      ) {
        const profile: any = await fetchStaffProfileRaw(staffId!);
        const tenantArray = profile?.tenants === null ? [] : profile.tenants;
        const payload = {email: staffId, phone: '', tenants: tenantArray};

        await inviteStaffToBusiness(app, payload, true, job.tenant_id);
        setSwitchProfileDialog(true);
        return;
      }

      const verification_status =
        profile_tenants.data.listStaffProfileTenants[0].verification_status;

      if (
        verification_status === 'approved' ||
        verification_status === 'pending'
      ) {
        setSwitchProfileDialog(true);
      } else {
        await resubmitApplicationToTenant(job.tenant_id, staffId!);
        setSwitchProfileDialog(true);
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const reloadJobs = () => {
    handlePopUp(
      'error',
      'This job is no longer accepting applications. We will now refresh the Job Feed and show you the latest available jobs'
    );
    setTimeout(() => {
      handleRefresh();
    }, 700);
  };

  const simpleApply = async () => {
    setSubmitting(true);
    try {
      const jobInstance = await checkJobInstance(job.id);

      if (jobInstance.status === 'deleted') {
        reloadJobs();
        return;
      }

      const profile_tenants: any = await checkTenantVerificationStatus(
        staffId!
      );
      const verification_status =
        profile_tenants.data?.listStaffProfileTenants[0]?.verification_status;

      if (verification_status === 'rejected') {
        await resubmitApplicationToTenant(job.tenant_id, staffId!);
      }

      const appliedJob = await fetchJobApplicationStatus(staffId!, job.id);

      if (applicationInProgress.includes(appliedJob[0]?.status)) {
        handlePopUp('info', 'Your application is under review');
      } else {
        applyJob();
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const applyOrSendInvitation = async (
    isTrainingAllPass: boolean,
    allTrainings: any,
    staffCustomAttributes: any,
    staffProfileTenant: any
  ) => {
    if (isTrainingAllPass && staffCustomAttributes.isPass) {
      if (tenantId === job.tenant_id) {
        await simpleApply();
      } else {
        await applyTenantJob();
      }
    } else {
      if (!isTrainingAllPass) {
        setRequiredTrainings(allTrainings);
        setChecker(true);
      }

      if (
        !staffCustomAttributes.isPass &&
        staffProfileTenant?.data?.getStaffProfileTenant?.id
      )
        setRequiredStaffCustomAttributes(staffCustomAttributes.required);

      setSubmitting(false);
    }
  };

  const jobApplicationCheck = async () => {
    setSubmitting(true);

    try {
      const userStatus = await userValid();
      if (!userStatus) {
        handlePopUp('error', inAppErrMsg);
        sessionInvalidRedirection();
        return;
      }

      if (isDocsExpired) {
        handlePopUp(
          'error',
          'Unable to apply. Please update your expired documents.'
        );
        setSubmitting(false);
        return;
      }

      const certifications: any =
        tenantId === 'default'
          ? await checkRequiredAgencyCertsInDigiJobs(job.tenant_id)(
              staffId!,
              job.required_certifications
            )
          : await checkRequiredAgencyCerts(
              staffId!,
              job.required_certifications
            );
      if (certifications?.hasAllCerts) {
        const contracts: any =
          tenantId === 'default'
            ? await signedAllContractsInDigiJobs(job.tenant_id)
            : await signedAllContracts();

        if (contracts?.allSigned) {
          const trainings: any =
            tenantId === 'default'
              ? await passedAllRequiredAgencyTrainingsInDigiJobs(
                  job.required_agency_trainings,
                  job.tenant_id
                )
              : await passedAllRequiredAgencyTrainings(
                  job.required_agency_trainings
                );

          // check for the staff custom attributes
          const staffProfileTenant = await getStaffProfileCrossTenant(
            app,
            staffId,
            job.tenant_id
          );

          const staffCustomAttributes = checkRequiredStaffCustomAttributes(
            staffProfileTenant?.data?.getStaffProfileTenant,
            job.required_staff_custom_attributes || []
          );

          await applyOrSendInvitation(
            trainings?.isAllPass,
            trainings?.allTrainings,
            staffCustomAttributes,
            staffProfileTenant
          );
        } else {
          setRequiredContracts(
            tenantId === 'default'
              ? contracts?.contractListWithTenant
              : contracts?.contractsWithStaffSignatureStatus
          );
          setContractChecker(true);
          setSubmitting(false);
        }
      } else {
        setSubmitting(false);
        setCertChecker(true);
        setRequiredCerts(certifications?.certArray);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box sx={boardContainer}>
      <Box sx={cardContainer}>
        <Grid container>
          <Grid item xs={10} sx={{cursor: 'pointer'}} onClick={goToJobDetails}>
            <Typography variant="h6" sx={infoJobTitle}>
              {job.jobTitle}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body1" sx={{textTransform: 'capitalize'}}>
          {job.business}
        </Typography>
        <Typography variant="body1" sx={businessLocation}>
          {applyStatus === ShiftStatus.confirmed
            ? `${job.street}, ${job.city}`
            : job.city}
        </Typography>
        <Box sx={jobDesc} onClick={goToJobDetails}>
          {job.salary && (
            <Box sx={jobDescBox}>
              <LocalAtm sx={jobDescIcon} />
              {`$${job.salary} / hour`}
            </Box>
          )}
          {job.rating && (
            <Box sx={jobDescBox}>
              <Box sx={{pl: '5px'}}>{job.rating}</Box>
              <Star sx={{pl: '1px', fontSize: '0.8rem'}} />
            </Box>
          )}
          {job.digiScore && (
            <Box sx={jobNatureBox}>
              <BarChart sx={jobDescIcon} />
              {`${job.digiScore}+`}
            </Box>
          )}
          <Box sx={jobDescBox}>
            {moment(job.startDate).format('DD MMM YYYY')}
          </Box>
          <Box sx={jobDescBox}>
            <AccessTimeFilled sx={jobDescIcon} />
            Starts: {moment(job.startTime, 'h:mmA').format('LT')} (
            {Math.floor(job.duration / 3600000).toFixed(1)} Hours)
          </Box>
        </Box>
        {job.jobDesc && (
          <Box sx={jobInfoBox} onClick={goToJobDetails}>
            {job.jobDesc[0]}
          </Box>
        )}
        <Button
          variant="outlined"
          sx={detailButton}
          onPointerUp={goToJobDetails}
        >
          Details
        </Button>
        {applyStatus === 'default' ? (
          <Button
            variant="outlined"
            sx={applyButton}
            onPointerUp={jobApplicationCheck}
            disabled={submitting}
          >
            {submitting ? (
              <CircularProgress
                size="1.3rem"
                sx={{color: 'white', fontSize: '12px'}}
              />
            ) : (
              'apply'
            )}
          </Button>
        ) : (
          <Box sx={jobStatusBox}>{applyStatus}</Box>
        )}
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        duration={5000}
        status={status}
        message={message}
      />
      <TrainingChecker
        open={checker}
        onClose={handleChecker}
        requiredTrainings={requiredTrainings}
      />
      <JobConfirmationDialog
        open={hasConfJob}
        onClose={handleHasConfJob}
        jobDate={moment(job.startDate).format('DD MMM YYYY')}
      />
      <ContractChecker
        open={contractChecker}
        onClose={handleContractChecker}
        requiredContracts={requiredContracts}
      />
      <SwitchProfileDialog
        open={switchProfileDialog}
        onClose={handleProfileDialog}
      />
      <CertChecker
        open={certChecker}
        onClose={handleCertChecker}
        requiredCerts={requiredCerts}
      />
      <StaffCustomAttributeForm
        requiredStaffCustomAttributes={requiredStaffCustomAttributes}
        shift={job}
        staffId={staffId}
        onClose={() => {}}
      />
    </Box>
  );
};

const applicationInProgress = [ShiftStatus.applied, ShiftStatus.backup];
