/* eslint-disable @typescript-eslint/no-explicit-any */
import {useLocation, useNavigate} from 'react-router-dom';
import {useCurrentTabTitle} from '../../hooks/useCurrentTabTitle';
import {app, getCurrentUser, setTenantKey} from '../../providers/Accounts';
import {Bridge} from '../../Bridge';
import {Children, cloneElement, useEffect, useRef, useState} from 'react';
import {PopUp} from '../../components/common/PopUp/PopUp';
import {usePopUp} from '../../hooks/usePopUp';
import {handleCustomError} from '../../views/RouteErrorView/RouteErrorView';
import {createStaffActivityPulse} from '@digistaff/staff';
import {fetchStaffProfileTenantLite} from '../../providers/api/profile';

export const PrivateRoute = ({children}: any) => {
  const [userLocationStatus, setUserLocationStatus] = useState('');
  const [userNotificationStatus, setUserNotificationStatus] = useState('');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const path = useLocation().pathname;
  const prevPathRef = useRef(path);
  const secondsToCheck = 600;
  const navigate = useNavigate();
  document.title = useCurrentTabTitle();
  const currentTenant = localStorage.getItem('tenantId');
  const [tenantProfile, setTenantProfile] = useState<any>(null);

  const checkLocationServices = async () => {
    try {
      const locationStatus = await Bridge.getLocationAuthorizationStatus();
      setUserLocationStatus(locationStatus);
      const hasRedirected = localStorage.getItem('hasRedirected');

      if (userLocationStatus === 'AUTHORIZED_ALWAYS') {
        return Promise.resolve();
      } else {
        if (!hasRedirected) {
          if (!window.blink) {
            navigate('/location/ios');
          } else {
            navigate('/location/android');
          }
        }

        return Promise.reject();
      }
    } catch (error) {
      console.error('checkLocationServices', error);
      return Promise.reject(error);
    }
  };

  const checkPushNotification = async () => {
    try {
      const notificationStatus =
        await Bridge.getPushNotificationAuthorizationStatus();
      setUserNotificationStatus(notificationStatus);

      if (
        userNotificationStatus === 'NOT_DETERMINED' ||
        userNotificationStatus === ''
      ) {
        return await Bridge.requestPushNotificationAuthorization();
      } else if (userNotificationStatus === 'AUTHORIZED') {
        return Promise.resolve();
      } else {
        return Promise.resolve();
        // TODO: Update after official launch
        // navigate('/enable-notification');
        // return Promise.reject('notificationStatus reject');
      }
    } catch (error) {
      console.error('checkPushNotification', error);
      return Promise.reject(error);
    }
  };

  const initiateDeviceBridge = async () => {
    try {
      const pushData = await Bridge.getPushPayload();
      console.log('pushData', pushData);
      await checkPushNotification();
      await checkLocationServices();
    } catch (err) {
      return Promise.resolve();
    }
  };

  const fetchUser = async () => {
    try {
      // Reset staff ID in localStorage to prevent safari localStorage 7day inactivation clearance
      const staffId = localStorage.getItem('email');
      if (!staffId) {
        const currentUser: any = await getCurrentUser(app);
        localStorage.setItem('email', currentUser?.user?.Username);
      }
      await getCurrentUser(app);
    } catch (error) {
      handlePopUp(
        'error',
        'User credential has expired. Please login again. We will now redirect you to the login page.'
      );
      setTimeout(() => {
        window.location.href = `${window.location.origin}/`;
      }, 1000);
    }
  };

  const recordUserActivity = async () => {
    if (currentTenant !== 'default') {
      setTenantKey(app, 'default');
    }
    try {
      const storedActiveTime = localStorage.getItem('activeTime');
      const currentTime = new Date();
      const userActiveTime = storedActiveTime
        ? new Date(storedActiveTime)
        : currentTime;
      let staffId = localStorage.getItem('email');

      if (!staffId) {
        const currentUser: any = await getCurrentUser(app);
        staffId = currentUser?.user?.Username;
      }

      const timeDifference =
        (currentTime.getTime() - userActiveTime.getTime()) / 1000;

      if (!storedActiveTime || timeDifference >= secondsToCheck) {
        localStorage.setItem('activeTime', currentTime.toISOString());
        await createStaffActivityPulse(app, {
          staff_id: staffId,
          status: 'active',
        });
      }
    } catch (error) {
      handleCustomError(error);
    } finally {
      if (currentTenant !== 'default') {
        setTenantKey(app, currentTenant!);
      }
    }
  };

  const checkStaffSuspendStatus = async () => {
    try {
      if (currentTenant === 'default') {
        return;
      }

      const res = await fetchStaffProfileTenantLite(currentTenant!);
      setTenantProfile(res);

      if (res?.is_suspended) {
        handlePopUp(
          'error',
          'Your account on this profile has been suspended by the agency admin. For further details, please get in touch with the agency. You will now be redirected to the profile switch page.'
        );
        setTimeout(() => {
          navigate('/agency/select');
        }, 1000);
      }
    } catch (error) {
      // Resolve as error is due to user's tenantId is missing in localStorage.
      // The JobView page will redirect user to agency select page to set tenantId again
      return Promise.resolve();
    }
  };

  useEffect(() => {
    initiateDeviceBridge().catch(err => {
      console.log(err);
    });
    // Only perform these functions when user switch page
    if (prevPathRef?.current !== path) {
      // Detect user activity
      recordUserActivity().catch(err => {
        console.log(err);
      });

      prevPathRef.current = path;
      // Detect user login status, will logout user if failed to obtain user status
      fetchUser().catch(err => {
        console.log(err);
      });
      // Detect user suspension status, will redirect user to agency select page if being suspended
      checkStaffSuspendStatus().catch(err => {
        console.log(err);
      });
    }
  });

  return (
    <>
      {
        // pass prop to children
        Children.map(children, child => cloneElement(child, {tenantProfile}))
      }
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
    </>
  );
};
