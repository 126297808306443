export const jobType = {
  name: 'jobType',
  label: 'Job Type',
  array: [
    {id: 'fulltime_permanent', name: 'Full-time (Permanent)'},
    {id: 'fulltime_temporary', name: 'Full-time (Temporary)'},
    {id: 'parttime_permanent', name: 'Part-time (Permanent)'},
    {id: 'parttime_temporary', name: 'Part-time (Temporary)'},
    {id: 'seasonal', name: 'Seasonal'},
    {id: 'contract', name: 'Contract'},
  ],
};

export const shiftTime = {
  name: 'shiftTime',
  label: 'Shift Time',
  array: [
    {id: 'morning', name: 'Morning'},
    {id: 'afternoon', name: 'Afternoon'},
    {id: 'night', name: 'Night'},
  ],
};
