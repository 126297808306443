import {
  createStaffSecurityKey,
  generateCode,
  getStaffSecurityKey,
} from '@digistaff/staff';
import {app} from '../Accounts';

export const createNewSecurityKey = async (staffId: string) => {
  try {
    const payload = {id: staffId};
    return await createStaffSecurityKey(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const generateQRcode = async () => {
  try {
    const res = await generateCode(app);
    return res;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchStaffSecurityKey = async () => {
  try {
    return await getStaffSecurityKey(app);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};
