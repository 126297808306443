import {Box, Button} from '@mui/material';
import {Doughnut} from 'react-chartjs-2';
import {BottomBar} from '../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {bottomSubPageLimit} from '../ProfileView/ProfileViewStyles';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from 'chart.js';
import {continueBtn} from '../../Public/LandingView/LandingViewStyles';
import {useNavigate} from 'react-router-dom';
import {chartBox, scoreBox, scoreDesc, scoreHeader} from './DigiScoreStyle';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import gradient from 'chartjs-plugin-gradient';
import {useEffect, useState} from 'react';
import {fetchDigiScore} from '../../../providers/api/digiScore';
import {CustomTextLoad} from '../../../components/common/CustomTextLoad/CustomTextLoad';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, gradient);

export const DigiScoreView = () => {
  const navigate = useNavigate();
  const [digiScore, setDigiScore] = useState(null);
  const staffId = localStorage.getItem('email');
  const [scoreLoading, setScoreLoading] = useState(true);

  useEffect(() => {
    const loadDigiScore = () => {
      if (staffId) {
        fetchDigiScore(staffId)
          .then(res => setDigiScore(res))
          .catch(err => {
            console.log(err);
            console.log(err);
          })
          .finally(() => setScoreLoading(false));
      }
    };
    loadDigiScore();
  }, [staffId]);

  const goToCenter = () => navigate('/training/center');

  const remainScore = () => {
    if (digiScore) {
      return 800 - digiScore;
    } else {
      return 800 - 0;
    }
  };

  const chartData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green'],
    datasets: [
      {
        label: '# of Scores',
        data: [digiScore, remainScore()],
        backgroundColor: ['#3E82BF', 'rgba(0,0,0, 0.2)'],
        borderColor: ['#3E82BF', 'rgba(0,0,0, 0.2)'],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOption = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      gradient,
    },
    rotation: 220,
    circumference: 280,
    cutout: '90%',
    maintainAspectRatio: true,
    responsive: true,
  };

  return (
    <>
      <Navbar rightTop />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Your DigiScore">
          <Box sx={chartBox}>
            <Doughnut
              data={chartData}
              options={doughnutOption}
              id="digiChart"
            />
            <Box sx={scoreBox}>
              {scoreLoading ? (
                <Box sx={{display: 'flex', gap: 0.5}}>
                  <CustomTextLoad height={3} width={10} />
                  <CustomTextLoad height={3} width={10} />
                  <CustomTextLoad height={3} width={10} />
                </Box>
              ) : (
                <Box sx={{fontSize: '40px'}}>{digiScore}</Box>
              )}
            </Box>
          </Box>
          <Box sx={{pt: 2, px: 2}}>
            <Box sx={scoreHeader}>What is DigiScore?</Box>
            <Box sx={scoreDesc}>
              The DigiScore is a number from 100 to 800 that rates an
              employee&apos;s commitment in DigiStaff. The higher the score, the
              better an employee looks to potential employer.
            </Box>
            <Box sx={scoreHeader}>Why you need DigiScore?</Box>
            <Box sx={scoreDesc}>
              Your DigiScore is used to determine whether you are eligible for a
              job and your priority in the applicants&apos; list. It is also
              used by potential employers to evaluate your reliability.
            </Box>
            <Box sx={scoreHeader}>Improve your DigiScore</Box>

            <Box sx={{px: {xs: 0, sm: 2}, pb: 2}}>
              <Button
                variant="outlined"
                sx={continueBtn}
                onPointerUp={goToCenter}
              >
                Visit Training Center
              </Button>
            </Box>
            <Box sx={{py: 2}} />
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
