/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, CircularProgress} from '@mui/material';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {BottomBar} from '../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {filterContentContainer} from '../DropDownView/DropDownViewStyles';
import {continueBtn} from '../../Public/LandingView/LandingViewStyles';
import {
  detailTitle,
  jobBoxBorder,
  jobDetailsBtnBox,
  jobSubTitle,
  mapBox,
  scrollLimitJobDetails,
} from './JobDetailViewStyles';
import moment from 'moment';
import {CustomMap} from '../../../components/common/GoogleMap/Map';
import {useEffect, useState} from 'react';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';
import {ShiftStatus} from '@digistaff/shift';
import {getStaffProfileCrossTenant} from '@digistaff/staff';
import {
  checkJobInstance,
  fetchJobDetails,
} from '../../../providers/api/shiftPosting';
import {CustomTextLoad} from '../../../components/common/CustomTextLoad/CustomTextLoad';
import {
  applyNewShift,
  checkJobApplicationStatus,
  deleteAppliedShift,
} from '../../../providers/api/shift';
import {CustomDialog} from '../../../components/common/Dialog/CustomDialog';
import {fetchStaffProfileRaw} from '../../../providers/api/profile';
import {inviteStaffToBusiness} from '@digistaff/business';
import {app, setTenantKey} from '../../../providers/Accounts';
import {JobConfirmationDialog} from '../../../components/common/JobConfirmationDialog/JobConfirmationDialog';
import {TrainingChecker} from '../../../components/common/TrainingChecker/TrainingChecker';
import {MandatoryDocumentChecker} from '../../../components/common/MandatoryDocumentChecker/MandatoryDocumentTracker';
import {
  checkTenantVerificationStatus,
  checkTenantVerificationStatusInDigiJobs,
  passedAllRequiredAgencyTrainings,
  passedAllRequiredAgencyTrainingsInDigiJobs,
  removeOldJobPosts,
  resubmitApplicationToTenant,
  signedAllContracts,
  signedAllContractsInDigiJobs,
} from '../JobView/JobHelper';
import {ContractChecker} from '../../../components/common/ContractChecker/ContractTracker';
import {SwitchProfileDialog} from '../../../components/common/SwitchProfileDialog/SwitchProfileDialog';
import {
  checkRequiredAgencyCerts,
  checkRequiredAgencyCertsInDigiJobs,
} from '../JobView/CheckCertHelper';
import {CertChecker} from '../../../components/common/CertChecker/CertChecker';
import {StaffCustomAttributeForm} from '../../../components/common/StaffCustomAttributeForm/StaffCustomAttributeForm';

import {useCurrentUser} from '../../../hooks/useCurrentUser';
import {removeOldSchedule} from '../ScheduleView/ScheduleHelpers';

import {checkRequiredStaffCustomAttributes} from '../../../providers/helpers/checkers';

export const JobDetailView = () => {
  const params: any = useParams();
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [job, setJob] = useState<any>({});
  const latLng = {lat: job?.latitude, lng: job?.longitude};
  const [loading, setLoading] = useState(true);
  const staffId: string = localStorage.getItem('email')!;
  const [buttonLabel, setButtonLabel] = useState('');
  const [discard, setDiscard] = useState(false);
  const [shiftId, setShiftId] = useState('');
  const tenantId = localStorage.getItem('tenantId');
  const location = useLocation();
  const [hasConfJob, setHasConfJob] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [checker, setChecker] = useState(false);
  const [requiredTrainings, setRequiredTrainings] = useState<any>([]);
  const navigate = useNavigate();
  const [contractChecker, setContractChecker] = useState(false);
  const [requiredContracts, setRequiredContracts] = useState<any>([]);
  const showCustomMap =
    typeof latLng.lat === 'number' &&
    typeof latLng.lng === 'number' &&
    !Number.isNaN(latLng.lat) &&
    !Number.isNaN(latLng.lng);
  const jobTenantId = location?.state?.jobTenantId;
  const requiredAgencyTraining = location?.state?.required_agency_trainings;
  const requiredCertifications = location?.state?.required_certifications;
  const [switchProfileDialog, setSwitchProfileDialog] = useState(false);
  const [fullAddr, setFullAddr] = useState(false);
  const [certChecker, setCertChecker] = useState(false);
  const [requiredCerts, setRequiredCerts] = useState<any>([]);
  const {userValid, sessionInvalidRedirection, inAppErrMsg} = useCurrentUser();
  const [isDocsExpired, setIsDocsExpired] = useState(false);
  const [requiredStaffCustomAttributes, setRequiredStaffCustomAttributes] =
    useState<any>([]);

  useEffect(() => {
    getShift();
    btnDisplay().catch(err => {
      console.log(err);
    });
  }, []);

  const getShift = () => {
    if (jobTenantId) setTenantKey(app, jobTenantId);
    fetchJobDetails(params.id)
      .then((res: any) => {
        setJob(res);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (jobTenantId) setTenantKey(app, tenantId!);
        setLoading(false);
      });
  };

  const applyJob = () => {
    if (jobTenantId) setTenantKey(app, jobTenantId);
    applyNewShift(staffId, job.id, job.startDate)
      .then((res: any) => {
        if (res.data.createShift.id === null) {
          setHasConfJob(true);
        } else {
          handlePopUp('success', 'You have successfully applied!');
          setButtonLabel('cancel');
          removeOldSchedule();
          removeOldJobPosts();
        }
      })
      .catch(err => {
        handlePopUp('error', 'Unable to apply job');
        console.log(err);
      })
      .finally(() => {
        if (jobTenantId) setTenantKey(app, tenantId!);
      });
  };

  const dropJob = (shiftId: string) => {
    setSubmitting(true);
    if (jobTenantId) setTenantKey(app, jobTenantId);
    deleteAppliedShift(shiftId)
      .then(() => handlePopUp('success', 'You dropped the application!'))
      .catch(err => {
        console.log(err);
        handlePopUp('error', 'Unable to drop shift with errors');
      })
      .finally(() => {
        handleDiscard();
        removeOldSchedule();
        removeOldJobPosts();
        setTimeout(() => {
          navigate('/jobs');
        }, 1000);
      })
      .catch(err => {
        handlePopUp('error', 'Unable to drop shift with errors');
        console.log(err);
      })
      .finally(() => {
        if (jobTenantId) setTenantKey(app, tenantId!);
      });
  };

  const handleHasConfJob = () => setHasConfJob(!hasConfJob);

  const handleChecker = () => setChecker(!checker);
  const handleContractChecker = () => setContractChecker(!contractChecker);
  const handleCertChecker = () => setCertChecker(!certChecker);
  const handleProfileDialog = () =>
    setSwitchProfileDialog(!switchProfileDialog);

  const reloadJobs = () => {
    handlePopUp(
      'error',
      'This job is no longer accepting applications. We will now refresh the Job Feed and show you the latest available jobs'
    );
    removeOldJobPosts();
    setTimeout(() => {
      navigate('/jobs');
    }, 700);
  };

  const simpleApply = async () => {
    setSubmitting(true);
    try {
      const jobInstance = await checkJobInstance(params.id);

      if (jobInstance.status === 'deleted') {
        reloadJobs();
        return;
      }

      const profile_tenants: any = await checkTenantVerificationStatus(
        staffId!
      );
      const verification_status =
        profile_tenants.data.listStaffProfileTenants[0].verification_status;

      if (verification_status === 'rejected') {
        await resubmitApplicationToTenant(job.tenant_id, staffId!);
      }

      const appliedJob = await checkJobApplicationStatus(staffId, params.id);
      if (appliedJob[0]?.status === ShiftStatus.applied) {
        handleDiscard();
        setShiftId(appliedJob[0]?.id);
      } else {
        applyJob();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const applyTenantJob = async () => {
    setSubmitting(true);
    try {
      const profile_tenants = await checkTenantVerificationStatusInDigiJobs(
        job.tenant_id
      )(staffId!);

      if (
        profile_tenants &&
        profile_tenants?.data?.listStaffProfileTenants?.length === 0
      ) {
        const profile: any = await fetchStaffProfileRaw(staffId!);
        const tenantArray = profile?.tenants === null ? [] : profile.tenants;
        const payload = {email: staffId, phone: '', tenants: tenantArray};

        await inviteStaffToBusiness(app, payload, true, job.tenant_id);
        setSwitchProfileDialog(true);
        return;
      }

      const verification_status =
        profile_tenants.data.listStaffProfileTenants[0].verification_status;

      if (
        verification_status === 'approved' ||
        verification_status === 'pending'
      ) {
        setSwitchProfileDialog(true);
      } else {
        await resubmitApplicationToTenant(job.tenant_id, staffId!);
        setSwitchProfileDialog(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const applyOrSendInvitation = async (
    isTrainingAllPass: boolean,
    allTrainings: any,
    staffCustomAttributes: any,
    staffProfileTenant: any
  ) => {
    if (isTrainingAllPass && staffCustomAttributes.isPass) {
      if (tenantId === job.tenant_id) {
        await simpleApply();
      } else {
        await applyTenantJob();
      }
    } else {
      if (!isTrainingAllPass) {
        setRequiredTrainings(allTrainings);
        setChecker(true);
      }

      if (
        !staffCustomAttributes.isPass &&
        staffProfileTenant?.data?.getStaffProfileTenant?.id
      )
        setRequiredStaffCustomAttributes(staffCustomAttributes.required);

      setSubmitting(false);
    }
  };

  const jobApplicationCheck = async () => {
    setSubmitting(true);

    try {
      const userStatus = await userValid();
      if (!userStatus) {
        handlePopUp('error', inAppErrMsg);
        sessionInvalidRedirection();
        return;
      }

      if (isDocsExpired) {
        handlePopUp(
          'error',
          'Unable to apply. Please update your expired documents.'
        );
        setSubmitting(false);
        return;
      }

      const certifications: any =
        tenantId === 'default'
          ? await checkRequiredAgencyCertsInDigiJobs(job.tenant_id)(
              staffId!,
              requiredCertifications
            )
          : await checkRequiredAgencyCerts(staffId!, requiredCertifications);
      if (certifications?.hasAllCerts) {
        const contracts: any =
          tenantId === 'default'
            ? await signedAllContractsInDigiJobs(job.tenant_id)
            : await signedAllContracts();

        if (contracts?.allSigned) {
          const trainings: any =
            tenantId === 'default'
              ? await passedAllRequiredAgencyTrainingsInDigiJobs(
                  requiredAgencyTraining,
                  job.tenant_id
                )
              : await passedAllRequiredAgencyTrainings(requiredAgencyTraining);

          // check for the staff custom attributes
          const staffProfileTenant = await getStaffProfileCrossTenant(
            app,
            staffId,
            job.tenant_id
          );

          const staffCustomAttributes = checkRequiredStaffCustomAttributes(
            staffProfileTenant?.data?.getStaffProfileTenant,
            job.required_staff_custom_attributes || []
          );

          await applyOrSendInvitation(
            trainings?.isAllPass,
            trainings?.allTrainings,
            staffCustomAttributes,
            staffProfileTenant
          );
        } else {
          setRequiredContracts(
            tenantId === 'default'
              ? contracts?.contractListWithTenant
              : contracts?.contractsWithStaffSignatureStatus
          );
          setContractChecker(true);
          setSubmitting(false);
        }
      } else {
        setSubmitting(false);
        setCertChecker(true);
        setRequiredCerts(certifications?.certArray);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleApply = async () => {
    if (jobTenantId) setTenantKey(app, jobTenantId);
    try {
      if (buttonLabel === 'cancel') {
        const appliedJob = await checkJobApplicationStatus(staffId, params.id);
        if (appliedJob[0]?.status === ShiftStatus.applied) {
          handleDiscard();
          setShiftId(appliedJob[0]?.id);
        }
      } else {
        await jobApplicationCheck();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBusinessLocationString = (
    street: string,
    city: string,
    postal: string
  ) => {
    const streetString = street || '';
    const cityString = city || '';
    const postalString = postal || '';

    return `${streetString}${cityString ? `, ${cityString}` : ''}${
      postalString ? ` ${postalString}` : ''
    }`;
  };

  const btnDisplay = async () => {
    try {
      const appliedJob = await checkJobApplicationStatus(staffId, params.id);

      if (appliedJob[0]?.status === ShiftStatus.applied) {
        setButtonLabel('cancel');
      } else if (appliedJob[0]?.status === ShiftStatus.default) {
        setButtonLabel('apply');
      } else if (
        appliedJob[0]?.status === ShiftStatus.backup ||
        appliedJob[0]?.status === ShiftStatus.rejected ||
        appliedJob[0]?.status === ShiftStatus.unscheduled
      ) {
        setButtonLabel('hidden');
      } else if (appliedJob[0]?.status === ShiftStatus.confirmed) {
        setFullAddr(true);
        setButtonLabel('hidden');
      } else {
        setButtonLabel('apply');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDiscard = () => setDiscard(!discard);

  const confirmDiscard = () => dropJob(shiftId);

  return (
    <Box sx={{width: '100%'}}>
      <Navbar backBtn rightTop />
      <Box sx={scrollLimitJobDetails}>
        <Box sx={filterContentContainer}>
          <Box sx={{px: 2}}>
            {showCustomMap && (
              <Box sx={mapBox}>
                {loading ? (
                  <CustomTextLoad height={200} fullWidth />
                ) : (
                  <CustomMap latLng={latLng} />
                )}
              </Box>
            )}
            <Box sx={jobBoxBorder}>
              {loading ? (
                <Box sx={{mt: 2}}>
                  <CustomTextLoad height={500} fullWidth />
                </Box>
              ) : (
                <>
                  <Box sx={detailTitle}>Job Details</Box>
                  <Box sx={jobSubTitle}>{job?.jobTitle}</Box>
                  <Box sx={{textTransform: 'capitalize'}}>
                    Business: {job?.business}
                  </Box>

                  <Box sx={{textTransform: 'capitalize'}}>
                    Work Location:{' '}
                    {fullAddr
                      ? getBusinessLocationString(
                          job?.street,
                          job?.city,
                          job.postal
                        )
                      : job?.city}
                  </Box>

                  <Box sx={jobSubTitle}>Job Details</Box>
                  <Box>Salary: {`$${job?.salary} an hour`}</Box>
                  <Box>
                    Start Date: {moment(job?.startDate).format('DD MMM YYYY')}
                  </Box>
                  <Box>
                    Starts: {moment(job?.startTime, 'h:mmA').format('LT')}{' '}
                  </Box>
                  <Box>Ends: {moment(job?.endTime, 'h:mmA').format('LT')} </Box>
                  {job?.jobDesc && (
                    <Box>
                      <Box sx={jobSubTitle}>Job Descriptions</Box>
                      {job.jobDesc
                        .split('\n')
                        .map((desc: string, index: number) => (
                          <Box key={index}>{desc}</Box>
                        ))}
                    </Box>
                  )}
                  {job?.jobDuties && (
                    <Box>
                      <Box sx={jobSubTitle}>Job Duties</Box>
                      {job.jobDuties
                        .split('\n')
                        .map((duty: string, index: number) => (
                          <Box key={index}>{duty}</Box>
                        ))}
                    </Box>
                  )}
                  {job?.jobReq && (
                    <Box>
                      <Box sx={jobSubTitle}>Job Requirements</Box>
                      {job.jobReq
                        .split('\n')
                        .map((req: string, index: number) => (
                          <Box key={index}>{req}</Box>
                        ))}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
          {buttonLabel !== 'hidden' && job.status !== 'deleted' && (
            <Box sx={jobDetailsBtnBox}>
              <Button
                variant="outlined"
                sx={continueBtn}
                onPointerUp={handleApply}
                disabled={submitting || loading}
              >
                {submitting ? (
                  <CircularProgress
                    size="1.3rem"
                    sx={{color: 'info.main', fontSize: '12px'}}
                  />
                ) : (
                  <>{buttonLabel}</>
                )}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        duration={1000}
        status={status}
        message={message}
      />
      <BottomBar />
      <CustomDialog
        open={discard}
        close={handleDiscard}
        title="Are you sure?"
        leftOnClick={handleDiscard}
        rightOnClick={confirmDiscard}
      />
      <JobConfirmationDialog
        open={hasConfJob}
        onClose={handleHasConfJob}
        jobDate={moment(job.startDate).format('DD MMM YYYY')}
      />
      <TrainingChecker
        open={checker}
        onClose={handleChecker}
        requiredTrainings={requiredTrainings}
      />
      <ContractChecker
        open={contractChecker}
        onClose={handleContractChecker}
        requiredContracts={requiredContracts}
      />
      <SwitchProfileDialog
        open={switchProfileDialog}
        onClose={handleProfileDialog}
      />
      <CertChecker
        open={certChecker}
        onClose={handleCertChecker}
        requiredCerts={requiredCerts}
      />
      <MandatoryDocumentChecker
        setIsDocsExpired={setIsDocsExpired}
        onClose={() => {}}
      />
      <StaffCustomAttributeForm
        requiredStaffCustomAttributes={requiredStaffCustomAttributes}
        shift={job}
        staffId={staffId}
        onClose={() => {}}
      />
    </Box>
  );
};
