/* eslint-disable @typescript-eslint/no-explicit-any */
import {app} from '../Accounts';
import {
  createAppointment,
  deleteAppointment,
  listAppointments,
  listAppointmentCategories,
  listAppointmentLocationSettings,
  updateAppointment,
  listAppointmentCustomDateSettings,
} from '@digistaff/business';
import moment from 'moment';
import {handleCustomError} from '../../views/RouteErrorView/RouteErrorView';
import {ShiftListType, listAllShifts} from '@digistaff/shift';

export const fetchAppointmentCategories = async () => {
  try {
    const res = await listAppointmentCategories(app, {
      filter: {status: 'active'},
    });

    return res?.data?.listAppointmentCategories;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const fetchTimeSlotAvailability = async (
  selectedDate: string,
  timeSlotsTemp: any,
  availableSlots: number
) => {
  try {
    const parsedDate = moment(selectedDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const startDateTime = moment(parsedDate).startOf('day').toISOString();
    const endDateTime = moment(parsedDate).endOf('day').toISOString();

    const filterCriteria = {
      appointment_date: {
        operator: 'between',
        value: {
          from: startDateTime,
          to: endDateTime,
        },
      },
      status: {
        operator: '<>',
        value: 'cancelled',
      },
    };

    const options = {filter: filterCriteria};

    const res = await listAppointments(app, options);

    const appointmentTimes = res?.data?.listAppointments.map(
      (appointment: any) => {
        return moment(appointment.appointment_date).format('hh:mm A');
      }
    );

    const filteredTimeSlots =
      timeSlotsTemp &&
      timeSlotsTemp.filter((timeSlot: string) => {
        const count = appointmentTimes.filter(
          (appointmentTime: string) => appointmentTime === timeSlot
        ).length;

        return count < availableSlots;
      });

    return filteredTimeSlots;
  } catch (err) {
    handleCustomError(err);
    return [];
  }
};

export const fetchAppointmentTimeSlot = async () => {
  try {
    const res = await listAppointmentLocationSettings(app);
    const agentProfile = res?.data?.listAppointmentLocationSettings[0];

    const weekdays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];
    const times: {[key: string]: any} = {};

    for (let i = 0; i < weekdays.length; i++) {
      const day = weekdays[i];
      const start_time_key = day + '_start_time';
      const end_time_key = day + '_end_time';

      if (
        agentProfile[start_time_key] !== null &&
        agentProfile[end_time_key] !== null
      ) {
        times[day] = {
          intervalMinutes: agentProfile.interval_minutes,
          startTime: agentProfile[start_time_key],
          endTime: agentProfile[end_time_key],
        };
      }
    }

    const dates = {
      sunday: {
        startTime: agentProfile.sunday_start_time,
        endTime: agentProfile.sunday_end_time,
      },
      monday: {
        startTime: agentProfile.monday_start_time,
        endTime: agentProfile.monday_end_time,
      },
      tuesday: {
        startTime: agentProfile.tuesday_start_time,
        endTime: agentProfile.tuesday_end_time,
      },
      wednesday: {
        startTime: agentProfile.wednesday_start_time,
        endTime: agentProfile.wednesday_end_time,
      },
      thursday: {
        startTime: agentProfile.thursday_start_time,
        endTime: agentProfile.thursday_end_time,
      },
      friday: {
        startTime: agentProfile.friday_start_time,
        endTime: agentProfile.friday_end_time,
      },
      saturday: {
        startTime: agentProfile.saturday_start_time,
        endTime: agentProfile.saturday_end_time,
      },
    };

    const availableSlots = agentProfile.booking_per_slot;

    const timezone = {
      id: agentProfile.timezone_id,
      offset: agentProfile.timezone_offset,
    };

    return {times, dates, availableSlots, timezone};
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const createStaffAppointment = async (
  staffId: string,
  category: string,
  date: string,
  time: string,
  reason: string,
  businessCustom?: any
) => {
  const [dayString, monthString, yearString] = date.split('/');
  const [timeString, meridiem] = time.split(' ');
  const [hourString, minuteString] = timeString.split(':');

  const month = Number(monthString);
  const year = Number(yearString);
  const day = Number(dayString);
  let hours = Number(hourString);
  const minutes = Number(minuteString);

  if (meridiem === 'PM' && hours !== 12) {
    hours += 12;
  }

  if (meridiem === 'AM' && hours === 12) {
    hours = 0;
  }

  const formattedDate = new Date(
    year,
    month - 1,
    day,
    hours,
    minutes
  ).toISOString();

  const customPayload = !businessCustom
    ? null
    : {
        cheque_pickup: {
          week_ending: businessCustom?.weekEnding,
          business_location_id: businessCustom?.businessLocationId,
          business_name: businessCustom?.businessName,
          business_location_street: businessCustom?.businessStreet,
          business_location_city: businessCustom?.businessCity,
          business_location_postal: businessCustom?.businessPostal,
          business_location_province: businessCustom?.businessProvince,
          business_location_country: businessCustom?.businessCountry,
        },
      };

  const payload = {
    staff_id: staffId,
    appointment_date: formattedDate,
    appointment_category_id: category,
    status: 'pending',
    notes: reason,
    custom: JSON.stringify(customPayload),
  };

  try {
    return await createAppointment(app, payload);
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const fetchAppointmentLocation = async () => {
  try {
    const locationRes = await listAppointmentLocationSettings(app);
    const locationSettings =
      locationRes.data.listAppointmentLocationSettings[0];
    const location = {
      address: `${locationSettings.street}, ${locationSettings.city}, ${locationSettings.province} ${locationSettings.postal}`,
      lat: locationSettings.latitude,
      lng: locationSettings.longitude,
    };
    return location;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const fetchStaffAppointment = async (staffId: string) => {
  try {
    const filterCriteria = {staff_id: staffId, status: 'pending'};
    const limit = 1;
    const orderCriteria = [{column: 'created_at', order: 'desc'}];
    const options = {filter: filterCriteria, limit, order: orderCriteria};
    const appointmentRes = await listAppointments(app, options);

    return appointmentRes.data.listAppointments;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const fetchLocationSettings = async () => {
  try {
    const locationRes = await listAppointmentLocationSettings(app);

    return locationRes;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const deleteStaffAppointment = async (appointment: any) => {
  try {
    const appointmentId = appointment.id;
    return await deleteAppointment(app, appointmentId);
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const dropAppointment = async (appointment: any) => {
  try {
    const id = appointment[0].id;
    const payload = {
      status: 'cancelled',
    };
    const res = await updateAppointment(app, id, payload);

    return res;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const fetchCustomBusinessDateSetting = async () => {
  try {
    const currentDate = new Date();
    const dateTwoWeeksLater = new Date();
    dateTwoWeeksLater.setDate(currentDate.getDate() + 14);

    const res = await listAppointmentCustomDateSettings(app, {
      filter: {
        start_date: {
          operator: 'between',
          value: {
            from: currentDate.toISOString(),
            to: dateTwoWeeksLater.toISOString(),
          },
        },
        status: 'active',
      },
    });
    return res?.data?.listAppointmentCustomDateSettings;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  }
};

export const checkTimeSlot = async (
  selectedDate: string,
  selectedTime: any,
  selectedTimeSlot: number
) => {
  try {
    const parsedDate = moment(selectedDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const startDateTime = moment(parsedDate).startOf('day').toISOString();
    const endDateTime = moment(parsedDate).endOf('day').toISOString();

    const filterCriteria = {
      appointment_date: {
        operator: 'between',
        value: {
          from: startDateTime,
          to: endDateTime,
        },
      },
      status: {
        operator: '<>',
        value: 'cancelled',
      },
    };

    const options = {filter: filterCriteria};

    const res = await listAppointments(app, options);

    const appointmentTimes = res.data.listAppointments.map(
      (appointment: any) => {
        return moment(appointment.appointment_date).format('hh:mm A');
      }
    );

    const timeFrequency = appointmentTimes.reduce((acc: any, time: any) => {
      if (time === selectedTime) acc++;
      return acc;
    }, 0);

    return timeFrequency < selectedTimeSlot;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  }
};

export const fetchBusinessLocationForAppointment = async (
  staffId: string,
  startDate: any,
  endDate: any
) => {
  try {
    const res = await listAllShifts(app, ShiftListType.schedule, {
      filter: {
        staff_id: staffId,
        status: {
          operator: 'in',
          value: ['confirmed', 'backup'],
        },
        shift_date: {
          operator: 'between',
          value: {
            from: startDate.toISOString(),
            to: endDate.toISOString(),
          },
        },
      },
    });
    const generated_business_list = res?.data?.listShifts.map((shift: any) => {
      const location =
        shift?.nestGetShiftInstance?.nestGetShiftPosting
          ?.nestGetBusinessLocation;
      const businessLocationString = `${location?.street}, ${location?.city}, ${location?.province} ${location?.postal}, ${location?.country}`;

      return {
        businessName:
          shift?.nestGetShiftInstance?.nestGetShiftPosting?.nestGetBusiness
            ?.name,
        businessLocation: location,
        businessLocationString: businessLocationString,
        businessLocationId:
          shift?.nestGetShiftInstance?.nestGetShiftPosting
            ?.business_location_id,
      };
    });

    const unique_business_list = generated_business_list.filter(
      (business: any, index: number, self: any) =>
        index ===
        self.findIndex(
          (b: any) => b.businessLocationId === business.businessLocationId
        )
    );

    return unique_business_list;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  }
};
