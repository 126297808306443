/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Add, ArrowRightAlt} from '@mui/icons-material';
import {Box, Chip, Grid} from '@mui/material';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {CustomTextLoad} from '../../../../../components/common/CustomTextLoad/CustomTextLoad';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {PopUp} from '../../../../../components/common/PopUp/PopUp';
import {SkillDrawer} from '../../../../../components/common/QualificationDrawer/SkillDrawer';
import {WorkDrawer} from '../../../../../components/common/QualificationDrawer/WorkDrawer';
import {usePopUp} from '../../../../../hooks/usePopUp';
import {
  createStaffWorkExp,
  createStaffWorkSkill,
  fetchStaffExperienceList,
  fetchStaffSkillsList,
} from '../../../../../providers/api/qualification';
import {
  bottomSubPageLimit,
  docuCardBox,
  docuQualiTitle,
  docuQualiBox,
  qualiInstruct,
  addGrid,
  gridTextRight,
  allExp,
} from '../../ProfileViewStyles';

export const Qualifications = () => {
  const [jobDrawer, setJobDrawer] = useState(false);
  const [skillDrawer, setSkillDrawer] = useState(false);
  const [jobs, setJobs] = useState<any>([]);
  const [skills, setSkills] = useState<any>([]);
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState('');
  const [company, setCompany] = useState('');
  const [expBegin, setExpBegin] = useState('');
  const [expEnd, setExpEnd] = useState('');
  const [industry, setIndustry] = useState('');
  const {popUp, closePopUp, handlePopUp, message, status} = usePopUp();
  const staffId = localStorage.getItem('email');
  const [loading, setLoading] = useState(true);
  const [skillLoading, setSkillLoading] = useState(true);

  useEffect(() => {
    loadExp().catch(err => {
      console.log(err);
      console.log(err);
    });
    loadSkill().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const loadExp = async () => {
    try {
      if (staffId) {
        const jobRes = await fetchStaffExperienceList(staffId);
        setJobs(jobRes);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const loadSkill = async () => {
    try {
      if (staffId) {
        const skillRes = await fetchStaffSkillsList(staffId);

        setSkills(skillRes);
        setSkillLoading(false);
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const formIsEmpty = (...inputs: (string | number)[]) => {
    if (inputs.some(input => !input || input === null)) return true;
    return false;
  };

  const handleJobDrawer = () => setJobDrawer(!jobDrawer);

  const handleSkillDrawer = () => setSkillDrawer(!skillDrawer);

  const jobFieldEmpty = () =>
    !jobTitle || !company || !expBegin || !expEnd || !industry;

  const clearFields = () => {
    setJobTitle('');
    setCompany('');
    setExpBegin('');
    setExpEnd('');
    setIndustry('');
  };

  const closeJobDrawer = () => {
    handlePopUp('success', 'Your qualification has been saved');
    clearFields();
    handleJobDrawer();
    loadExp().catch(err => {
      console.log(err);
      console.log(err);
    });
  };

  const closeExpDrawer = () => {
    handlePopUp('success', 'Your qualification has been saved');
    setSkillDrawer(false);
    loadSkill().catch(err => {
      console.log(err);
      console.log(err);
    });
  };

  const addJob = () => {
    if (staffId) {
      createStaffWorkExp(staffId, jobTitle, company, industry, expBegin, expEnd)
        .then(() => closeJobDrawer())
        .catch(err => {
          handlePopUp('error', 'Unable to save your qualification');
          console.log(err);
        });
    }
  };

  const handleJobSubmit = () => {
    jobFieldEmpty()
      ? handlePopUp('error', 'Please fill in all the fields')
      : addJob();
  };

  const addSkill = (skillTitle: string, exp: number) => {
    if (staffId) {
      createStaffWorkSkill(staffId, skillTitle, exp)
        .then(() => closeExpDrawer())
        .catch(err => {
          handlePopUp('error', 'Unable to save your qualification');
          console.log(err);
        });
    }
  };

  const handleSkills = (event: any) => {
    event.preventDefault();
    const skillTitle = event.target.elements.skillTitle.value;
    const exp: number = event.target.elements.exp.value;

    if (formIsEmpty(skillTitle, exp)) {
      handlePopUp('error', 'Please fill in all the fields!');
      return;
    } else {
      addSkill(skillTitle, exp);
    }
  };

  const calculateYear = (start: string, end: string) => {
    const startDate = moment(start);
    const endDate = moment(end);

    const yearDiff = endDate.diff(startDate, 'years');
    return yearDiff;
  };

  const goToWorks = () => navigate('/qualifications/experience/works');

  const goToSkills = () => navigate('/qualifications/experience/skills');

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Qualifications" />
        <Box sx={{py: 1}} />
        <Box sx={qualiInstruct}>
          These details are used to display job opportunities that are tailored
          to your specific skills and experience.
        </Box>
        <Box sx={docuCardBox}>
          <Box sx={{py: 1}} />
          <Grid container sx={docuQualiBox}>
            <Grid item xs={1}></Grid>
            <Grid item xs={9.5}>
              <Box sx={docuQualiTitle}>Work Experience</Box>
              {loading ? (
                <>
                  <CustomTextLoad height={13} mb={10} width={200} />
                  <CustomTextLoad height={13} mb={10} width={150} />
                  <CustomTextLoad height={13} mb={10} width={180} />
                  <CustomTextLoad height={13} mb={10} width={100} />
                </>
              ) : (
                <>
                  {jobs.length !== 0 ? (
                    <Box>
                      <Box sx={gridTextRight}>
                        Job Title: {jobs[0].jobTitle}
                      </Box>
                      <Box sx={gridTextRight}>Company: {jobs[0].company}</Box>
                      <Box sx={gridTextRight}>
                        Industry: {jobs[0].industryName}
                      </Box>
                      <Box sx={gridTextRight}>
                        Years of Experience:{' '}
                        {calculateYear(jobs[0].start, jobs[0].end)}
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{color: 'secondary.dark'}}>
                      Add your work experience!
                    </Box>
                  )}
                </>
              )}
              <Box sx={allExp} onPointerUp={goToWorks}>
                View All Experiences <ArrowRightAlt />
              </Box>
              <Box sx={{pb: '4px'}} />
            </Grid>
            <Grid item xs={1.5} sx={addGrid} onPointerUp={handleJobDrawer}>
              <Add sx={{fontSize: '36px'}} />
            </Grid>
          </Grid>
          <Grid container sx={docuQualiBox}>
            <Grid item xs={1}></Grid>
            <Grid item xs={9.5}>
              <Box sx={docuQualiTitle}>Skills</Box>
              {skillLoading ? (
                <CustomTextLoad height={25} width={200} />
              ) : (
                <>
                  {skills.length !== 0 ? (
                    skills
                      .slice(0, 3)
                      .map((skill: any) => (
                        <Chip
                          key={skill.id}
                          label={skill.name}
                          sx={{mr: 1, my: '4px'}}
                        />
                      ))
                  ) : (
                    <Box sx={{color: 'secondary.dark'}}>Add your skill!</Box>
                  )}
                </>
              )}
              <Box sx={{py: 1}} />
            </Grid>
            <Grid item xs={1.5} sx={addGrid} onPointerUp={handleSkillDrawer}>
              <Add sx={{fontSize: '36px'}} />
            </Grid>
            <Box sx={allExp} onPointerUp={goToSkills}>
              View All Skills <ArrowRightAlt />
            </Box>
          </Grid>
          <Box sx={{pt: '1px'}} />
        </Box>
      </Box>
      <BottomBar />
      <WorkDrawer
        open={jobDrawer}
        close={handleJobDrawer}
        setJobTitle={setJobTitle}
        setCompany={setCompany}
        setIndustry={setIndustry}
        jobTitle={jobTitle}
        company={company}
        industry={industry}
        expBegin={expBegin}
        setExpBegin={setExpBegin}
        expEnd={expEnd}
        setExpEnd={setExpEnd}
        handleJobSubmit={handleJobSubmit}
      />
      <SkillDrawer
        open={skillDrawer}
        close={handleSkillDrawer}
        handleSkills={handleSkills}
      />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
