import {Box, Button} from '@mui/material';
import {useState} from 'react';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {PasswordInput} from '../../../../../components/common/PasswordInput/PasswordInput';
import {continueBtn} from '../../../../Public/LandingView/LandingViewStyles';
import {bottomSubPageLimit} from '../../ProfileViewStyles';
import {app, changePassword} from '../../../../../providers/Accounts';
import {PopUp} from '../../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../../hooks/usePopUp';

export const ChangePwView = () => {
  const {handlePopUp, closePopUp, popUp, status, message} = usePopUp();
  const [oldPw, setOldPw] = useState({
    value: '',
    show: false,
  });
  const [newPw, setNewPw] = useState({
    value: '',
    show: false,
  });
  const [confNewPw, setConfNewPw] = useState({
    value: '',
    show: false,
  });
  const pwLength = 8;

  const handleOldPw = (event: React.ChangeEvent<HTMLInputElement>) =>
    setOldPw({...oldPw, value: event.target.value});

  const handleOldPwIcon = () => setOldPw({...oldPw, show: !oldPw.show});

  const handleNewPw = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNewPw({...newPw, value: event.target.value});

  const handleNewPwIcon = () => setNewPw({...newPw, show: !newPw.show});

  const handleConfNewPw = (event: React.ChangeEvent<HTMLInputElement>) =>
    setConfNewPw({...confNewPw, value: event.target.value});

  const handleConfNewPwIcon = () =>
    setConfNewPw({...confNewPw, show: !confNewPw.show});

  const checkPw =
    oldPw.value.length < pwLength ||
    newPw.value.length < pwLength ||
    confNewPw.value.length < pwLength;

  const sameNewPw = () => newPw.value === confNewPw.value;

  const emptyFields = () => {
    setOldPw({...oldPw, value: ''});
    setNewPw({...newPw, value: ''});
    setConfNewPw({...confNewPw, value: ''});
  };

  const handlePwChange = () => {
    changePassword(app, oldPw.value, newPw.value)
      .then(() => {
        handlePopUp('success', 'Your changes have been submitted');
        emptyFields();
      })
      .catch(err => {
        console.log(err);
        handlePopUp('error', `${err.code} , ${err.err}`);
      });
  };

  const submitPw = () =>
    sameNewPw()
      ? handlePwChange()
      : handlePopUp('error', 'New passwords do not match');

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Change Password">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mt: 3,
              px: 2,
            }}
          >
            <PasswordInput
              type={oldPw.show}
              onChange={handleOldPw}
              value={oldPw.value}
              onClickIcon={handleOldPwIcon}
            />
            <PasswordInput
              type={newPw.show}
              onChange={handleNewPw}
              value={newPw.value}
              onClickIcon={handleNewPwIcon}
              placeholder="Enter your new password"
            />
            <PasswordInput
              type={confNewPw.show}
              onChange={handleConfNewPw}
              value={confNewPw.value}
              onClickIcon={handleConfNewPwIcon}
              placeholder="Confirm your new password"
            />
            <Button
              variant="outlined"
              sx={continueBtn}
              disabled={checkPw}
              onPointerUp={submitPw}
            >
              Submit
            </Button>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
