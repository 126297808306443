/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createStaffAvailability,
  getStaffAvailability,
  updateStaffAvailability,
  updateStaffProfile,
} from '@digistaff/staff';
import {schedule} from '../../components/common/AvailabilityTable/schedule';
import {app} from '../Accounts';

export const getStaffAvailabilityReg = async (staffId: string) => {
  try {
    const res: any = await getStaffAvailability(app, staffId);
    const loadedTimeTable = res.data.getStaffAvailability;
    if (loadedTimeTable) {
      const newTimeTable = schedule.map(item => {
        const availability = loadedTimeTable[item.day];

        return {
          ...item,
          morning: (availability & 1) !== 0,
          afternoon: (availability & 2) !== 0,
          night: (availability & 4) !== 0,
        };
      });

      return newTimeTable;
    } else {
      return 'new';
    }
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createStaffAvailabilityReg = async (
  staffId: string,
  timeTable: any
) => {
  const formattedTimeTable: any = {};

  timeTable.forEach((item: any) => {
    let availability = 0;

    if (item.morning) {
      availability += 1;
    }

    if (item.afternoon) {
      availability += 2;
    }

    if (item.night) {
      availability += 4;
    }

    formattedTimeTable[item.day] = availability;
  });

  const payload = {
    id: staffId,
    ...formattedTimeTable,
  };

  await updateStaffProfile(app, staffId, {current_onboarding_step: 6});

  return await createStaffAvailability(app, payload);
};

export const updateStaffAvailabilityReg = async (
  staffId: string,
  timeTable: any
) => {
  const formattedTimeTable: any = {};

  timeTable.forEach((item: any) => {
    let availability = 0;

    if (item.morning) {
      availability += 1;
    }

    if (item.afternoon) {
      availability += 2;
    }

    if (item.night) {
      availability += 4;
    }

    formattedTimeTable[item.day] = availability;
  });

  const payload = {
    ...formattedTimeTable,
  };

  return await updateStaffAvailability(app, staffId, payload);
};
