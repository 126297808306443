/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {usePopUp} from '../../../../hooks/usePopUp';
import {useFileUpload} from '../../../../hooks/useFileUpload';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  createWorkDocument,
  getWorkDocument,
  updateWorkDocument,
} from '../../../../providers/api/documents';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {
  continueBtn,
  userScrollLimit,
} from '../../LandingView/LandingViewStyles';
import {PhotoCamera} from '@mui/icons-material';
import {
  flexAlign,
  photoIcon,
} from '../../../Private/ProfileView/ProfileViewStyles';
import {checkBoxLarge} from '../../../../components/common/AvailabilityTable/AvailabilityTableStyles';
import {PopUp} from '../../../../components/common/PopUp/PopUp';

import {useCurrentUser} from '../../../../hooks/useCurrentUser';

export const WorkDocRegView = () => {
  const [tempStatus, setTempStatus] = useState('');
  const [checked, setChecked] = useState(false);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [imageUrl, setImageUrl] = useState('');
  const {handleFileUpload, changeHandler, selectedFile, fileSelected} =
    useFileUpload();
  const staffId = sessionStorage.getItem('email');
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const workDocId = location.state.param1;
  const navigate = useNavigate();
  const {userValid, sessionInvalidRedirection, errMsg} = useCurrentUser();

  useEffect(() => {
    getData().catch(err => {
      console.log(err);
    });
  }, []);

  const getData = async () => {
    if (workDocId) {
      try {
        const retrievedDoc: any = await getWorkDocument(workDocId);
        setTempStatus(retrievedDoc.doc_type);
        setImageUrl(retrievedDoc._photo);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleWorkStatusChange = (newWorkStatus: string) => {
    if (newWorkStatus !== tempStatus) {
      setImageUrl('');
    }
    setTempStatus(newWorkStatus);
    setChecked(false);
  };

  const handleCheckBox = () => setChecked(!checked);

  const isWorkOrStudy = () =>
    ['work permit', 'study permit'].includes(tempStatus);

  const isCitizenOrPR = () =>
    ['permanent resident', 'citizenship'].includes(tempStatus);

  const isApplied = () => tempStatus === 'applied status';

  const createWorkDoc = async () => {
    let workPhoto: any;
    setSubmitting(true);

    const userStatus = await userValid();
    if (!userStatus) {
      handlePopUp('error', errMsg);
      sessionInvalidRedirection();
      return;
    }

    if (!isApplied()) {
      if (!fileSelected) {
        handlePopUp('error', 'Please upload document');
        setSubmitting(false);
        return;
      }

      const uploaded: any = await handleFileUpload(staffId!);
      workPhoto = uploaded.data.result.Location;
    } else {
      workPhoto = '';
    }

    createWorkDocument(staffId!, tempStatus, workPhoto)
      .then(() => closeDrawer())
      .catch(err => {
        console.log(err);
        handlePopUp('error', 'Unable to update work status');
      });
  };

  const updateWorkDoc = async () => {
    let workPhoto: any;
    setSubmitting(true);

    const userStatus = await userValid();
    if (!userStatus) {
      handlePopUp('error', errMsg);
      sessionInvalidRedirection();
      return;
    }

    if (!isApplied()) {
      if (!fileSelected) {
        handlePopUp('error', 'Please upload document');
        setSubmitting(false);
        return;
      }

      const uploaded: any = await handleFileUpload(staffId!);
      workPhoto = uploaded.data.result.Location;
    } else {
      workPhoto = '';
    }

    updateWorkDocument(workDocId, tempStatus, workPhoto)
      .then(() => closeDrawer())
      .catch(err => {
        console.log(err);
        handlePopUp('error', 'Unable to update work status');
      });
  };

  const handleData = () => (workDocId ? updateWorkDoc() : createWorkDoc());

  const closeDrawer = () => {
    handlePopUp(
      'success',
      'Your documents have been uploaded for verification'
    );

    setTimeout(() => {
      navigate('/info/id');
    }, 700);
  };

  const handleSubmit = () => {
    handleData().catch(err => {
      console.log(err);
    });
  };

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/info/id" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Upload Eligibility to Work">
          <Box
            sx={{
              mt: 3,
              mx: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <TextField
              id="status-field"
              value={tempStatus}
              select
              label="Work Status"
              sx={{width: '100%', textTransform: 'capitalize'}}
            >
              {statusList.map(o => (
                <MenuItem
                  id={o}
                  key={o}
                  value={o}
                  sx={{textTransform: 'capitalize'}}
                  onClick={() => handleWorkStatusChange(o)}
                >
                  {o}
                </MenuItem>
              ))}
            </TextField>
            {isCitizenOrPR() && (
              <Button
                id="upload-btn"
                variant="outlined"
                sx={continueBtn}
                component="label"
              >
                <PhotoCamera sx={photoIcon} />
                Upload Eligibility to Work
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={changeHandler}
                />
              </Button>
            )}
            {isWorkOrStudy() && (
              <Button
                id="upload-btn"
                variant="outlined"
                sx={continueBtn}
                component="label"
              >
                <PhotoCamera sx={photoIcon} />
                Upload Eligibility to Work
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={changeHandler}
                />
              </Button>
            )}
            {isApplied() && (
              <Box>
                Please upload the CRA letter indicating you are legally allowed
                to work in the{' '}
                <Box component="span" sx={{fontWeight: 'bold'}}>
                  SIN Section
                </Box>
                . This letter will include a 10 digit application number.
                <Box sx={{my: 1}}>
                  Selecting the Applied status means you do NOT have a valid
                  permit or sin but you have a letter from the CRA allowing you
                  to work
                </Box>
                <Box sx={{py: 1}} />
                <Button
                  id="submit-btn"
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={handleData}
                >
                  Confirm
                </Button>
              </Box>
            )}
            {tempStatus !== '' && !isApplied() && (
              <>
                <Box sx={{my: 1}}>
                  <Box sx={flexAlign}>
                    <Checkbox
                      sx={checkBoxLarge}
                      checked={checked}
                      onChange={handleCheckBox}
                    />
                    I hereby certify that, to the best of my knowledge, the
                    provided information is true and accurate.
                  </Box>
                </Box>
                <Button
                  id="submit-btn"
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={handleSubmit}
                  disabled={submitting || !checked}
                >
                  {submitting ? (
                    <CircularProgress
                      size="1.3rem"
                      sx={{color: 'primary.light', fontSize: '12px'}}
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </>
            )}
            <PopUp
              isOpen={popUp}
              onClose={closePopUp}
              message={message}
              status={status}
            />
          </Box>
        </PageWrapper>
      </Box>
    </>
  );
};

export const statusList = [
  'permanent resident',
  'citizenship',
  'work permit',
  'study permit',
  'applied status',
];
