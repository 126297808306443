import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from '@mui/material';
import {
  bottomIconContainer,
  bottomOuter,
  bottomPaper,
  bottonBarContainer,
  centerIcon,
  centerIconBase,
} from './BottomBarStyles';
import {
  QrCodeScanner,
  BarChart,
  PersonOutline,
  CalendarMonth,
} from '@mui/icons-material';
import {useLocation, useNavigate} from 'react-router-dom';
import DigistaffLogoIconWhite from './../../../../assets/images/DS-Logo.svg';

// Center icon for the bottom nav bar
export const CenterIcon = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    const ctrIcon = document.getElementsByClassName('center')[0];
    ctrIcon.classList.toggle('spin');

    setTimeout(() => navigate('/jobs'), 210);
  };

  return (
    <Box
      className="center"
      component="img"
      src={DigistaffLogoIconWhite}
      sx={centerIcon}
      onPointerUp={handleClick}
    />
  );
};

export const BottomBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path
      ? {fontWeight: 'bold', color: 'primary.main'}
      : {};
  };

  return (
    <Box sx={bottonBarContainer}>
      <Paper sx={bottomPaper} elevation={3}>
        <Box component="div" sx={bottomOuter}>
          <Box component="div" sx={centerIconBase} />
        </Box>
        <BottomNavigation
          showLabels
          sx={{borderTop: '1px solid', borderColor: 'secondary.main'}}
        >
          <BottomNavigationAction
            label="Checkin"
            icon={<QrCodeScanner />}
            sx={{...bottomIconContainer, ...isActive('/checkin')}}
            onPointerUp={() => navigate('/checkin')}
          />
          <BottomNavigationAction
            label="Schedule"
            icon={<CalendarMonth />}
            sx={{...bottomIconContainer, ...isActive('/schedule')}}
            onPointerUp={() => navigate('/schedule')}
          />
          <BottomNavigationAction icon={<CenterIcon />} />
          <BottomNavigationAction
            label="DigiScore"
            icon={<BarChart />}
            sx={{...bottomIconContainer, ...isActive('/digi-score')}}
            onPointerUp={() => navigate('/digi-score')}
          />
          <BottomNavigationAction
            label="Me"
            icon={<PersonOutline />}
            sx={{...bottomIconContainer, ...isActive('/user')}}
            onPointerUp={() => navigate('/user')}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};
