/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';

export const DocChecker = ({open, onClose, requiredDocs}: any) => {
  const navigate = useNavigate();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle textAlign="center">Required Documents</DialogTitle>
      <DialogContent>
        <Box sx={{textAlign: 'center', mb: 2, color: 'info.main'}}>
          Some of the required documents are missing. Please ensure that you
          have provided all the necessary documents.
        </Box>
        <Box sx={{maxHeight: '280px', overflowY: 'auto'}}>
          <Grid
            container
            spacing={2}
            sx={{width: '100%', mt: 0, ml: 0, pb: 0.5}}
          >
            {requiredDocs &&
              requiredDocs.map((doc?: any) => (
                <Grid
                  container
                  key={doc}
                  spacing={2}
                  alignItems="center"
                  sx={{margin: '0px', width: '100%'}}
                >
                  <Grid item xs={8}>
                    <>{docNameList[doc]}</>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`/document/${doc}/new`)}
                      sx={{width: '50px'}}
                    >
                      Upload
                    </Button>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const docNameList: any = {
  government: 'Government ID',
  sin: 'SIN Document',
  work: 'Work Eligibility Document',
};
