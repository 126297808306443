import {Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import {PasswordInputProps} from '../../../types/types';

export const PasswordInput = ({
  type,
  name,
  onChange,
  value,
  onClickIcon,
  placeholder,
  autoFocus,
  style,
  inputRef,
  tempPw,
  onKeyDown,
  id,
}: PasswordInputProps) => {
  return (
    <TextField
      id={id}
      autoFocus={autoFocus}
      type={type ? 'text' : 'password'}
      sx={
        style
          ? style
          : {
              width: '100%',
              backgroundColor: 'secondary.main',
              border: 'none',
              '& .MuiInput-input': {
                marginLeft: '10px',
              },
            }
      }
      name={name}
      placeholder={placeholder ? placeholder : 'Enter your password'}
      onChange={onChange}
      value={tempPw ? tempPw : value}
      inputRef={inputRef}
      onKeyDown={onKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onPointerUp={onClickIcon}>
              {type ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
