import {Box, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {CustomTextLoad} from '../../../../components/common/CustomTextLoad/CustomTextLoad';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {PopUp} from '../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../hooks/usePopUp';
import {
  getContactPreference,
  updateContactPreference,
} from '../../../../providers/api/contactPreference';
import {bottomSubPageLimit} from '../ProfileViewStyles';

import {
  ContactType,
  OptionType,
  OptionsType,
  PreferenceInterface,
} from '../../../../types/types';

export const ContactPreferenceView = () => {
  const [contact, setContact] = useState<ContactType>({label: '', value: ''});
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const staffId = localStorage.getItem('email');
  const [loading, setLoading] = useState(true);
  const options: OptionsType = useMemo(
    () => [
      {label: 'Push Notification', value: 'push'},
      {label: 'SMS', value: 'sms'},
      {label: 'Email', value: 'email'},
      {label: 'Phone Call', value: 'phone'},
    ],
    []
  );

  useEffect(() => {
    const fetchContactPreference = async () => {
      try {
        if (staffId) {
          const preference: PreferenceInterface = await getContactPreference(
            staffId
          );

          const matchedOption = options.find(
            option => option.value === preference.method
          );
          if (matchedOption) {
            setContact(matchedOption);
          }
        }
      } catch (err) {
        console.log(err);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchContactPreference().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [options, staffId]);

  const handleChange = async (option: OptionType) => {
    try {
      await updateContactPreference(staffId!, option);

      setContact({label: option.label, value: option.value});
      handlePopUp(
        'success',
        `You have changed your contact preference to ${option.label}`
      );
    } catch (err) {
      handlePopUp('error', 'Failed to update contact preference');
      console.log(err);
      console.log(err);
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Contact Preferences">
          <Box sx={{py: 2, px: 1}}>
            <Box sx={{pb: 1, fontWeight: 'bold'}}>Preferred Contact Method</Box>
            <Box sx={{px: 3}}>
              {loading ? (
                <Box
                  sx={{display: 'flex', flexDirection: 'column', gap: 3, mt: 2}}
                >
                  <CustomTextLoad height={14} />
                  <CustomTextLoad height={14} />
                  <CustomTextLoad height={14} />
                  <CustomTextLoad height={14} />
                </Box>
              ) : (
                <RadioGroup value={contact.value}>
                  {options.map((option: OptionType) => (
                    <FormControlLabel
                      key={option.value}
                      control={<Radio />}
                      label={option.label}
                      value={option.value}
                      checked={contact.value === option.value}
                      onChange={() => handleChange(option)}
                    />
                  ))}
                </RadioGroup>
              )}
            </Box>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        duration={2000}
        status={status}
        message={message}
      />
    </>
  );
};
