export const scrollLimit = {
  position: 'absolute',
  top: {xs: '58px', sm: '64px'},
  bottom: '56px',
  p: 1,
  overflowY: 'scroll',
  width: '100vw',
  maxWidth: '100%',
  backgroundColor: 'primary.dark',
};

export const boardContainer = {
  display: 'block',
  height: {xs: '280px', sm: '275px', md: '280px'},
  width: '100%',
  bgcolor: 'primary.light',
  color: 'grey.800',
  border: '1px solid',
  borderColor: 'grey.300',
  p: 2,
  position: 'relative',
  borderRadius: '8px',
  my: 0.5,
};

export const cardContainer = {display: 'flex', flexDirection: 'column'};

export const favIcon = {
  position: 'absolute',
  right: '15px',
  top: '20px',
  cursor: 'pointer',
};

export const businessLocation = {
  display: '-webkit-box',
  overflow: 'hidden',
  textTransform: 'capitalize',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
};

export const jobDesc = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 1,
  py: 1,
  cursor: 'pointer',
};

export const jobDescBox = {
  backgroundColor: '#E6F0F8',
  px: {xs: '3px', sm: '6px'},
  fontWeight: 'bold',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  fontSize: {xs: '0.6rem', sm: '0.8rem'},
};

export const chipIcon = {mr: '3px'};

export const jobNatureBox = {
  backgroundColor: 'success.light',
  px: {xs: '3px', sm: '6px'},
  fontWeight: 'bold',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  fontSize: {xs: '0.6rem', sm: '0.8rem'},
};

export const jobDescIcon = {p: '5px'};

export const jobActive = {
  fontSize: '0.8rem',
  color: 'secondary.dark',
  display: 'flex',
  alignItems: 'flex-end',
  height: '3rem',
  position: 'absolute',
  bottom: '15px',
  left: '15px',
};

export const descCount = {p: 0.5, fontSize: '0.8rem', color: 'secondary.dark'};

export const applyButton = {
  width: {xs: '6rem', sm: '8rem'},
  height: '2.5rem',
  backgroundColor: 'info.main',
  color: 'primary.light',
  border: 'none',
  boxShadow: 3,
  borderColor: 'info.main',
  fontSize: '1rem !important',
  fontWeight: 'bold',
  position: 'absolute',
  bottom: '15px',
  right: '15px',
  '&:hover': {
    color: 'info.main',
    borderColor: 'info.main',
  },
  borderRadius: '16px',
};

export const dropButton = {
  width: {xs: '6rem', sm: '8rem'},
  height: '2.5rem',
  backgroundColor: 'secondary.main',
  color: 'primary.main',
  border: 'none',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  position: 'absolute',
  bottom: '15px',
  right: '15px',
};

export const detailButton = {
  width: {xs: '6rem', sm: '8rem'},
  height: '2.5rem',
  backgroundColor: 'info.main',
  color: 'primary.light',
  border: 'none',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  position: 'absolute',
  bottom: '15px',
  left: '15px',
  '&:hover': {
    color: 'info.main',
    borderColor: 'info.main',
  },
  borderRadius: '16px',
};

export const jobInfoBox = {
  textTransform: 'capitalize',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: {xs: 1, sm: 2},
  WebkitBoxOrient: 'vertical',
  width: {xs: '230px', sm: '300px', md: '320px'},
  cursor: 'pointer',
};

export const skelContainer = {
  display: 'block',
  height: {xs: '280px', sm: '275px', md: '280px'},
  width: '100%',
  bgcolor: 'primary.light',
  color: 'grey.800',
  border: '3px solid',
  borderColor: 'grey.300',
  p: 2,
  my: 1,
  position: 'relative',
};

export const jobStatusBox = {
  position: 'absolute',
  bottom: '25px',
  right: '35px',
  textTransform: 'capitalize',
  fontSize: '16px',
  color: 'info.main',
};

export const infoJobTitle = {
  fontWeight: 'bold',
  textTransform: 'capitalize',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: {xs: '230px', sm: '300px', md: '500px'},
  cursor: 'pointer',
};

export const jobFeedEmptyBox = {
  height: '80vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  flexDirection: 'column',
};
