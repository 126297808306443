/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, IconButton, InputAdornment} from '@mui/material';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useCallback, useEffect, useRef, useState} from 'react';
import {ScrollLoader} from '../../../../components/common/Loader/ScrollLoader';
import {
  createMessage,
  fetchConversationMessages,
  updateMessage,
  updateMsgStatus,
} from '../../../../providers/api/chat';
import {getUserNameAndPic} from '../../../../providers/api/profile';
import {DSGQLSubscription} from '@digistaff/common';
import {getAppsyncStaffAPI, chatSubscriptionGQL} from '@digistaff/notification';
import {CustomDialog} from '../../../../components/common/Dialog/CustomDialog';
import {checkJobApplicationStatus} from '../../../../providers/api/shift';
import {usePopUp} from '../../../../hooks/usePopUp';
import {PopUp} from '../../../../components/common/PopUp/PopUp';
import {ShiftStatus} from '@digistaff/shift';
import {fetchJobDetails} from '../../../../providers/api/shiftPosting';
import {applyNewShift} from '../../../../providers/api/shift';
import {app} from '../../../../providers/Accounts';

import {
  MsgAppBar,
  MsgBoxLeft,
  MsgBoxRight,
  MsgInputContainer,
  MsgInputField,
  MsgInputWrapper,
} from './MsgComponents';
import {Send} from '@mui/icons-material';

export const MessageView = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const location = useLocation();
  const bottomRef = useRef<any>();
  const [dialog, setDialog] = useState(false);
  const [msg, setMsg] = useState<any>([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [inputMessage, setInputMessage] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [offsetTemp, setOffsetTemp] = useState(0);
  const [loadedAll, setLoadedAll] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [selectedMsg, setSelectedMsg] = useState<any>({});
  const staffId = localStorage.getItem('email');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [responseStates, setResponseStates] = useState<Record<string, string>>(
    {}
  );

  const loadMessages = useCallback(async () => {
    if (!loadedAll) {
      try {
        const limit = 25;
        const offset = offsetTemp * limit;
        const res = await fetchConversationMessages(id!, limit, offset);
        if (res && res.length !== 0) {
          let updatedMessages = res.map(async (message: any) => {
            if (message.sender_id !== staffId) {
              await updateMsgStatus(message);
              return {...message, status: 'seen'};
            }
            return message;
          });
          updatedMessages = await Promise.all(updatedMessages);
          const reversedMsg = updatedMessages.reverse();

          if (offset > 0) {
            setMsg((prev: any) => [...reversedMsg, ...prev]);
          } else {
            setMsg(reversedMsg);
          }
        } else {
          setLoadedAll(true);
        }
      } catch (err) {
        console.log(err);
        console.log(err);
      } finally {
        setLoadingPage(false);
      }
    }
  }, [loadedAll, offsetTemp, id, staffId]);

  const loadProfilePic = useCallback(async () => {
    try {
      const res = await getUserNameAndPic(staffId as string);
      setProfilePic(res?.avatar);
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  }, [staffId]);

  useEffect(() => {
    loadMessages().catch(err => {
      console.log(err);
      console.log(err);
    });
    loadProfilePic().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [offsetTemp, loadMessages, loadProfilePic]);

  useEffect(() => {
    if (autoScroll) {
      scrollToBottom();
    } else {
      return;
    }
  }, [msg.length, autoScroll]);

  useEffect(() => {
    const appsyncAPI = getAppsyncStaffAPI(app.environment);
    const gqlSub = new DSGQLSubscription(chatSubscriptionGQL, appsyncAPI, {
      id: id,
    });
    gqlSub.on(async (payload: any) => {
      let newMessage = payload.payload.data.onCreateChatMessage;
      if (newMessage.sender_id !== staffId) {
        await updateMsgStatus(newMessage);
        newMessage = {...newMessage, status: 'seen'};
        setMsg((prevMessages: any) => {
          return [...prevMessages, newMessage];
        });
      }
    });

    return () => {
      gqlSub.stop();
    };
  }, [id, staffId]);

  const scrollToBottom = () =>
    bottomRef?.current?.scrollIntoView({behavior: 'smooth'});

  const handleChange = (e: any) => setInputMessage(e.target.value);

  const handleSendMessage = async () => {
    const trimmedMessage = inputMessage.trim();
    if (!trimmedMessage) return;

    try {
      const res = await createMessage(id!, trimmedMessage, staffId!);
      setMsg([...msg, res]);
      setInputMessage('');
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const goToChat = () => navigate('/chat');

  const handleDialog = () => setDialog(!dialog);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement;
    if (element.scrollTop === 0) {
      setAutoScroll(false);
      setOffsetTemp(offsetTemp + 1);
    }
  };

  const handleReject = async (item: any) => {
    if (item.reference_response) {
      handlePopUp('info', 'You have already responded');
    } else {
      try {
        await updateMessage(item.id, 'no');
        handlePopUp('success', 'We have received your response');
        setResponseStates(prevState => ({...prevState, [item.id]: 'no'}));
      } catch (err) {
        console.log(err);
        console.log(err);
      }
    }
  };

  const handleApply = async (item: any) => {
    if (item.reference_response) {
      handlePopUp('info', 'You have already responded');
      return;
    }
    try {
      const appliedJob = await checkJobApplicationStatus(
        staffId!,
        item.reference_id
      );
      if (
        appliedJob[0]?.status === ShiftStatus.default ||
        appliedJob.length === 0
      ) {
        handleDialog();
        setSelectedMsg(item);
      } else {
        handlePopUp('info', 'You have already applied this job');
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const confirmApplication = async () => {
    try {
      const job = await fetchJobDetails(selectedMsg.reference_id);
      await applyNewShift(staffId!, job.id, job.startDate);
      await updateMessage(selectedMsg.id, 'yes');
      handlePopUp('success', 'You have successfully applied!');
      handleDialog();
      setResponseStates(prevState => ({...prevState, [selectedMsg.id]: 'yes'}));
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      !(event.ctrlKey || event.metaKey)
    ) {
      event.preventDefault();
      setInputMessage(prevMessage => prevMessage + '\n');
      return;
    }

    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      handleSendMessage().catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  };

  return (
    <>
      <MsgAppBar
        goToChat={goToChat}
        senderTitle={location?.state?.param1}
        avatarUrl={location?.state?.param2}
        handleDialog={handleDialog}
      />
      <Box
        sx={{
          position: 'absolute',
          top: {xs: '58px', sm: '64px'},
          bottom: '160px',
          p: 0,
          overflowY: 'scroll',
          backgroundColor: 'primary.dark',
        }}
        onScroll={handleScroll}
      >
        {loadingPage ? (
          <ScrollLoader />
        ) : (
          <Box sx={{pb: 2}}>
            {msg.map((item: any) => (
              <Box key={item.id}>
                {item.sender_id === staffId ? (
                  <MsgBoxRight item={item} profilePic={profilePic} />
                ) : (
                  <MsgBoxLeft
                    avatar={location?.state?.param2}
                    item={item}
                    responseStates={responseStates}
                    handleReject={handleReject}
                    handleApply={handleApply}
                  />
                )}
              </Box>
            ))}
            <div ref={bottomRef} />
          </Box>
        )}
      </Box>
      <MsgInputWrapper>
        <MsgInputContainer>
          <MsgInputField
            value={inputMessage}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Message"
            multiline
            minRows={1}
            maxRows={2}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onPointerUp={handleSendMessage}>
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MsgInputContainer>
      </MsgInputWrapper>
      <BottomBar />
      <CustomDialog
        open={dialog}
        close={handleDialog}
        title="Confirm apply the shift?"
        leftOnClick={handleDialog}
        rightOnClick={confirmApplication}
      />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        duration={1000}
        status={status}
        message={message}
      />
    </>
  );
};
