/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AccessTime,
  ArrowCircleUp,
  ArrowForward,
  Cancel,
  CheckCircle,
} from '@mui/icons-material';
import {Box, Divider} from '@mui/material';
import moment from 'moment';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {
  bottomSubPageLimit,
  docuArrowRight,
  docuDescBox,
  docuMandateTitle,
  docuSectionBox,
  docuTickBlank,
  docuTickGreen,
  docuTickOrange,
  docuTickRed,
  docuTickYellow,
  expiredText,
  expiryText,
  qualiInstruct,
  tickText,
} from '../../ProfileViewStyles';
import {CustomTextLoad} from '../../../../../components/common/CustomTextLoad/CustomTextLoad';
import {
  DocumentStatus,
  allMandatoryFromGQL,
  fetchStaffDocumentTenant,
  listMandatoryDocuments,
  workStatusMappingFromGQL,
} from '../../../../../providers/api/documents';
import {listStaffCertifications} from '../../../../../providers/api/certifications';
import {DocChecker} from '../../../../../components/common/DocChecker/DocChecker';
import {usePopUp} from '../../../../../hooks/usePopUp';
import {PopUp} from '../../../../../components/common/PopUp/PopUp';

import {useContracts} from '../../../../../providers/api/useContracts';

export const checkExpiry = (expiry: string) =>
  moment(expiry, 'YYYY-MM-DDTHH:mm:ss.SSSZ').diff(moment(), 'days');

export const DocumentsView = () => {
  const navigate = useNavigate();
  const [docList, setDocList] = useState<any>([]);
  const [certList, setCertList] = useState<any>([]);
  const [docLoad, setDocLoad] = useState(true);
  const [certLoad, setCertLoad] = useState(true);
  const [statusInHeader, setStatusInHeader] = useState('');
  const [certStatus, setCertStatus] = useState<Map<string, JSX.Element>>(
    new Map()
  );
  const [docChecker, setDocChecker] = useState(false);
  const [missingDocList, setMissingDocList] = useState<any>([]);
  const [hasMissingDoc, setHasMissingDoc] = useState(false);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const tenantId = localStorage.getItem('tenantId');
  const {listAllContracts} = useContracts();
  const [contractList, setContractList] = useState<any>([]);
  const [contractLoading, setContractLoading] = useState(true);

  useEffect(() => {
    const staffId = localStorage.getItem('email');
    const loadMandatoryDocuments = () => {
      listMandatoryDocuments(staffId!)
        .then(mandatoryDocs => {
          if (mandatoryDocs.length <= 2) {
            getMissingMandatoryDocs(mandatoryDocs);
            filterMandatoryDocs(mandatoryDocs);
          } else {
            filterMandatoryDocs(mandatoryDocs);
          }
        })
        .catch(err => {
          console.log(err);
        });
    };

    const loadCertifications = () => {
      listStaffCertifications(staffId!)
        .then(certDocs => setCertList(certDocs))
        .catch(err => {
          console.log(err);
        })
        .finally(() => setCertLoad(false));
    };

    loadMandatoryDocuments();
    loadCertifications();
  }, []);

  const getContracts = useCallback(async () => {
    const staffId = localStorage.getItem('email');
    const allContracts: any = await listAllContracts(staffId!);
    const filteredContracts = allContracts.filter((currContract: any) => {
      if (!currContract.employment_contract_snapshot) return false;

      const doc = JSON.parse(currContract.employment_contract_snapshot);

      if (doc.url) return currContract;
      else return false;
    });
    setContractList(filteredContracts);
    setContractLoading(false);
  }, [listAllContracts]);

  useEffect(() => {
    if (tenantId !== 'default') void getContracts();
  }, [getContracts, tenantId]);

  useEffect(() => {
    const checkStaffDocumentTenant = async (cert: any) => {
      try {
        const res = await fetchStaffDocumentTenant(cert.id);

        if (res.status === DocumentStatus.rejected) {
          return updateCertStatus(cert.id, <Cancel sx={docuTickRed} />);
        } else if (res.status === DocumentStatus.approved) {
          if (checkExpiry(cert?.expiration) < 30) {
            return updateCertStatus(
              cert.id,
              <AccessTime sx={docuTickOrange} />
            );
          } else {
            return updateCertStatus(
              cert.id,
              <CheckCircle sx={docuTickGreen} />
            );
          }
        } else if (res.status === DocumentStatus.pending) {
          return updateCertStatus(
            cert.id,
            <ArrowCircleUp sx={docuTickYellow} />
          );
        } else if (res.status === DocumentStatus.resubmit) {
          return updateCertStatus(cert.id, <Cancel sx={docuTickYellow} />);
        } else {
          return updateCertStatus(cert.id, <AccessTime sx={docuTickBlank} />);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setDocLoad(false);
      }
    };

    const checkCertStatus = async (cert: any) => {
      try {
        if (tenantId === 'default') {
          const statusIcon = (
            <AccessTime sx={{...docuTickBlank, color: 'primary.light'}} />
          );
          updateCertStatus(cert.id, statusIcon);
          setDocLoad(false);
          return;
        }

        await checkStaffDocumentTenant(cert);
      } catch (err) {
        console.log(err);
      }
    };

    docList.forEach((cert: any) => checkCertStatus(cert));
    certList.forEach((cert: any) => checkCertStatus(cert));
  }, [docList, certList, tenantId]);

  const filterMandatoryDocs = (mandatoryDocs: any) => {
    const workStatus = mandatoryDocs.filter((doc: any) => {
      return doc.type.startsWith('mandatory_work');
    });

    workStatus.length !== 0 &&
      setStatusInHeader(workStatusMappingFromGQL[workStatus[0].type]);

    setDocList(mandatoryDocs);
  };

  const updateCertStatus = (certId: string, statusIcon: JSX.Element) => {
    setCertStatus(prevStatus => new Map(prevStatus).set(certId, statusIcon));
  };

  const getMissingMandatoryDocs = (docs: any) => {
    const missingMandatoryDocs: any = [];

    allMandatoryDocType.forEach(docType => {
      const docExists = docs.some((doc: any) => {
        const docTypeParts = doc.type.split('_');
        return docTypeParts[1] === docType;
      });

      if (!docExists) {
        missingMandatoryDocs.push(docType);
      }
    });

    setDocLoad(false);
    setMissingDocList(missingMandatoryDocs);
    setDocChecker(true);
    setHasMissingDoc(true);
  };

  const goToCert = () => {
    if (certLoad) {
      return;
    }
    navigate('/documents/certifications');
  };

  const goToContracts = () => {
    if (tenantId !== 'default') navigate('/documents/contracts');
  };

  const goToIdent = () => {
    if (hasMissingDoc) {
      handlePopUp('info', 'Please upload the missing document');
      setDocChecker(true);
      return;
    }
    if (docLoad) {
      return;
    }
    navigate('/documents/identities', {state: docList});
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="My Documents">
          <Box sx={qualiInstruct}>
            {docLoad ? (
              <CustomTextLoad height={14} />
            ) : (
              <>
                {statusInHeader && (
                  <Box sx={{display: 'inline', textTransform: 'capitalize'}}>
                    Your current work status is{' '}
                    <span style={{fontWeight: 'bold', color: '#3E82BF'}}>
                      {statusInHeader}
                    </span>
                  </Box>
                )}
              </>
            )}
            <Box sx={{color: 'secondary.dark'}}>
              You can go to Mandatory section below to change your work status
              in Eligibility to Work
            </Box>
          </Box>
          <Box sx={{py: 1}} />
          <Box sx={docuSectionBox}>
            <Box sx={docuMandateTitle} onClick={goToIdent}>
              Mandatory
              <ArrowForward sx={docuArrowRight} />
            </Box>
            <Divider />
            <Box sx={docuDescBox}>
              {docLoad ? (
                <>
                  <CustomTextLoad height={20} mb={10} />
                  <CustomTextLoad height={20} mb={10} />
                  <CustomTextLoad height={20} mb={10} />
                </>
              ) : (
                <>
                  {docList.length !== 0 &&
                    docList.map((cert: any) => (
                      <Box sx={tickText} key={cert.id}>
                        {allMandatoryFromGQL[cert.type]}
                        {checkExpiry(cert.expiration) < 0 ? (
                          <Box component="span" sx={expiredText}>
                            {`Expired (${Math.abs(
                              checkExpiry(cert.expiration)
                            )} ${
                              Math.abs(checkExpiry(cert.expiration)) === 1
                                ? 'day'
                                : 'days'
                            } ago)`}
                          </Box>
                        ) : checkExpiry(cert.expiration) < 30 ? (
                          <Box component="span" sx={expiryText}>
                            {`Expiring soon (${checkExpiry(cert.expiration)} ${
                              checkExpiry(cert.expiration) === 1
                                ? 'day'
                                : 'days'
                            })`}
                          </Box>
                        ) : null}
                        <>{certStatus.get(cert.id)}</>
                      </Box>
                    ))}
                </>
              )}
            </Box>
          </Box>
          <Box sx={docuSectionBox}>
            <Box sx={docuMandateTitle} onPointerUp={goToCert}>
              Certifications
              <ArrowForward sx={docuArrowRight} />
            </Box>
            <Divider />
            <Box sx={docuDescBox}>
              {certLoad ? (
                <>
                  <CustomTextLoad height={20} mb={10} />
                  <CustomTextLoad height={20} mb={10} />
                  <CustomTextLoad height={20} mb={10} />
                </>
              ) : (
                <>
                  {certList.map((cert: any) => (
                    <Box sx={tickText} key={cert.id}>
                      {cert.idName}
                      <>{certStatus.get(cert.id)}</>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Box>
          <Box sx={docuSectionBox} onPointerUp={goToContracts}>
            <Box sx={docuMandateTitle}>
              Contracts
              {tenantId !== 'default' && <ArrowForward sx={docuArrowRight} />}
            </Box>
            <Divider />
            {tenantId !== 'default' ? (
              <Box sx={docuDescBox}>
                {contractLoading ? (
                  <>
                    <CustomTextLoad height={20} mb={10} />
                    <CustomTextLoad height={20} mb={10} />
                    <CustomTextLoad height={20} mb={10} />
                  </>
                ) : (
                  <>
                    {contractList.map((contract: any) => (
                      <Box sx={tickText} key={contract.id}>
                        <>{contract.nestGetEmploymentContract.name}</>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            ) : (
              <Box sx={docuDescBox}>
                Please select a profile to view your signed contracts
              </Box>
            )}
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
      <DocChecker
        open={docChecker}
        onClose={() => setDocChecker(false)}
        requiredDocs={missingDocList}
      />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        duration={5000}
        status={status}
        message={message}
      />
    </>
  );
};

export const allMandatoryDocType = ['government', 'sin', 'work'];
