/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, Divider, Modal} from '@mui/material';
import {DateRange, RangeKeyDict} from 'react-date-range';
import {
  accumulationBox,
  pendingBox,
  vacaInstruBox,
  vacationCxl,
  vacationSinceBox,
  chequeEmptyBox,
  vacationBoxTitle,
} from '../../../ProfileViewStyles';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {useEffect, useState} from 'react';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import moment from 'moment';
import {VacationModalBox} from './VacationModalBox';
import {
  cancelVacation,
  createNewVacation,
  fetchStaffVacation,
  fetchVacationBlackout,
  fetchVacationCount,
} from '../../../../../../providers/api/vacation';
import {CustomTextLoad} from '../../../../../../components/common/CustomTextLoad/CustomTextLoad';

const defaultDateRange = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  },
];

export const EmployeeVacationView = () => {
  const {handlePopUp, popUp, setPopUp, status, message} = usePopUp();
  const [modal, setModal] = useState(false);
  const [dateRange, setDateRange] = useState<any[]>(defaultDateRange);
  const [requested, setRequested] = useState({
    id: '',
    start: '',
    end: '',
    status: '',
  });
  const [vacationSummary, setVacationSummary] = useState<any>({});
  const [vacationCount, setVacationCount] = useState(0);
  const [staffJoin, setStaffJoin] = useState('');
  const [loading, setLoading] = useState(true);
  const [disabledDates, setDisabledDates] = useState<any>([]);
  const [noAgent, setNoAgent] = useState(false);

  useEffect(() => {
    const tenantId = localStorage.getItem('tenantId');
    if (tenantId === 'default') {
      setNoAgent(true);
      setLoading(false);
    } else {
      loadVocationCount().catch(err => {
        console.log(err);
        console.log(err);
      });
      loadStaffVacation().catch(err => {
        console.log(err);
        console.log(err);
      });
      loadVacationBlackout().catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  }, []);

  const loadVocationCount = async () => {
    try {
      const {vcSummary, staffCreated}: any = await fetchVacationCount();
      const vacationBalance =
        vcSummary.accumulated_days - vcSummary.consumed_days;
      setVacationSummary(vcSummary);
      setVacationCount(vacationBalance);
      setStaffJoin(staffCreated);
    } catch (err) {
      console.log(err);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const loadStaffVacation = async () => {
    try {
      const res = await fetchStaffVacation();
      if (res.length !== 0) {
        setRequested({
          id: res[0].id,
          start: moment(res[0].start_date).format('DD MMM YYYY'),
          end: moment(res[0].end_date).format('DD MMM YYYY'),
          status: res[0].status,
        });
      }
    } catch (err: any) {
      console.log(err);
      console.log(err);
    }
  };

  const loadVacationBlackout = async () => {
    try {
      const res: any = await fetchVacationBlackout();
      setDisabledDates(res);
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  // 14 days per week-block prior to staff's request
  const earliestDate = new Date(
    new Date().getTime() + 14 * 24 * 60 * 60 * 1000
  );

  const handleModal = () => setModal(!modal);

  const closePopUp = () => setPopUp(!popUp);

  const handleSelection = (ranges: RangeKeyDict) =>
    setDateRange([ranges.selection]);

  const confirmSub = () => {
    const newVacationStartDate = new Date(dateRange[0].startDate).toISOString();
    const newVacationEndDate = new Date(dateRange[0].endDate).toISOString();

    createNewVacation(newVacationStartDate, newVacationEndDate)
      .then((res: any) => {
        setRequested({
          id: res.id,
          start: moment(res.start_date).format('DD MMM YYYY'),
          end: moment(res.end_date).format('DD MMM YYYY'),
          status: res.status,
        });
        setDateRange(defaultDateRange);
        handlePopUp(
          'success',
          'Your request has been submitted. Please check approval status later'
        );
      })
      .catch(err => {
        console.log(err);
        console.log(err);
      });
  };

  const handleSubmit = () => {
    const selectedTimeRange =
      Math.ceil(
        (dateRange[0].endDate.getTime() - dateRange[0].startDate.getTime()) /
          (1000 * 3600 * 24)
      ) + 1;

    if (vacationCount !== 0 && selectedTimeRange <= vacationCount) {
      confirmSub();
    } else {
      handlePopUp(
        'error',
        'You do not have sufficient balance for your vacation request'
      );
    }
  };

  const handleCancel = () => {
    cancelVacation(requested.id)
      .then(() => {
        setRequested({
          id: '',
          start: '',
          end: '',
          status: '',
        });
        handlePopUp('success', 'Your pending vacation has been cancelled');
        handleModal();
      })
      .catch(err => {
        console.log(err);
        console.log(err);
      });
  };

  const showPlural = (count: number) => (count === 1 ? 'day' : 'days');

  return (
    <>
      {loading ? (
        <Box sx={{px: 1}}>
          <CustomTextLoad height={150} fullWidth mb={30} />
          <CustomTextLoad height={450} fullWidth />
        </Box>
      ) : noAgent ? (
        <Box sx={chequeEmptyBox}>
          You currently do not associate with an agency yet or you are currently
          in DigiJobs. Switch Profile and request vacation with an agency
        </Box>
      ) : (
        <>
          <Box sx={{px: 1}}>
            <Box sx={accumulationBox}>
              <Box sx={vacationBoxTitle}>
                Available Vacation: {vacationCount} {showPlural(vacationCount)}
              </Box>
              <Box sx={{mx: 2}}>
                <Box>
                  Accumulated: {vacationSummary.accumulated_days}{' '}
                  {showPlural(vacationSummary.accumulated_days)}
                </Box>
                <Box>
                  Consumed: {vacationSummary.consumed_days}{' '}
                  {showPlural(vacationSummary.consumed_days)}
                </Box>
              </Box>
              <Box sx={vacationSinceBox}>
                {staffJoin === null
                  ? 'Status for approval: PENDING'
                  : `Joined since: ${moment(staffJoin).format('DD MMM YYYY')}`}
              </Box>
            </Box>
            {Object.values(requested)[0].length !== 0 && (
              <Box sx={accumulationBox}>
                <Box sx={vacationBoxTitle}>
                  Vacation Request: {requested.start}
                  {' - '}
                  {requested.end}
                </Box>
                <Box sx={pendingBox}>Status: {requested.status}</Box>
                <Button
                  variant="outlined"
                  sx={vacationCxl}
                  onPointerUp={handleModal}
                >
                  cancel
                </Button>
              </Box>
            )}
          </Box>
          <Divider sx={{my: 2}} />
          <Box sx={{px: 1}}>
            <Box sx={vacaInstruBox}>
              You can request vacation 2 weeks prior to today.
            </Box>
            <Box sx={{width: '100%', mt: '-30px'}}>
              <DateRange
                minDate={earliestDate}
                rangeColors={['primary.main']}
                editableDateInputs
                moveRangeOnFirstSelection
                ranges={dateRange}
                onChange={handleSelection}
                disabledDates={disabledDates}
              />
            </Box>
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={handleSubmit}
              disabled={
                Object.values(requested)[0].length > 0 ||
                moment(dateRange[0].startDate).isSame(moment(), 'day')
              }
            >
              submit
            </Button>
          </Box>
          <Box sx={{my: 2}} />
          <PopUp
            isOpen={popUp}
            onClose={closePopUp}
            message={message}
            status={status}
          />
          <Modal open={modal} onClose={handleModal}>
            <>
              <VacationModalBox
                handleModal={handleModal}
                handleCancel={handleCancel}
              />
            </>
          </Modal>
        </>
      )}
    </>
  );
};
