/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, Grow, TextField} from '@mui/material';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {
  accTextBox,
  authContainer,
  continueBtn,
  createAccContainer,
  descContainer,
  emailAddrBox,
  emailError,
  emailSignUp,
  iframeBox,
  primaryTitleBox,
  secondTitleBox,
  textFieldSignIn,
  userScrollLimit,
} from './LandingViewStyles';
import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';
import {
  app,
  getCurrentUser,
  signInWithEmailAndPassword,
} from '../../../providers/Accounts';
import {digiStaffLogo} from '../../../components/common/Image/Image';

import {checkOnboardingStatus} from '../../../providers/api/profile';
import validator from 'validator';

export const LandingView = () => {
  const [formError, setFormError] = useState({email: ''});
  const emailRef = useRef<HTMLInputElement>();
  const navigate = useNavigate();
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const pwTemp = 'tdsa212empPasswrd'; // temp pw for cognito account validation
  const fullyOnboard = 10;

  // Detect user login status
  // If user logged in before, redirect user to job feed
  useEffect(() => {
    const autoLogin = async () => {
      try {
        const userAuthStatus = await getCurrentUser(app);
        const onboardingStatus = await checkOnboardingStatus();
        if (
          userAuthStatus?.code === 'DS_USER_AUTHENTICATED' &&
          onboardingStatus === fullyOnboard
        ) {
          navigate('/jobs');
        }
      } catch (error) {
        return;
      }
    };
    autoLogin().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [navigate]);

  const validateField = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value.toLowerCase();
    const result = validator.isEmail(value);

    !result
      ? setFormError({
          ...formError,
          email: 'Please provide a valid email address',
        })
      : setFormError({...formError, email: ''});
  };

  const handleKeyDown = (e: {key: string}) =>
    e.key === 'Enter' && !formError.email && handleContinue();

  const setEmailStorage = (email: string) => {
    const loweredEmail = email.toLowerCase();
    localStorage.setItem('email', loweredEmail);
    sessionStorage.setItem('email', loweredEmail);
  };

  const goToLogin = (email: string) => {
    setEmailStorage(email);
    navigate('/login');
  };

  // Use random pw to detect if user had registered and redirect to corresponding page
  // If registered, redirect to login page
  // If not, redirect to registration process
  const handleContinue = async () => {
    const email = emailRef.current!.value.toLowerCase();

    if (!email) {
      handlePopUp('error', 'Please enter your email');
      return;
    }

    try {
      await signInWithEmailAndPassword(app, email, pwTemp);
    } catch (err: any) {
      switch (err?.err?.name) {
        case 'UserNotFoundException':
          setEmailStorage(email);
          navigate('/terms');
          break;
        case 'NotAuthorizedException':
          goToLogin(email);
          break;
        default:
          console.log(err);
          console.log(err);
      }
    }
  };

  return (
    <>
      <Navbar signUp landing />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
      <Box sx={userScrollLimit}>
        <Grow in unmountOnExit>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              maxWidth: '100%',
              overFlow: 'hidden',
              bottom: '0',
              width: '100vw',
            }}
          >
            <Box sx={createAccContainer}>
              <Box sx={accTextBox}>Create Account / Sign In</Box>
            </Box>
            <Box sx={descContainer}>
              <Box sx={primaryTitleBox}>Ready to take the next step?</Box>
              <Box sx={secondTitleBox}>Create an account or sign in</Box>
              <Box sx={iframeBox}>
                <Box
                  component="img"
                  src={digiStaffLogo}
                  sx={{height: 'auto', width: '100%', objectFit: 'contain'}}
                />
              </Box>
            </Box>
            <Box sx={authContainer}>
              <Box sx={emailSignUp}>
                <Box sx={emailAddrBox}>
                  Email Address*
                  {formError.email && (
                    <Box sx={emailError}>{formError.email}</Box>
                  )}
                </Box>
                <Box sx={{width: '100%', pb: 1}}>
                  <TextField
                    type="email"
                    sx={textFieldSignIn}
                    inputRef={emailRef}
                    onChange={e => validateField(e)}
                    onKeyDown={handleKeyDown}
                    name="email"
                  />
                </Box>
                <Box sx={{height: {xs: '0.5rem', sm: '1rem'}}} />
                <Button
                  id="anchorBtn"
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={handleContinue}
                  disabled={formError.email !== ''}
                  name="login"
                >
                  Continue →
                </Button>
              </Box>
            </Box>
          </Box>
        </Grow>
      </Box>
    </>
  );
};
