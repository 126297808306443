export const imageList = {
  gridAutoFlow: 'column',
  gridTemplateColumns: 'repeat(auto-fill,minmax(80px,1fr)) !important',
  gridAutoColumns: 'minmax(80px, 1fr)',
  '&::-webkit-scrollbar': {display: 'none'},
};

export const dateContainer = {
  height: '60px',
  borderRadius: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
};

export const monthBox = {fontSize: '0.7rem', color: 'secondary.dark'};

export const dateBox = {fontSize: '18px', fontWeight: 'bold'};

export const dateDisabledBox = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: 'secondary.main',
};

export const dayBox = {fontSize: '0.7rem', color: 'secondary.dark'};

export const selectedBox = {
  backgroundColor: 'info.main',
  color: 'primary.light',
  borderRadius: '50%',
  width: '27px',
  display: 'flex',
  justifyContent: 'center',
};
