/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {getCertification, listCertifications} from '@digistaff/business';
import {
  createStaffDocument,
  createStaffDocumentTenant,
  deleteStaffDocument,
  listStaffDocuments,
  updateStaffDocument,
  updateStaffDocumentTenant,
} from '@digistaff/staff';
import {app, setTenantKey} from '../Accounts';
import {DocumentStatus} from './documents';

import {withDefaultTenant} from './withTenant';
import {handleCustomError} from '../../views/RouteErrorView/RouteErrorView';

export const listCertificationNameAndID = async () => {
  try {
    const response = await listCertifications(app);

    return response.data.listCertifications;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchAllCertifications = withDefaultTenant(
  listCertificationNameAndID
);

export const listStaffCertifications = async (staffId: string) => {
  try {
    const filterCriteria = {
      staff_id: staffId,
      type: {operator: 'like', value: 'certification%'},
    };

    const response = await listStaffDocuments(app, {
      filter: filterCriteria,
    });

    const allDocPromises = response.data.listStaffDocuments.map(
      async (doc: any) => {
        const custom = JSON.parse(doc.custom);

        const doc_id = doc.type.split('_')[1];

        const res: any = await getCertification(app, doc_id);

        return {
          ...doc,
          name: custom.name,
          idName: res?.data?.getCertification?.name,
        };
      }
    );

    const allDoc = await Promise.all(allDocPromises);

    return allDoc;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchAllStaffCertifications = withDefaultTenant(
  listStaffCertifications
);

export const createStaffCertificationInTenants = async (
  certIdInDefaultTenant: string,
  cert: any,
  staffId: string,
  tenantId: string,
  currentTenantId: string
) => {
  const currentTenant = currentTenantId;

  try {
    setTenantKey(app, tenantId);
    const formattedType = `certification_${cert.id}`;
    const res = await createStaffDocumentTenant(app, {
      id: certIdInDefaultTenant,
      type: formattedType,
      staff_id: staffId,
      status: DocumentStatus.pending,
    });
    setTenantKey(app, currentTenant);
    return res;
  } catch (err) {
    console.log(err);
    console.log(err);
    setTenantKey(app, currentTenant);
    return Promise.reject(err);
  }
};

export const createStaffCertification = async (
  staffId: string,
  cert: any,
  docNo: string,
  expiry: any,
  certPhoto: string
) => {
  try {
    const formattedType = `certification_${cert.id}`;

    const payload = {
      staff_id: staffId,
      type: formattedType,
      expiration: expiry,
      reference_id: docNo,
      file_url: certPhoto,
      custom: JSON.stringify({name: cert.name}),
    };

    return await createStaffDocument(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createStaffCertificationInDefaultTenant = withDefaultTenant(
  createStaffCertification
);

export const getStaffCertification = async () => {
  try {
    return;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateStaffCertification = async (
  certId: string,
  certPhoto: string,
  expiryDate: any
) => {
  try {
    const payload = {
      file_url: certPhoto,
      expiration: expiryDate ?? null,
    };
    return await updateStaffDocument(app, certId, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateStaffCertificationInDefaultTenant = withDefaultTenant(
  updateStaffCertification
);

export const updateStaffCertificationInTenant = async (
  certId: string,
  currentTenantId: string,
  tenantId: string
) => {
  const currentTenant = currentTenantId;
  try {
    setTenantKey(app, tenantId);
    const payload = {
      status: DocumentStatus.pending,
    };
    const res = await updateStaffDocumentTenant(app, certId, payload);
    setTenantKey(app, currentTenant);
    return res;
  } catch (err) {
    setTenantKey(app, currentTenant);
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const deleteStaffCertification = async (docId: string) => {
  try {
    return await deleteStaffDocument(app, docId);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const checkStaffCertification = async (
  staffId: string,
  cert_name: string
): Promise<boolean> => {
  try {
    const filterCriteria = {
      staff_id: staffId,
      type: {operator: 'like', value: 'certification%'},
    };

    const response = await listStaffDocuments(app, {
      filter: filterCriteria,
    });

    const allDoc = response.data.listStaffDocuments.map((doc: any) => {
      const custom = JSON.parse(doc.custom);

      return {
        name: custom.name,
      };
    });

    return allDoc.some((item: any) => item.name === cert_name);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const checkStaffCertificationInDefault = withDefaultTenant(
  checkStaffCertification
);

export const fetchCertification = async (certId: string) => {
  try {
    const res: any = await getCertification(app, certId);

    return res.data.getCertification;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  }
};
