import {Box} from '@mui/material';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {bottomSubPageLimit} from '../ProfileViewStyles';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

export const ToBeDevelopedView = () => {
  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IntegrationInstructionsIcon sx={{fontSize: '36px', mr: 1}} />
          <Box sx={{fontSize: '36px'}}>To Be Developed!</Box>
        </Box>
      </Box>
      <BottomBar />
    </>
  );
};
