/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Divider, FormControl, FormLabel} from '@mui/material';
import {LocationFilterField} from '../../../../components/common/LocationFilterField/LocationFilterField';

import {formContainer, formLabel} from '../DropDownViewStyles';

const locations = [
  {city: 'Toronto, ON'},
  {city: 'North York, ON'},
  {city: 'Brampton, ON'},
  {city: 'Mississauga, ON'},
  {city: 'Thornhill, ON'},
  {city: 'GTA, ON'},
  {city: 'Vaughan, ON'},
  {city: 'Hamilton, ON'},
];

export const LocationFilter = ({sortValue, setSortValue}: any) => {
  return (
    <FormControl sx={formContainer}>
      <FormLabel sx={formLabel}>Location</FormLabel>
      <Box sx={{py: 2, position: 'relative', height: '90px'}}>
        <LocationFilterField
          sortValue={sortValue}
          setSortValue={setSortValue}
          locations={locations}
        />
      </Box>
      <Divider />
    </FormControl>
  );
};
