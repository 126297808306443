/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {listBusinessIndustries} from '@digistaff/business';
import {
  createStaffExperience,
  createStaffSkill,
  deleteStaffExperience,
  deleteStaffSkill,
  listStaffExperience,
  listStaffSkills,
} from '@digistaff/staff';
import {app} from '../Accounts';

export const fetchStaffExperienceList = async (staffId: string) => {
  try {
    const filterCriteria = {staff_id: staffId};
    const res: any = await listStaffExperience(app, {filter: filterCriteria});
    const expListLoaded = res.data.listStaffExperiences;
    const industries = await fetchBusinessIndustry();

    const expList = expListLoaded.map((exp: any) => {
      const formattedIndustry = industries.find(
        (ind: any) => ind.id === exp.business_industry_id
      );
      return {
        id: exp.id,
        jobTitle: exp.job_title,
        company: exp.company,
        industryID: exp.business_industry_id,
        industryName: formattedIndustry.name,
        start: exp.start_date,
        end: exp.end_date,
        create_at: exp.create_at,
        update_at: exp.update_at,
      };
    });

    return expList;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchStaffSkillsList = async (staffId: string) => {
  const filterCriteria = {staff_id: staffId};
  const res: any = await listStaffSkills(app, {filter: filterCriteria});
  return res.data.listStaffSkills;
};

export const fetchBusinessIndustry = async () => {
  try {
    const industryRes = await listBusinessIndustries(app);
    const industryRetrieved = industryRes.data.listBusinessIndustries.map(
      (industry: any) => ({
        id: industry.id,
        name: industry.name,
      })
    );
    return industryRetrieved;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createStaffWorkExp = async (
  staffId: string,
  jobTitle: string,
  company: string,
  industry: string,
  expBegin: string,
  expEnd: string
) => {
  const formattedStart = new Date();
  const formattedEnd = new Date();

  try {
    formattedStart.setFullYear(Number(expBegin));
    formattedStart.setMonth(0);
    formattedStart.setDate(1);

    formattedEnd.setFullYear(Number(expEnd));
    formattedEnd.setMonth(0);
    formattedEnd.setDate(1);

    const payload = {
      staff_id: staffId,
      job_title: jobTitle,
      company: company,
      business_industry_id: industry,
      start_date: formattedStart.toISOString(),
      end_date: formattedEnd.toISOString(),
    };

    return await createStaffExperience(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createStaffWorkSkill = async (
  staffId: string,
  skillTitle: string,
  exp: number
) => {
  const payload = {
    staff_id: staffId,
    name: skillTitle,
    duration_years: exp,
  };
  try {
    return await createStaffSkill(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateStaffWorkExp = async () => {};

export const updateStaffWorkSkill = async () => {};

export const deleteStaffWorkExp = async (expId: string) => {
  try {
    return await deleteStaffExperience(app, expId);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const deleteStaffWorkSkill = async (skillId: string) => {
  try {
    return await deleteStaffSkill(app, skillId);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};
