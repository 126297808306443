import {AlertColor} from '@mui/material';
import {useState} from 'react';

export const usePopUp = () => {
  const [popUp, setPopUp] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<AlertColor>('success');

  const handlePopUp = (status: AlertColor, message: string) => {
    setPopUp(true), setStatus(status), setMessage(message);
  };

  const closePopUp = () => setPopUp(false);

  return {handlePopUp, closePopUp, popUp, setPopUp, status, message};
};
