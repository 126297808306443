/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button} from '@mui/material';
import {useEffect, useState} from 'react';
import moment, {Moment} from 'moment';
import {usePopUp} from '../../../hooks/usePopUp';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {contentBgLayout} from '../../Private/ProfileView/ProfileViewStyles';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {continueBtn, userScrollLimit} from '../LandingView/LandingViewStyles';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {useNavigate} from 'react-router-dom';
import {
  certBox,
  certCardBox,
  certCardDesc,
  certRemove,
  noCertBox,
  statusTitle,
  whiteBtn,
} from './RegistrationStyles';
import {useFileUpload} from '../../../hooks/useFileUpload';
import {
  createStaffCertification,
  deleteStaffCertification,
  listStaffCertifications,
} from '../../../providers/api/certifications';
import {updateStaffProfile} from '@digistaff/staff';
import {app} from '../../../providers/Accounts';

import {checkOnboardingStatus} from '../../../providers/api/profile';

export const CertificationView = () => {
  const [cert, setCert] = useState({});
  const [calendar, setCalendar] = useState<Moment | null>(null);
  const [forkCat, setForkCat] = useState('');
  const [docNo, setDocNo] = useState('');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [certArray, setCertArray] = useState<any>([]);
  const [drawer, setDrawer] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const staffId = sessionStorage.getItem('email');
  const [removeDoc, setRemoveDoc] = useState(false);
  const {handleFileUpload, changeHandler, selectedFile, fileSelected} =
    useFileUpload();
  const [certLoading, setCertLoading] = useState(false);
  const [loadingCertList, setLoadingCertList] = useState(true);

  useEffect(() => {
    navigate('/info/emergency');
  }, []);

  // useEffect(() => {
  //   fetchCertificationList();
  // }, [drawer, removeDoc]);

  const fetchCertificationList = async () => {
    if (staffId) {
      try {
        const retrievedCert = await listStaffCertifications(staffId);
        setCertArray(retrievedCert);
      } catch (err) {
        console.log(err);
        console.log(err);
      } finally {
        setLoadingCertList(false);
      }
    }
  };

  const handleDrawer = () => setDrawer(!drawer);

  const handleCertChange = (newValue: any) => setCert(newValue);

  const handleForkCert = (newValue: string) => setForkCat(newValue);

  const handleCalChange = (newValue: Moment | null) => setCalendar(newValue);

  const handleDocChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDocNo(e.target.value);

  const expiryDateOK = () =>
    calendar && moment(calendar, 'DD/MM/YYYY').isAfter(moment(), 'day');

  const certNotCompleted = () => !cert || !fileSelected || !docNo;

  const addCert = async () => {
    setCertLoading(true);
    try {
      const uploaded: any = await handleFileUpload(staffId!);
      const certPhoto = uploaded.data.result.Location;
      createStaffCertification(staffId!, cert, docNo, calendar, certPhoto)
        .then(() => closeDrawer())
        .catch(err => {
          console.log(err);
          console.log(err);
          handlePopUp('error', 'Unable to upload document');
        })
        .finally(() => setCertLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemove = (docNo: string) => {
    setRemoveDoc(!removeDoc);
    deleteStaffCertification(docNo).catch(err => {
      console.log(err);
      console.log(err);
    });
    const newCertArray = certArray.filter((cert: any) => cert.id !== docNo);
    setCertArray(newCertArray);
  };

  const clearField = () => {
    setCert({});
    setCalendar(null);
    setForkCat('');
    setImageUrl('');
    setDocNo('');
    setError('');
  };

  const closeDrawer = () => {
    handlePopUp(
      'success',
      'Your documents have been uploaded for verification.'
    );
    clearField();
    setTimeout(() => handleDrawer(), 1000);
  };

  const handleSave = () => {
    if (expiryDateOK()) {
      addCert().catch(err => {
        console.log(err);
        console.log(err);
      });
    } else {
      setError('Expiry date should be greater than today');
    }
  };

  const handleNext = async () => {
    try {
      const onboardingStatus = await checkOnboardingStatus();
      if (onboardingStatus === 9) {
        navigate('/review');
      } else {
        await updateStaffProfile(app, staffId!, {current_onboarding_step: 8});
        navigate('/info/emergency');
      }
    } catch (error) {
      console.log(error);
      console.log(error);
    }
  };

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/info/id" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Upload Certifications">
          <Box sx={{px: 2, py: 1}}>
            <Box sx={contentBgLayout}>
              {certArray.length === 0 ? (
                <Box sx={noCertBox}>
                  <Box>You don&apos;t have any certificate added yet.</Box> If
                  you don&apos;t have any certificate to upload at the moment,
                  press next to continue
                </Box>
              ) : (
                <Box sx={certBox}>
                  {certArray.map((c: any) => (
                    <Box sx={certCardBox} key={c.id}>
                      <Box sx={statusTitle}>{c.custom}</Box>
                      <Box
                        sx={certRemove}
                        onPointerUp={() => handleRemove(c.id)}
                      >
                        Remove
                      </Box>
                      <Box sx={certCardDesc}>Document ID: {c.reference_id}</Box>
                      <Box sx={certCardDesc}>
                        Expiration: {moment(c.expiration).format('DD MMM YYYY')}
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            <Box
              sx={{display: 'flex', flexDirection: 'column', gap: 2, mt: 2}}
              onPointerUp={handleDrawer}
            >
              <Button variant="outlined" sx={whiteBtn}>
                Add Certificate
              </Button>
              <Button
                variant="outlined"
                sx={continueBtn}
                onPointerUp={handleNext}
              >
                Next
              </Button>
            </Box>
          </Box>
        </PageWrapper>
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
