/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  Slider,
} from '@mui/material';
import {formContainer, formLabel, radioGroup} from '../DropDownViewStyles';

const valuetext = (value: number) => `${value}`;

const marks = [
  {value: 1, label: 'No Limit'},
  {value: 25, label: '25+'},
  {value: 50, label: '50+'},
  {value: 75, label: '75+'},
  {value: 100, label: '100+'},
];

export const SalaryFilter = ({sortValue, handleChange}: any) => {
  return (
    <FormControl sx={formContainer}>
      <FormLabel sx={formLabel}>Salary per hour</FormLabel>
      <RadioGroup sx={radioGroup}>
        <Box sx={{display: 'flex', justifyContent: 'center', pt: 1}}>
          <Slider
            sx={{width: '90%', color: 'info.main'}}
            value={sortValue.salary}
            onChange={handleChange}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            marks={marks}
            min={1}
            max={100}
          />
        </Box>
      </RadioGroup>
      <Divider />
    </FormControl>
  );
};
