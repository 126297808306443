/* eslint-disable @typescript-eslint/no-explicit-any */
import {Close} from '@mui/icons-material';
import {Box, Button, Drawer, MenuItem, TextField} from '@mui/material';
import {continueBtn} from '../../../views/Public/LandingView/LandingViewStyles';
import {
  drawerBox,
  drawerClose,
  drawerInnerBox,
  drawerTitleContainer,
  qualiJobTitle,
  qualiTextField,
} from '../../../views/Private/ProfileView/ProfileViewStyles';
import {useEffect, useState} from 'react';
import {fetchBusinessIndustry} from '../../../providers/api/qualification';

export const WorkDrawer = ({
  open,
  close,
  setJobTitle,
  setCompany,
  setIndustry,
  setExpBegin,
  jobTitle,
  company,
  industry,
  expBegin,
  handleJobSubmit,
  expEnd,
  setExpEnd,
}: any) => {
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    loadIndustries().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const loadIndustries = async () => {
    try {
      const res = await fetchBusinessIndustry();
      setIndustries(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={close} sx={drawerBox}>
      <Box sx={drawerInnerBox}>
        <Box sx={drawerTitleContainer}>
          <Box sx={{fontSize: '24px'}}>Add work experience</Box>
          <Close sx={drawerClose} onClick={close} />
        </Box>
        <Box sx={{width: '100%'}}>
          <Box sx={qualiJobTitle}>Job title</Box>
          <TextField
            sx={qualiTextField}
            value={jobTitle}
            onChange={e => setJobTitle(e.target.value)}
          />
          <Box sx={qualiJobTitle}>Company</Box>
          <TextField
            sx={qualiTextField}
            value={company}
            onChange={e => setCompany(e.target.value)}
          />
          <Box sx={qualiJobTitle}>Industry</Box>
          <TextField
            select
            sx={{width: '100%', textTransform: 'capitalize'}}
            value={industry}
            onChange={e => setIndustry(e.target.value)}
          >
            {industries.map((inds: any) => (
              <MenuItem
                key={inds.id}
                value={inds.id}
                sx={{textTransform: 'capitalize'}}
              >
                {inds.name}
              </MenuItem>
            ))}
          </TextField>
          <Box sx={qualiJobTitle}>Start Year of employment</Box>
          <TextField
            sx={qualiTextField}
            value={expBegin}
            type="number"
            onChange={e => setExpBegin(e.target.value)}
          />
          <Box sx={qualiJobTitle}>End Year of exployment</Box>
          <TextField
            sx={qualiTextField}
            value={expEnd}
            type="number"
            onChange={e => setExpEnd(e.target.value)}
          />
          <Box sx={{mt: 3}}>
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={handleJobSubmit}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export const CISIndustries = [
  'accommodation and food services',
  'administrative and support, waste management and remediation services',
  'agriculture, forestry, fishing and hunting',
  'arts, entertainment and recreation',
  'construction',
  'education services',
  'finance and insurance',
  'health care and social assistance',
  'information and cultural industries',
  'management of companies and enterprises',
  'manufacturing',
  'mining, quarrying, and oil and gas extraction',
  'other services (except public administration',
  'professional, scientific and technical services',
  'public administration',
  'real estate and rental and leasing',
  'retail trade',
  'transportation and warehousing',
  'utilities',
  'wholesale trade',
];
