import {TextField} from '@mui/material';
import {LocalizationProvider, MobileDatePicker} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {CustomDatePickerProps} from '../../../types/types';

export const CustomDatePicker = ({
  value,
  handleChange,
  label,
  disabled,
  minDate,
  shouldDisableDate,
  maxDate,
}: CustomDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDatePicker
        label={label}
        inputFormat="DD/MM/YYYY"
        value={value}
        onChange={handleChange}
        renderInput={params => <TextField {...params} sx={{width: '100%'}} />}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        shouldDisableDate={shouldDisableDate ? shouldDisableDate(value) : null}
      />
    </LocalizationProvider>
  );
};
