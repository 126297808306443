/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AvailabilityTable} from '../../../components/common/AvailabilityTable/AvailabilityTable';
import {schedule} from '../../../components/common/AvailabilityTable/schedule';
import {ScrollLoader} from '../../../components/common/Loader/ScrollLoader';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {
  createStaffAvailabilityReg,
  getStaffAvailabilityReg,
  updateStaffAvailabilityReg,
} from '../../../providers/api/availability';
import {continueBtn, userScrollLimit} from '../LandingView/LandingViewStyles';

import {
  checkOnboardingStatus,
  updateStaffProfileWithPayload,
} from '../../../providers/api/profile';

export const weekTableEmpty = (weekTable: any) => {
  const weekTableFields = Object.values(weekTable);

  if (
    weekTableFields.every(
      (v: any) =>
        v.morning === false && v.afternoon === false && v.night === false
    )
  )
    return true;
  return false;
};

export const AvailabilityView = () => {
  const navigate = useNavigate();
  const [weekTable, setWeekTable] = useState<any>([]);
  const staffId: any = sessionStorage.getItem('email');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadTimeTable().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  // fetch default all selected schedule for first time visit
  const loadTimeTable = async () => {
    try {
      const timeTableTemp = await getStaffAvailabilityReg(staffId);
      if (timeTableTemp === 'new') {
        setWeekTable(schedule);
      } else {
        setWeekTable(timeTableTemp);
      }
    } catch (err) {
      console.log(err);
      console.log('error in component', err);
    }
    setLoading(false);
  };

  const createTimeTable = async () => {
    try {
      await createStaffAvailabilityReg(staffId, weekTable);
      await updateStaffProfileWithPayload(staffId!, {
        current_onboarding_step: 6,
      });
      navigate('/info/id');
    } catch (error) {
      console.log(error);
      console.log(error);
    }
  };

  const updateTimeTable = async () => {
    try {
      await updateStaffAvailabilityReg(staffId, weekTable);
      const onboardingStatus = await checkOnboardingStatus();
      if (onboardingStatus === 9) {
        navigate('/review');
      } else {
        navigate('/info/id');
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const handleClick = async () => {
    try {
      const onboardingStatus = await checkOnboardingStatus();
      if (onboardingStatus >= 6) {
        await updateTimeTable();
      } else {
        await createTimeTable();
      }
    } catch (error) {
      console.log(error);
      console.log(error);
    }
  };

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/info/personal" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Availability">
          {loading ? (
            <ScrollLoader />
          ) : (
            <AvailabilityTable
              weekTable={weekTable}
              setWeekTable={setWeekTable}
            />
          )}
          <Box sx={{px: 2, pb: 2}}>
            <Button
              id="next-btn"
              variant="outlined"
              sx={continueBtn}
              disabled={weekTableEmpty(weekTable)}
              onPointerUp={handleClick}
            >
              Next
            </Button>
          </Box>
        </PageWrapper>
      </Box>
    </>
  );
};
