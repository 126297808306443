/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppBar, Badge, Box, Grid, Toolbar, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {
  absolutePointer,
  logoBox,
  navBarBox,
  navBarGridContainer,
  rightBadge,
} from './NavBarStyles';
import {Chat, ArrowBackIosNew, Close, Tune} from '@mui/icons-material';
import {NavBarProps} from '../../../types/types';
import {CustomDialog} from '../Dialog/CustomDialog';
import {useEffect, useState} from 'react';
import {PopUp} from '../PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';
import {handleCustomError} from '../../../views/RouteErrorView/RouteErrorView';
import {
  fetchChatConversations,
  fetchMsgCount,
} from '../../../providers/api/chat';
import {app} from '../../../providers/Accounts';
import {DSGQLSubscription} from '@digistaff/common';
import {chatSubscriptionGQL, getAppsyncStaffAPI} from '@digistaff/notification';

/**
 * Navbar for webview
 * The Navbar accepts props to modify the buttons to be placed on
 *
 * @returns
 */
export const Navbar = ({
  leftTop,
  rightTop,
  backBtn,
  cancelBtn,
  signUp,
  page,
  login,
  landing,
}: NavBarProps) => {
  const navigate = useNavigate();
  const [dialog, setDialog] = useState(false);
  const [discard, setDiscard] = useState(false);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const tenantId = localStorage.getItem('tenantId');
  const [msgCount, setMsgCount] = useState(0);
  const [conversationId, setConversationId] = useState('');
  const [gqlSub, setGqlSub] = useState<any | null>(null);

  useEffect(() => {
    const loadMsgCount = async () => {
      try {
        const staffId = localStorage.getItem('email');
        let conv_id;
        conv_id = localStorage.getItem('conv_id');

        if (!conv_id || conv_id === 'false') {
          const res = await fetchChatConversations(staffId!);
          const conversation_id =
            res && res.length > 0 && res[0].conversation_id;
          localStorage.setItem('conv_id', conversation_id);
          conv_id = conversation_id;
        }

        setConversationId(conv_id);
        const count = await fetchMsgCount(conv_id, staffId!);
        setMsgCount(count);
      } catch (error) {
        handleCustomError(error);
      }
    };

    if (!rightTop || !tenantId || tenantId === 'default') return;
    loadMsgCount().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [tenantId, rightTop]);

  useEffect(() => {
    if (!conversationId) return;

    if (gqlSub) gqlSub.stop();

    const appsyncAPI = getAppsyncStaffAPI(app.environment);
    const newGqlSub = new DSGQLSubscription(chatSubscriptionGQL, appsyncAPI, {
      id: conversationId,
    });

    newGqlSub.on((payload: any) => {
      const newMessage = payload.payload.data.onCreateChatMessage;
      if (newMessage) {
        setMsgCount(prev => prev + 1);
      }
    });

    setGqlSub(newGqlSub);

    return () => {
      if (gqlSub) gqlSub.stop();
    };
  }, [conversationId, rightTop]);

  const toLastPage = () => navigate(-1);

  const goBackTo = () => page && navigate(page);

  const goToJobs = () => navigate('/jobs');

  const goToChat = () => navigate('/chat');

  const goToFilter = () => navigate('/filter');

  const handleDialog = () => setDialog(!dialog);

  const handleDiscard = () => setDiscard(!discard);

  const confirmDiscard = () => {
    handleDialog();
    handlePopUp('success', 'Your application has been saved');
    setTimeout(() => navigate('/'), 1000);
  };

  const handleClose = () => (signUp ? handleDiscard() : goToJobs());

  const handleBack = () => (signUp ? goBackTo() : toLastPage());

  const switchProfile = () => navigate('/account/switch');

  const getCurrentTenant = () => {
    const tenantList = localStorage.getItem('tenantList');
    const currentTenant = localStorage.getItem('tenantId');

    if (currentTenant === 'default') return 'DigiJobs';

    if (currentTenant && tenantList) {
      const tenants = JSON.parse(tenantList);
      const tenantInfo = tenants.find(
        (tenant: any) => tenant.id === currentTenant
      );

      let title = tenantInfo.name;
      if (title.length > 35) title = `${title.substring(0, 35)}...`;

      return title;
    }

    return '';
  };

  return (
    <Box sx={landing ? appBarLanding : appBarBox}>
      <AppBar position="fixed" sx={navBarBox}>
        <Toolbar>
          {leftTop && <Tune sx={absolutePointer} onPointerUp={goToFilter} />}
          {backBtn && (
            <ArrowBackIosNew sx={absolutePointer} onPointerUp={handleBack} />
          )}
          <Grid container sx={navBarGridContainer}>
            <Grid item xs sx={logoBox} />
          </Grid>
          <Box
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              right:
                (rightTop && tenantId !== 'default') || cancelBtn ? 55 : 15,
              textAlign: 'center',
              color: 'info.main',
            }}
            onPointerUp={switchProfile}
          >
            <Typography>{getCurrentTenant()}</Typography>
          </Box>
          {rightTop && tenantId !== 'default' && (
            <Badge
              sx={rightBadge}
              onPointerUp={goToChat}
              badgeContent={msgCount}
              color="error"
              max={99}
            >
              <Chat />
            </Badge>
          )}
          {cancelBtn && <Close onPointerUp={handleClose} sx={rightBadge} />}
        </Toolbar>
      </AppBar>
      <CustomDialog
        open={discard}
        close={handleDiscard}
        title="Are you sure?"
        leftOnClick={handleDiscard}
        rightOnClick={confirmDiscard}
      />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </Box>
  );
};

const appBarBox = {flexGrow: 1};

const appBarLanding = {flexGrow: 1, position: 'fixed'};
