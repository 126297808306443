/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useEffect, useMemo, useRef, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Close} from '@mui/icons-material';
import {Document, Page, pdfjs} from 'react-pdf';
import {usePopUp} from '../../../../hooks/usePopUp';
import {
  fetchAgencyEmploymentContract,
  fetchAgencyEmploymentContractInDigiJobs,
  submitStaffEmploymentContract,
  submitStaffEmploymentContractInDigiJobs,
} from '../../../../providers/api/contract';
import {
  uploadImageFile,
  uploadImageFileWithTenant,
} from '../../../../providers/api/training';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {scrollLimit} from '../../ChatView/ChatViewStyles';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {
  errorText,
  termsBox,
} from '../../../Public/Registration/RegistrationStyles';
import {continueBtn} from '../../../Public/LandingView/LandingViewStyles';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {PopUp} from '../../../../components/common/PopUp/PopUp';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import parse from 'html-react-parser';
import {fetchStaffProfileRaw} from '../../../../providers/api/profile';

import ReactQuill from 'react-quill';
import moment from 'moment';

export const EmploymentAgreementView = () => {
  const [drawer, setDrawer] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState('');
  const sigPadRef = useRef<any>();
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const staffId = localStorage.getItem('email');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [submitting, setSubmitting] = useState(false);
  const [title, setTitle] = useState('');
  const [scale, setScale] = useState(1.0);
  const location = useLocation();
  const jobTenantId = location?.state?.tenant_id;
  const [htmlString, setHTMLString] = useState('');
  const [fileType, setFileType] = useState('');

  useEffect(() => {
    const fetchProfileForReplacement = (resFromContract: any) => {
      fetchStaffProfileRaw(staffId!)
        .then((staffProfile: any) => {
          setTitle(resFromContract.name);
          setFileUrl(resFromContract.file_url);

          const replacements = {
            '{first_name}': staffProfile.first_name,
            '{last_name}': staffProfile.last_name,
            '{address}': `${staffProfile?.street}, ${staffProfile?.city} ${staffProfile?.province} ${staffProfile?.postal}, ${staffProfile?.country}`,
            '{date}': moment().format('LL'),
            //TODO: confirm job title
            '{officer_position}': 'the Company',
            '{staff_signature}': '',
            '{employer_signature}': '',
          };

          const updatedHTMLString = replacePlaceholders(
            resFromContract.file,
            replacements
          );
          setHTMLString(updatedHTMLString);
        })
        .catch(err => {
          console.log(err);
          console.log(err);
        });
    };

    const handleFile = (res: any) => {
      if (res.file_type === 'html') {
        fetchProfileForReplacement(res);
      } else if (res.file_type === 'pdf') {
        setFileUrl(res.file_url);
      }
    };

    const handleFetchedContract = (res: any) => {
      setFileType(res.file_type);
      handleFile(res);
    };

    if (params.id) {
      let fetchContract;
      if (jobTenantId !== undefined) {
        fetchContract = fetchAgencyEmploymentContractInDigiJobs(jobTenantId)(
          params.id
        );
      } else {
        fetchContract = fetchAgencyEmploymentContract(params.id);
      }

      fetchContract
        .then(handleFetchedContract)
        .catch(err => {
          console.log(err);
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [jobTenantId, params.id, staffId]);

  const replacePlaceholders = (str: string, replacements: any) => {
    let newStr = str;

    Object.keys(replacements).forEach(key => {
      const pattern = new RegExp(key, 'g');
      newStr = newStr.replace(pattern, replacements[key]);
    });

    return newStr;
  };

  const onDocumentLoadSuccess = ({numPages}: any) => setNumPages(numPages);

  const handleDrawer = () => setDrawer(!drawer);

  const handleClear = () => sigPadRef.current.clear();

  const canvasEmpty = () => {
    const canvas = sigPadRef.current.getTrimmedCanvas();
    return canvas.width === 1 && canvas.height === 1;
  };

  const handleTrim = () => {
    if (canvasEmpty()) {
      setError('Please provide your signature');
    } else {
      setTrimmedDataURL(
        sigPadRef.current.getTrimmedCanvas().toDataURL('image/png')
      );
      setDrawer(false);
    }
  };

  const getSignBoxWidth = () => {
    let signBoxWidth = 300;
    const termBox = document.getElementById('termBox');

    if (termBox) {
      signBoxWidth = termBox.offsetWidth - 30;
    }

    return signBoxWidth;
  };

  const zoomIn = () => setScale(scale + 0.1);

  const zoomOut = () => {
    if (scale > 0.1) {
      setScale(scale - 0.1);
    }
  };

  const storeContractInLocal = () => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith(`contract.${params.id}`)) {
        localStorage.setItem(key, 'true');
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);

      if (!trimmedDataURL) {
        handlePopUp('error', 'Please sign the employment agreement.');
        return;
      }

      const base64Data = trimmedDataURL.substring(
        trimmedDataURL.indexOf(',') + 1
      );
      const imageFile = `${staffId}_${params.id}`;

      let uploadedImage;
      if (jobTenantId !== undefined) {
        uploadedImage = await uploadImageFileWithTenant(jobTenantId)(
          base64Data,
          imageFile
        );
        await submitStaffEmploymentContractInDigiJobs(jobTenantId)(
          params.id as string,
          staffId as string,
          uploadedImage
        );
      } else {
        uploadedImage = await uploadImageFile(base64Data, imageFile);
        await submitStaffEmploymentContract(
          params.id as string,
          staffId as string,
          uploadedImage
        );
      }

      handlePopUp('success', 'Contract signed successfully');
      setSubmitting(false);
      setTrimmedDataURL('');
      storeContractInLocal();
      setTimeout(() => {
        navigate(-1);
      }, 700);
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: false,
    }),
    []
  );

  return (
    <>
      <Navbar backBtn />
      <Box sx={scrollLimit}>
        {loading ? (
          <></>
        ) : (
          <PageWrapper title={title}>
            <Box sx={{p: 2}}>
              <ReactQuill
                theme="snow"
                value={htmlString}
                readOnly
                modules={modules}
              />
              {/*<Box sx={termsBox} id="termBox">
                 {fileType === 'html' ? (
                  <>{parse(htmlString)}</>
                ) : (
                  <div
                    style={{
                      overflow: 'auto',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        transform: `scale(${scale})`,
                        transformOrigin: '0 0',
                      }}
                    >
                      <Document
                        file={fileUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                          />
                        ))}
                      </Document>
                    </div>
                  </div>
                )}
              </Box>
              {fileType === 'pdf' && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    flexWrap: 'wrap',
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={zoomOut}
                    sx={{flexGrow: 1}}
                  >
                    Zoom Out
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={zoomIn}
                    sx={{flexGrow: 1}}
                  >
                    Zoom In
                  </Button>
                </Box>
              )} */}
              {trimmedDataURL && (
                <Box sx={{p: 2, display: 'flex', justifyContent: 'center'}}>
                  <Box
                    component="img"
                    src={trimmedDataURL ? trimmedDataURL : undefined}
                    sx={{
                      width: '100px',
                      height: '100px',
                    }}
                  />
                </Box>
              )}
              <Box sx={{my: 3}}>
                <Button
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={handleDrawer}
                >
                  Sign
                </Button>
              </Box>
              <Button
                variant="outlined"
                sx={continueBtn}
                disabled={!trimmedDataURL || loading || submitting}
                onPointerUp={handleSubmit}
              >
                {submitting ? (
                  <CircularProgress
                    size="1.3rem"
                    sx={{color: 'primary.light', fontSize: '12px'}}
                  />
                ) : (
                  'Submit'
                )}
              </Button>
            </Box>
          </PageWrapper>
        )}
      </Box>
      <BottomBar />
      <Dialog open={drawer} onClose={handleDrawer}>
        <DialogTitle textAlign="center">
          Please Sign Below
          <IconButton
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
            }}
            onClick={handleDrawer}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: '300px',
              width: '100%',
              border: '1px solid',
              borderColor: 'secondary.main',
              overflowX: 'hidden',
            }}
          >
            <SignatureCanvas
              ref={sigPadRef}
              canvasProps={{
                width: getSignBoxWidth(),
                height: 300,
                className: 'sigCanvas',
              }}
            />
          </Box>
          {error && <Box sx={errorText}>{error}</Box>}
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, mt: 2}}>
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={handleClear}
            >
              Clear
            </Button>
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={handleTrim}
            >
              Confirm
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
    </>
  );
};
