/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ArrowForwardIos,
  ArrowRight,
  Create,
  Email,
  Phone,
  PinDrop,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  Switch,
} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BottomBar} from '../../../components/common/BottomBar/BottomBar';
import {CustomTextLoad} from '../../../components/common/CustomTextLoad/CustomTextLoad';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {
  fetchProfileCard,
  fetchStaffSilentStatus,
  updateStaffProfileSilentMode,
} from '../../../providers/api/profile';
import {AuthContext} from '../../../providers/AuthProvider';
import {continueBtn} from '../../Public/LandingView/LandingViewStyles';
import {
  arrowRight,
  avatarHeader,
  bottomSubPageLimit,
  divider,
  docuCardTextBox,
  editPenBox,
  emailBox,
  listContainer,
  photoIcon,
  titleBox,
  transparentBox,
  userInfoCardBox,
} from './ProfileViewStyles';
import {Bridge} from '../../../Bridge';

import {PopUp} from '../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';

export const ProfileView = () => {
  const navigate = useNavigate();
  const [dnd, setDnd] = useState(false);
  const {logOut} = useContext(AuthContext);
  const [profile, setProfile] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const tenantId = localStorage.getItem('tenantId');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    const staffId = localStorage.getItem('email');
    const dayReset = 24 * 60 * 60 * 1000;
    const loadUserProfile = async () => {
      const lastFetch = localStorage.getItem('lastFetch');

      if (!lastFetch || Date.now() - Number(lastFetch) >= dayReset) {
        try {
          const staffProfile = await fetchProfileCard(staffId!);
          const silentStatus = await fetchStaffSilentStatus(staffId!);

          localStorage.setItem('staffProfile', JSON.stringify(staffProfile));
          localStorage.setItem('silentStatus', JSON.stringify(silentStatus));
          localStorage.setItem('lastFetch', String(Date.now()));
          setProfile(staffProfile);
          setDnd(silentStatus);
        } catch (error) {
          console.log(error);
          console.log(error);
        } finally {
          setLoading(false);
        }
      } else {
        const staffProfile = JSON.parse(localStorage.getItem('staffProfile')!);
        const silentStatus = JSON.parse(localStorage.getItem('silentStatus')!);
        setProfile(staffProfile);
        setDnd(silentStatus);
        setLoading(false);
      }
    };

    loadUserProfile().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const handleContactClick = () => navigate('/account/overview');
  const handleEqRentalClick = () => navigate('/account/equipment/rental');
  const goToProfile = () => navigate('/account/profile');
  const goToProfilePic = () =>
    navigate('/account/profile/profilePic', {state: profile});
  const switchProfile = () => navigate('/account/switch');

  const handleLogOut = async () => {
    setLoggingOut(true);
    try {
      handlePopUp('success', 'Logged out. Redirecting you to login page...');
      await Bridge.resetAppData();
      await logOut();
    } catch (error: any) {
      // Prevent user unable to logout due to browser differences
      if (error === undefined || error.error === undefined) {
        await logOut();
      } else {
        // Prevent user did not refresh page and unable to logout with the latest version
        await logOut();
        console.log(error);
      }
    }
  };

  const handleDnD = async () => {
    try {
      await updateStaffProfileSilentMode(!dnd);
      localStorage.setItem('silentStatus', JSON.stringify(!dnd));
      setDnd(!dnd);
      const msg = !dnd
        ? 'You have successfully turned on silent mode'
        : 'You have successfully turned off silent mode';
      handlePopUp('success', msg);
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  return (
    <>
      <Navbar />
      <Box sx={bottomSubPageLimit}>
        <Box sx={{width: '100vw', maxWidth: '100%'}}>
          <Box sx={userInfoCardBox}>
            <Box sx={avatarHeader}>
              <Box onPointerUp={goToProfilePic} sx={{position: 'relative'}}>
                <Avatar
                  sx={{mr: 1, width: '50px', height: '50px'}}
                  src={profile.avatar}
                />
                <Box sx={editPenBox}>
                  <Create sx={{fontSize: '15px', color: 'primary.light'}} />
                </Box>
              </Box>
              <Box sx={{pb: 1}} onPointerUp={goToProfile}>
                {loading ? (
                  <>
                    <CustomTextLoad height={25} width={150} mb={10} />
                    <CustomTextLoad height={14} width={100} mb={5} />
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      {profile.fullName}
                    </Box>
                    <Box>Digistaff ID: {profile.digistaffId}</Box>
                  </>
                )}
              </Box>
            </Box>
            <Box sx={{mx: 4, pt: 1, pb: 2}} onClick={goToProfile}>
              <Box sx={docuCardTextBox}>
                <Phone sx={photoIcon} />
                {loading ? (
                  <CustomTextLoad height={12} width={250} />
                ) : (
                  <>{profile.phone}</>
                )}
              </Box>
              <Box sx={docuCardTextBox}>
                <Email sx={photoIcon} />
                {loading ? (
                  <CustomTextLoad height={12} width={200} />
                ) : (
                  <Box sx={emailBox}>{profile.email}</Box>
                )}
              </Box>
              <Box sx={docuCardTextBox}>
                <PinDrop sx={photoIcon} />
                {loading ? (
                  <CustomTextLoad height={12} width={150} />
                ) : (
                  <>{profile.location}</>
                )}
              </Box>
            </Box>
            <Box sx={transparentBox} onPointerUp={goToProfile} />
            <ArrowRight
              sx={{position: 'absolute', right: '5px', top: '45%'}}
              onPointerUp={goToProfile}
            />
          </Box>
          <Grid
            container
            sx={{p: 1, display: 'flex', alignItems: 'center', height: '75px'}}
          >
            <Grid item xs={5}></Grid>
            <Grid item xs={7}>
              <FormGroup>
                <FormControlLabel
                  control={<DoNotDisturb checked={dnd} sx={{m: 1}} />}
                  label="Do Not Disturb"
                  labelPlacement="start"
                  onChange={handleDnD}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Divider sx={divider} />
          {profileList.map((item: {title: string; location: string}) => (
            <Box key={item.title}>
              <Box
                sx={listContainer}
                onPointerUp={() => navigate(item.location)}
              >
                <Box sx={titleBox}>{item.title}</Box>
                <ArrowForwardIos sx={arrowRight} />
              </Box>
              <Divider sx={divider} />
            </Box>
          ))}
          {tenantId !== 'default' && (
            <>
              <Box
                sx={listContainer}
                onPointerUp={() => navigate('/appointment')}
              >
                <Box sx={titleBox}>My Appointments</Box>
                <ArrowForwardIos sx={arrowRight} />
              </Box>
              <Divider sx={divider} />
              <Box
                sx={listContainer}
                onPointerUp={() => navigate('/vacation/center')}
              >
                <Box sx={titleBox}>Vacation Center</Box>
                <ArrowForwardIos sx={arrowRight} />
              </Box>
              <Divider sx={divider} />
              <Box
                sx={listContainer}
                onPointerUp={() => navigate('/payout/history')}
              >
                <Box sx={titleBox}>Payout History</Box>
                <ArrowForwardIos sx={arrowRight} />
              </Box>
              <Divider sx={divider} />
              <Box sx={listContainer} onPointerUp={handleEqRentalClick}>
                <Box sx={titleBox}>Equipment Rental</Box>
                <ArrowForwardIos sx={arrowRight} />
              </Box>
              <Divider sx={divider} />
            </>
          )}
          <Box
            sx={listContainer}
            onPointerUp={() => navigate('/qualifications')}
          >
            <Box sx={titleBox}>Qualifications</Box>
            <ArrowForwardIos sx={arrowRight} />
          </Box>
          <Divider sx={divider} />
          <Box sx={listContainer} onPointerUp={handleContactClick}>
            <Box sx={titleBox}>Contact Preferences</Box>
            <ArrowForwardIos sx={arrowRight} />
          </Box>
          <Divider sx={divider} />
          <Box
            sx={listContainer}
            onPointerUp={() => navigate('/contact/emergency')}
          >
            <Box sx={titleBox}>Emergency Contact</Box>
            <ArrowForwardIos sx={arrowRight} />
          </Box>
          <Divider sx={divider} />
          {!loggingOut ? (
            <Box sx={listContainer} onPointerUp={handleLogOut}>
              <Box sx={titleBox}>Logout</Box>
              <ArrowForwardIos sx={arrowRight} />
            </Box>
          ) : (
            <Box sx={listContainer}>
              <Box sx={titleBox}>
                <CircularProgress />
              </Box>
            </Box>
          )}
          <Divider sx={divider} />
          <Box sx={{mb: 2, px: 2}}>
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={switchProfile}
            >
              Switch profile
            </Button>
          </Box>
        </Box>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
        duration={2000}
      />
    </>
  );
};

const profileList = [
  {title: 'Account ID', location: '/account/id', redirect: true},
  {title: 'Documents', location: '/documents', redirect: true},
  {title: 'My Availability', location: '/availability', redirect: true},
];

const DoNotDisturb = styled(Switch)(({theme}) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#3E82BF',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));
