/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
  TextField,
} from '@mui/material';
import {useEffect, useState} from 'react';

import {updateStaffCustomAttributes} from '../../../providers/api/profile';

export const StaffCustomAttributeForm = ({
  requiredStaffCustomAttributes,
  shift,
  onClose,
  staffId,
}: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [newAttributes, setNewAttributes] = useState<any>({});
  const [saveButtonLabel, setSaveButtonLabel] = useState('Save');

  useEffect(() => {
    if (requiredStaffCustomAttributes.length) setModalOpen(true);
  }, [requiredStaffCustomAttributes]);

  const handleFormChange = (id: string, e: any) => {
    const prevValue: any = {...setNewAttributes};
    const customAttribute = requiredStaffCustomAttributes.find(
      (item: any) => item.id === id
    );

    if (customAttribute.type) {
      prevValue[id] = e.target.value;

      if (customAttribute.type === 'number')
        prevValue[id] = parseInt(prevValue[id]);

      setNewAttributes(prevValue);
    }
  };

  const handleFormSave = async () => {
    let canSend = true;

    for (const property in newAttributes) {
      if (!newAttributes[property]) canSend = false;
    }

    if (Object.keys(newAttributes).length === 0) canSend = false;

    if (canSend) {
      setSaveButtonLabel('Saving...');

      const result = await updateStaffCustomAttributes(
        staffId,
        newAttributes,
        shift.tenant_id
      );

      setSaveButtonLabel('Save');
      setModalOpen(false);
    }
  };

  return (
    <Dialog open={modalOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle textAlign="center">{'Fill the attributes'}</DialogTitle>
      <DialogContent>
        <Box sx={{textAlign: 'center', mb: 2, color: 'info.main'}}>
          {'This shift requires further information:'}
        </Box>
        <Box sx={{maxHeight: '280px', mb: 2, overflowY: 'auto'}}>
          <Grid
            container
            spacing={2}
            sx={{width: '100%', mt: 0, ml: 0, pb: 0.5}}
          >
            {requiredStaffCustomAttributes &&
              requiredStaffCustomAttributes.map((item: any) => (
                <Grid
                  container
                  key={item?.id}
                  spacing={2}
                  alignItems="center"
                  sx={{margin: '0px', width: '100%'}}
                >
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        wordWrap: 'break-word',
                        color: 'error.dark',
                      }}
                    >
                      {`${item.name}`}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{display: 'flex', justifyContent: 'center'}}
                  >
                    <TextField
                      id="outlined-number"
                      label={item.unit}
                      type={item.type}
                      InputProps={{inputProps: {min: item.min, max: item.max}}}
                      onChange={e => handleFormChange(item.id, e)}
                    />
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button variant="contained" onClick={handleFormSave}>
          {saveButtonLabel}
        </Button>
        <Button variant="outlined" onClick={() => setModalOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
