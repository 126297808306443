/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Typography} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {
  bottomSubPageLimit,
  certLoadingBox,
  contentBgLayout,
} from '../../ProfileViewStyles';
import {
  contractItem,
  noCertBox,
} from '../../../../Public/Registration/RegistrationStyles';
import {ScrollLoader} from '../../../../../components/common/Loader/ScrollLoader';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {useContracts} from '../../../../../providers/api/useContracts';
import {SimCardDownload} from '@mui/icons-material';

export const Contracts = () => {
  const navigate = useNavigate();
  const [contractList, setContractList] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const {listAllContracts} = useContracts();

  const getContracts = useCallback(async () => {
    const staffId = localStorage.getItem('email');
    const allContracts: any = await listAllContracts(staffId!);
    const filteredContracts = allContracts.filter((currContract: any) => {
      if (!currContract.employment_contract_snapshot) return false;

      const doc = JSON.parse(currContract.employment_contract_snapshot);

      if (doc.url) return currContract;
      else return false;
    });
    setContractList(filteredContracts);
    setLoading(false);
  }, [listAllContracts]);

  useEffect(() => {
    void getContracts();
  }, [getContracts]);

  const viewContract = (contract: any) => {
    const doc = JSON.parse(contract.employment_contract_snapshot);
    const url = doc.url;

    navigate(`/documents/contracts/${contract.id}`, {
      state: {
        name: contract.nestGetEmploymentContract.name,
        url: url,
      },
    });
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Contracts">
          <Box sx={{p: 1}}>
            <Box sx={contentBgLayout}>
              {loading ? (
                <Box sx={certLoadingBox}>
                  <ScrollLoader />
                </Box>
              ) : (
                <>
                  {contractList && contractList.length === 0 ? (
                    <Box sx={noCertBox}>
                      <Box>
                        You don&apos;t have any signed contracts for this
                        profile.
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'scroll',
                        gap: 2,
                        px: 1,
                        textTransform: 'capitalize',
                      }}
                    >
                      {contractList &&
                        contractList.map((c: any) => (
                          <Box
                            sx={contractItem}
                            key={c?.id}
                            onPointerUp={() => viewContract(c)}
                          >
                            <Typography variant="h5" mb={2}>
                              {c?.nestGetEmploymentContract?.name}
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 1,
                              }}
                            >
                              {c?.nestGetEmploymentContract?.signed_date !==
                                null && (
                                <Box>
                                  Signed:{' '}
                                  {moment(
                                    c?.nestGetEmploymentContract?.signed_date
                                  ).format('DD MMM YYYY')}
                                </Box>
                              )}
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  cursor: 'pointer',
                                }}
                                onPointerUp={() => viewContract(c)}
                              >
                                <SimCardDownload />
                              </Box>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
