/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {Box, Button, Checkbox, CircularProgress, Divider} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {continueBtn, userScrollLimit} from '../LandingView/LandingViewStyles';
import {flexAlign} from '../../Private/ProfileView/ProfileViewStyles';
import {editBtn, finalFormDivider, finalReviewText} from './RegistrationStyles';
import {loadWorkerProfile} from './RegistrationUtils';
import {CustomTextLoad} from '../../../components/common/CustomTextLoad/CustomTextLoad';
import {updateStaffProfileWithPayload} from '../../../providers/api/profile';
import {checkBoxLarge} from '../../../components/common/AvailabilityTable/AvailabilityTableStyles';
import {
  createStaffDocumentInTenant,
  createStaffProfileInTenant,
  fetchStaffInviteTenants,
} from '../../../providers/api/tenancy';

import {PopUp} from '../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';

export const FinalSubmissionView = () => {
  const navigate = useNavigate();
  const staffId = sessionStorage.getItem('email');
  const [checked, setChecked] = useState(false);
  const [workerProfile, setWorkerProfile] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [govDocLoaded, setGovDocLoaded] = useState({});
  const [sinDocLoaded, setSinDocLoaded] = useState({});
  const [workDocLoaded, setWorkDocLoaded] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [staffPhoneNo, setStaffPhoneNo] = useState('');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();

  useEffect(() => {
    loadProfile().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const loadProfile = async () => {
    try {
      const {workerProfile, govDoc, sinDoc, workDoc, staffPhone} =
        await loadWorkerProfile(staffId!);
      // Prevent early user no work doc and unable to proceed
      if (workDoc.length === 0) {
        handlePopUp(
          'error',
          'Missing work status. Redirecting you back to upload work status...'
        );
        setTimeout(() => {
          navigate('/info/id');
        }, 1500);
        return;
      }
      setWorkerProfile(workerProfile);
      setGovDocLoaded(govDoc);
      setSinDocLoaded(sinDoc);
      setWorkDocLoaded(workDoc);
      setStaffPhoneNo(staffPhone);
    } catch (err) {
      console.log(err);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // Use submitted docs to create tenant doc
  const registerUserWithTenantProfileAndTenantDoc = async (
    tenantArray: any
  ) => {
    try {
      await Promise.all(
        tenantArray.map(async (tenant: any) => {
          await Promise.all([
            createStaffDocumentInTenant(
              staffId!,
              tenant.tenant_id,
              govDocLoaded
            ),
            createStaffDocumentInTenant(
              staffId!,
              tenant.tenant_id,
              sinDocLoaded
            ),
            createStaffDocumentInTenant(
              staffId!,
              tenant.tenant_id,
              workDocLoaded
            ),
          ]);
          await createStaffProfileInTenant(staffId!, tenant.tenant_id);
        })
      );
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const handleCheck = () => setChecked(!checked);

  // Check if staff was invited by tenant
  // If yes, create tenant doc status first before finishing worker registration
  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const res = await fetchStaffInviteTenants(staffId!, staffPhoneNo);

      if (res.length === 0) {
        const payload = {
          current_onboarding_step: 10,
        };
        await updateStaffProfileWithPayload(staffId!, payload);
        proceedToJobFeed();
      } else {
        await registerUserWithTenantProfileAndTenantDoc(res);

        const payload = {
          current_onboarding_step: 10,
          tenants: res.map((tenant: any) => tenant.tenant_id),
        };
        await updateStaffProfileWithPayload(staffId!, payload);
        proceedToJobFeed();
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const proceedToJobFeed = () => {
    localStorage.removeItem('page');
    navigate('/registered');
  };

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/info/emergency" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Review">
          <Box sx={{p: 2}}>
            <Box>
              {loading ? (
                <>
                  {Array.from(new Array(15)).map((_, idx) => (
                    <Box key={idx}>
                      <Box sx={flexAlign}>
                        <Box sx={finalReviewText}>
                          <CustomTextLoad height={14} width={200} />
                        </Box>
                        <Box sx={editBtn}>
                          <CustomTextLoad height={14} width={25} />
                        </Box>
                      </Box>
                      <Divider sx={finalFormDivider} />
                    </Box>
                  ))}
                </>
              ) : (
                <>
                  {workerProfile.map((p: any) => (
                    <Box key={p.title}>
                      <Box sx={flexAlign}>
                        <Box
                          sx={finalReviewText}
                        >{`${p.title}: ${p.property}`}</Box>
                        <Box sx={editBtn} onPointerUp={() => navigate(p.nav)}>
                          edit
                        </Box>
                      </Box>
                      <Divider sx={finalFormDivider} />
                    </Box>
                  ))}
                </>
              )}
              <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                <Checkbox
                  id="conf-checkbox"
                  checked={checked}
                  onChange={handleCheck}
                  sx={checkBoxLarge}
                />
                <Box sx={{fontSize: '12px'}}>
                  I hereby certify that all of the information provided by me in
                  this application is correct, accurate and complete to the best
                  of my knowledge.
                </Box>
              </Box>
              <Button
                id="next-btn"
                variant="outlined"
                sx={continueBtn}
                disabled={!checked || submitting}
                onPointerUp={handleSubmit}
              >
                {submitting ? (
                  <CircularProgress
                    size="1.3rem"
                    sx={{color: 'primary.light', fontSize: '12px'}}
                  />
                ) : (
                  'Submit'
                )}
              </Button>
            </Box>
          </Box>
        </PageWrapper>
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
