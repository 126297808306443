/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createStaffEmergencyContact,
  getStaffEmergencyContact,
  updateStaffEmergencyContact,
} from '@digistaff/staff';
import {app} from '../Accounts';

export const valuesFilled = (item: any) =>
  Object.values(item).every(val => val);

export const getEmergencyContactList = async (staffId: string) => {
  try {
    const response: any = await getStaffEmergencyContact(app, staffId);
    const contactList = response.data.getStaffEmergencyContact;

    const retrievedOne = {
      relationship: contactList.primary_contact_relationship,
      name: contactList.primary_contact_name,
      phone: contactList.primary_contact_number,
    };

    const retrievedTwo = {
      relationship: contactList.secondary_contact_relationship,
      name: contactList.secondary_contact_name,
      phone: contactList.secondary_contact_number,
    };

    if (!valuesFilled(retrievedTwo)) {
      retrievedTwo.relationship = '';
      retrievedTwo.name = '';
      retrievedTwo.phone = '';
    }

    return {retrievedOne, retrievedTwo};
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createEmergencyContactApp = async (
  staffId: string,
  contactOne: any,
  contactTwo: any
) => {
  if (!valuesFilled(contactTwo)) {
    contactTwo.relationship = '';
    contactTwo.name = '';
    contactTwo.phone = '';
  }

  const payload = {
    id: staffId,
    primary_contact_name: contactOne.name,
    primary_contact_relationship: contactOne.relationship.toLowerCase(),
    primary_contact_number: contactOne.phone,
    secondary_contact_name: contactTwo.name,
    secondary_contact_relationship: contactTwo.relationship.toLowerCase(),
    secondary_contact_number: contactTwo.phone,
  };
  try {
    return await createStaffEmergencyContact(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateEmergencyContactApp = async (
  staffId: string,
  contactOne: any,
  contactTwo: any
) => {
  if (!valuesFilled(contactTwo)) {
    contactTwo.relationship = '';
    contactTwo.name = '';
    contactTwo.phone = '';
  }

  const payload = {
    primary_contact_name: contactOne.name,
    primary_contact_relationship: contactOne.relationship.toLowerCase(),
    primary_contact_number: contactOne.phone,
    secondary_contact_name: contactTwo.name,
    secondary_contact_relationship: contactTwo.relationship.toLowerCase(),
    secondary_contact_number: contactTwo.phone,
  };
  try {
    return await updateStaffEmergencyContact(app, staffId, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};
