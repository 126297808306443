/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import {useEffect, useState} from 'react';
import {usePopUp} from '../../../hooks/usePopUp';
import {EmergencyContactProps} from '../../../types/types';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {
  headerLabel,
  textFieldEmer,
} from '../../Private/ProfileView/ProfileViewStyles';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {continueBtn, userScrollLimit} from '../LandingView/LandingViewStyles';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {useNavigate} from 'react-router-dom';
import {
  createEmergencyContactApp,
  getEmergencyContactList,
  updateEmergencyContactApp,
} from '../../../providers/api/emergencyContact';
import {createContactPreference} from '../../../providers/api/contactPreference';
import {createDigiscore} from '../../../providers/api/digiScore';
import {createNewSecurityKey} from '../../../providers/api/staffSecurity';
import {createNewFilterInstance} from '../../../providers/api/jobFilter';
import {
  checkOnboardingStatus,
  updateStaffProfileWithPayload,
} from '../../../providers/api/profile';

export const EmergencyRegisterView = () => {
  const [emerOne, setEmerOne] = useState({
    relationship: '',
    name: '',
    phone: '',
  });
  const [emerTwo, setEmerTwo] = useState({
    relationship: '',
    name: '',
    phone: '',
  });
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const navigate = useNavigate();
  const staffId = sessionStorage.getItem('email');
  const [contactCreated, setContactCreated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadEmergencyContact().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const isInfoNotCompleted = (emergencyContact: EmergencyContactProps) => {
    const {phone, name, relationship} = emergencyContact;
    return !name || !relationship || !isPossiblePhoneNumber(phone);
  };

  const loadEmergencyContact = async () => {
    try {
      const onboardingStatus = await checkOnboardingStatus();
      if (onboardingStatus < 9) return;
      const {retrievedOne, retrievedTwo}: any = await getEmergencyContactList(
        staffId!
      );
      if (retrievedOne.name) setContactCreated(true);
      setEmerOne(retrievedOne);
      setEmerTwo(retrievedTwo);
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const createEmergencyContact = async () => {
    try {
      await updateStaffProfileWithPayload(staffId!, {
        current_onboarding_step: 9,
      });
      await createEmergencyContactApp(staffId!, emerOne, emerTwo);
      await createContactPreference(staffId!);
      await createNewSecurityKey(staffId!);
      await createNewFilterInstance(staffId!);
      await createDigiscore(staffId!);
      localStorage.setItem('tenantId', '');
      goToReview();
    } catch (error) {
      console.log(error);
      console.log(error);
    }
  };

  const goToReview = () => {
    setLoading(false);
    navigate('/review');
  };

  const updateEmergencyContact = async () => {
    try {
      await updateEmergencyContactApp(staffId!, emerOne, emerTwo);
      goToReview();
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const handleNext = () => {
    setLoading(true);
    contactCreated
      ? updateEmergencyContact().catch(err => {
          console.log(err);
          console.log(err);
        })
      : createEmergencyContact().catch(err => {
          console.log(err);
          console.log(err);
        });
  };

  const handleSubmit = () => {
    if (!isValidPhoneNumber(emerOne.phone)) {
      handlePopUp(
        'error',
        'Please provide a valid phone number for primary contact'
      );
    } else if (emerTwo.name) {
      if (!isValidPhoneNumber(emerTwo.phone)) {
        handlePopUp(
          'error',
          'Please provide a valid phone number for secondary contact'
        );
      } else {
        isInfoNotCompleted(emerTwo)
          ? handlePopUp(
              'error',
              'You are trying to provide a second emergency contact but the information is not completed'
            )
          : handleNext();
      }
    } else {
      handleNext();
    }
  };

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/info/id" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Emergency Contact">
          <Box sx={{mx: 2}}>
            <Box sx={{textAlign: 'center', mb: 1}}>
              Please provide at least 1 emergency contact
            </Box>
            <Box sx={headerLabel}>Primary</Box>
            <Grid container sx={{width: '100%'}}>
              <Grid item xs={4}>
                <TextField
                  id="emergency-one-relationship"
                  value={emerOne.relationship}
                  sx={textFieldEmer}
                  select
                  label="Relationship"
                >
                  {relationships.map(p => (
                    <MenuItem
                      id={p}
                      key={p}
                      value={p}
                      onPointerUp={() =>
                        setEmerOne({...emerOne, relationship: p})
                      }
                      sx={{textTransform: 'capitalize'}}
                    >
                      {p}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="emergency-one-name"
                  sx={textFieldEmer}
                  value={emerOne.name}
                  onChange={e => setEmerOne({...emerOne, name: e.target.value})}
                  label="Name"
                />
              </Grid>
            </Grid>
            <Box sx={{py: 1}}>
              <PhoneInput
                id="emergency-one-phone"
                placeholder="Input phone number"
                international
                countryCallingCodeEditable
                defaultCountry="CA"
                value={emerOne.phone}
                onChange={(v: string) => {
                  if (v) setEmerOne({...emerOne, phone: v});
                }}
                maxLength={15}
              />
            </Box>
            <Box sx={{py: 2}}>
              <Divider />
            </Box>
            <Box sx={headerLabel}>Secondary</Box>
            <Grid container sx={{width: '100%'}}>
              <Grid item xs={4}>
                <TextField
                  id="emergency-two-relationship"
                  value={emerTwo.relationship}
                  sx={textFieldEmer}
                  select
                  label="Relationship"
                >
                  {relationships.map(p => (
                    <MenuItem
                      id={p}
                      key={p}
                      value={p}
                      onPointerUp={() =>
                        setEmerTwo({...emerTwo, relationship: p})
                      }
                    >
                      {p}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="emergency-two-name"
                  sx={textFieldEmer}
                  value={emerTwo.name}
                  onChange={e => setEmerTwo({...emerTwo, name: e.target.value})}
                  label="Name"
                />
              </Grid>
            </Grid>
            <Box sx={{py: 1}}>
              <PhoneInput
                id="emergency-two-phone"
                international
                countryCallingCodeEditable
                defaultCountry="CA"
                value={emerTwo.phone}
                onChange={(v: string) => {
                  if (v) setEmerTwo({...emerTwo, phone: v});
                }}
                maxLength={15}
              />
            </Box>
            <Box sx={{py: 1}} />
            <Button
              id="next-btn"
              sx={continueBtn}
              onPointerUp={handleSubmit}
              variant="outlined"
              disabled={isInfoNotCompleted(emerOne) || loading}
            >
              {loading ? (
                <CircularProgress
                  size="1.3rem"
                  sx={{color: 'primary.light', fontSize: '12px'}}
                />
              ) : (
                'Save'
              )}
            </Button>
          </Box>
        </PageWrapper>
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        duration={2000}
        status={status}
        message={message}
      />
    </>
  );
};

export const relationships = [
  'father',
  'mother',
  'parent',
  'guardian',
  'family member',
  'brother',
  'sister',
  'son',
  'daughter',
  'child',
  'friend',
  'spouse',
  'partner',
  'assistant',
  'manager',
  'other',
];
