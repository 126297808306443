/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, IconButton, InputAdornment, TextField} from '@mui/material';
import {ChangeEvent, useState} from 'react';
import {
  filterList,
  locationBox,
} from '../../../views/Private/DropDownView/DropDownViewStyles';
import {Clear} from '@mui/icons-material';

export const LocationFilterField = ({
  sortValue,
  setSortValue,
  locations,
  customFilterList,
}: any) => {
  const [list, setList] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);

  const handleSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchWord = event.target.value;
    setSortValue({...sortValue, location: searchWord});
    const newSearch = locations.filter((location: any) => {
      return location.city.toLowerCase().includes(searchWord.toLowerCase());
    });
    if (searchWord === '') {
      setFilteredData([]);
    } else {
      setFilteredData(newSearch);
    }
  };

  const handleClick = (location: any) => {
    setSortValue({...sortValue, location: location.city});
    setList(false);
    setFilteredData([]);
  };

  const handleEnter = (e: any) => {
    if (e.key === 'Enter')
      setSortValue({...sortValue, location: e.target.value});
    setList(false);
  };

  const clearField = () => {
    setSortValue({...sortValue, location: ''});
  };

  return (
    <>
      <TextField
        sx={{width: '100%'}}
        value={sortValue.location}
        onChange={e => handleSearch(e)}
        onFocus={() => setList(true)}
        onBlur={() => setList(false)}
        onKeyDown={e => handleEnter(e)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{pr: '11px'}}>
              {sortValue.location && (
                <IconButton edge="end" onClick={clearField}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {filteredData.length ? (
        <Box sx={customFilterList ? customFilterList : filterList}>
          {filteredData.map((location: any) => (
            <Box
              key={location.city}
              sx={locationBox}
              onMouseDown={() => handleClick(location)}
            >
              {location.city}
            </Box>
          ))}
        </Box>
      ) : (
        list && (
          <Box sx={customFilterList ? customFilterList : filterList}>
            {locations.map((location: any) => (
              <Box
                key={location.city}
                sx={locationBox}
                onMouseDown={() => handleClick(location)}
              >
                {location.city}
              </Box>
            ))}
          </Box>
        )
      )}
    </>
  );
};
