import {getCurrentUser} from '@digistaff/app';
import {app} from '../providers/Accounts';

export const useCurrentUser = () => {
  const userValid = async () => {
    try {
      await getCurrentUser(app);
      return true;
    } catch (error) {
      return false;
    }
  };

  const errMsg =
    'The registration time has exceeded the time limit. You can proceed with your application by logging in again. We will now redirect you to the login page.';

  const inAppErrMsg =
    'User Session expired. We will now redirect you to the login page.';

  const sessionInvalidRedirection = () => {
    setTimeout(() => {
      window.location.href = `${window.location.origin}/`;
    }, 1000);
  };

  return {userValid, sessionInvalidRedirection, errMsg, inAppErrMsg};
};
